import { Alert, Checkbox, CircularProgress, IconButton, LinearProgress, Snackbar } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import {
  MdAutoFixHigh,
  MdConnectingAirports,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdModeEdit,
  MdOutlineClear,
  MdOutlineTrackChanges,
  MdOutlineViewInAr,
  MdSwitchAccessShortcutAdd,
  MdTransitEnterexit,
} from 'react-icons/md';
import { PiColumnsLight } from 'react-icons/pi';

import ShipmentBody from './ShipmentBody';
import emptyData from './emptyData.png';
import { FaFilterCircleXmark, FaSortDown, FaSortUp, FaTruckPickup } from 'react-icons/fa6';
import OutsideClickHandler from 'react-outside-click-handler';
import { AiOutlineSortAscending } from 'react-icons/ai';
import { TfiMoreAlt } from 'react-icons/tfi';
import { IoIosArrowForward } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IoCheckmarkDoneSharp, IoCopyOutline } from 'react-icons/io5';
import { FaLongArrowAltRight, FaRegEdit } from 'react-icons/fa';

import EditPickedup from '../../../components/EditPickedup';
import DashboardEdit from '../../../../customer/components/DashboardEdit';
import CustomizingSelect from './CustomizingSelect';
import { FcCheckmark } from 'react-icons/fc';
import { LuFolderCheck } from 'react-icons/lu';
import { VscBeakerStop } from 'react-icons/vsc';
import { CiSliderVertical } from 'react-icons/ci';
import { GiConfirmed } from 'react-icons/gi';
import { TbTrashOff } from 'react-icons/tb';
import ShipmentBottom from './ShipmentBottom';
export default function ShipmentsGrid({
  headerGroups,
  rows,
  prepareRow,
  handleSelectedAllShipment,
  getTableBodyProps,
  isColumnResizing,
  handleSelectedShipment,
  handleSortShipmentColumn,
  searchClass,
  handleResetSearchColumn,
  handleSelectedContext,
  handleSelectedHeader,
  handleSelectedAllShipment1,
  searchCus,
  setSearchCus,
  searchHawb,
  displayShipment,
  displayShipmentLabel,
  searchCountry,
  itemPerPage,
  setItemPerPage,
  searchService,
  searchType,
  searchWeight,
  searchPcs,
  searchSin,
  searchSale,
  searchDate,
  handleCloseShipmentEdit,
  handleCloseShipmentLabel,
  searchStatus,
  handleChangeClass,
  handleSelectedCustomizeShipment,
  searchRate,
  searchFrom,
  searchExactWeight,
  searchPack,
  searchAirline,
  searchAgent,
  searchMawb,
  searchNote,
  searchSellNote,
  searchAccNote,
  searchPickupNote,
  searchGnNote,
  searchEndpoint,
  searchExactCharge,
  searchSurcharge,
  searchFsc,
  searchFscValue,
  searchAddress,
  searchComestic,
  searchSurcharge2,
  searchVat,
  searchVatValue,
  searchSurcharge3,
  searchAcc,
  searchCompany,
  searchRemote,
  searchDemandCharge,
  searchReweight,
  duplicateWeight,
  duplicateRate,
  duplicateCode,
  duplicateReweight,
  duplicateAirline,
  duplicatePack,
  duplicateAgent,
  duplicateExactWeight,
  duplicateClass,
  duplicateChargeable,
  duplicateAddress,
  duplicateRemote,
  duplicateExtra1,
  duplicateExtra2,
  duplicateExtra3,
  duplicateFsc,
  duplicateFscValue,
  duplicateVat,
  duplicateVatValue,
  duplicateSale,
  duplicateSender,
  duplicateNote,
  duplicatePickupNote,
  duplicateGnNote,
  duplicateSellNote,
  duplicateAccNote,
  duplicateDemandCharge,
  handleChangeCode,
  searchCode,
  handleChangeInputColumn,
  handleCloseContext,
  currentPage,
  pages,
  getCurrentPage,
  handleFirstPage,
  handlePrevPage,
  renderPageNumbers,
  handleNextPage,
  handleLastPage,
  totalWeight,
  totalRate,
  totalExactCharge,
  totalExactWeight,
  totalReweight,
  totalPrice,
  totalPieces,
  totalComestic,
  totalAddress,
  totalRemote,
  totalSurcharge,
  totalFscValue,
  totalSurcharge2,
  totalSurcharge3,
  totalVatValue,
  totalDus,
  totalDhl,
  totalFie,
  totalFip,
  totalF3p,
  totalDhlVn,
  totalFedexVn,
  totalDemandCharge,
  duplicateService,
  duplicateComestic,
  duplicateSurcharge,
  duplicateSurcharge2,
  duplicateSurcharge3,
  duplicateTotal,
  duplicateAcc,
  duplicateCompany,
  handleSelectedContextMenu,
  handleSelectedCustomizeShipmentRemove,
  handleCloseContextMenu,
  handleShowShipment,
  handleShowShipmentLabel,
  selectedEdit,
  searchTotal,
  selected,
}) {
  const [showEmpty, setShowEmpty] = useState(false);
  const [showService, setShowService] = useState(false);
  const [serviceStatus, setServiceStatus] = useState('DUS');
  const [displaySelectOption, setDisplaySelectOption] = useState(false);
  const [displayCustomer, setDisplayCustomer] = useState(false);
  const [displayCustomeSelectOption, setDisplayCustomeSelectOption] = useState(false);
  const [displayCopy, setDisplayCopy] = useState(false);
  const [copyText, setCopyText] = useState('');
  const navigate = useNavigate();
  const onShowNoneShipment = () => {
    setTimeout(() => {
      setShowEmpty(true);
    }, 5000);
  };
  const handleShowService = () => {
    setShowService(!showService);
  };
  useEffect(() => {
    if (rows.length === 0) {
      setTimeout(() => {
        setShowEmpty(true);
      }, 5000);
    } else {
      setShowEmpty(false);
    }
  }, [rows]);

  function resetCopy(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setDisplayCopy(false);
  }
  return (
    <div>
      {displayCopy && (
        <Snackbar
          open={displayCopy}
          autoHideDuration={5000}
          onClose={resetCopy}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert elevation={5} variant="filled" onClose={resetCopy} severity="success">
            Copied {copyText}
          </Alert>
        </Snackbar>
      )}
      {displayShipment && <EditPickedup handleCloseEdit={handleCloseShipmentEdit} hawbProps={selectedEdit?.hawb} />}
      {displayShipmentLabel && (
        <DashboardEdit handleCloseShipment={handleCloseShipmentLabel} shipmentData={selectedEdit} />
      )}
      <div className="table-wrapper1 flex flex-col">
        <div className="flex items-center sticky top-0 bg-white z-[9997] w-fit shadow-[rgba(0,0,0,0.15)_2.4px_2.4px_3.2px]">
          <div
            className="sticky left-0 z-[9998] bg-white flex items-center shadow-[rgba(0,0,0,0.05)_2.4px_0px_0px]"
            style={{ border: '1px solid lightgray', borderBottom: 'none', borderRight: 'none' }}
          >
            <Checkbox
              style={{ padding: 11, margin: 1, height: 50 }}
              onChange={(e) => {
                handleSelectedAllShipment(e);
              }}
            />
            <div
              className="w-[50px] h-[52px] flex items-center justify-center"
              style={{ borderRight: '1px solid lightgray', borderLeft: '1px solid lightgray' }}
            >
              <p className="font-semibold">No</p>
            </div>
            <div className="w-[177px] h-[52px] flex items-center ml-2.5">
              <p className="font-semibold">HAWB</p>
            </div>
            <OutsideClickHandler
              onOutsideClick={() => {
                setDisplaySelectOption(false);
              }}
            >
              <div className="relative">
                <div
                  className={`w-[30px] h-[30px] flex items-center justify-center rounded-[9px]  cursor-pointer mr-2 ${
                    displaySelectOption
                      ? 'bg-sky-600 text-white hover:opacity-70 active:opacity-50'
                      : 'hover:bg-slate-300 active:bg-slate-400 bg-slate-200'
                  }`}
                  onClick={() => setDisplaySelectOption(!displaySelectOption)}
                >
                  <TfiMoreAlt size={20} />
                </div>
                {displaySelectOption && (
                  <div>
                    <div className="absolute bg-white mt-1.5 w-[250px] rounded-[10px] z-10 shadow-[rgba(50,50,105,0.15)_0px_2px_5px_0px,rgba(0,0,0,0.2)_0px_1px_1px_0px]">
                      <div
                        className="m-1 p-1.5 select-none rounded-[10px] hover:bg-slate-100 active:bg-slate-200 px-3 cursor-pointer"
                        onClick={() => {
                          handleSelectedAllShipment1('all');
                          setDisplaySelectOption(false);
                        }}
                      >
                        <p>Select all shipments</p>
                      </div>
                      <div
                        className="m-1 p-1.5 select-none rounded-[10px] hover:bg-slate-100 active:bg-slate-200 px-3 cursor-pointer"
                        onClick={() => {
                          handleSelectedAllShipment1('wpx');
                          setDisplaySelectOption(false);
                        }}
                      >
                        <p>Select all WPX</p>
                      </div>
                      <div
                        className="m-1 p-1.5 select-none rounded-[10px] hover:bg-slate-100 active:bg-slate-200 px-3 cursor-pointer"
                        onClick={() => {
                          handleSelectedAllShipment1('dox');
                          setDisplaySelectOption(false);
                        }}
                      >
                        <p>Select all DOX</p>
                      </div>
                      <div
                        className="m-1 p-1.5 select-none rounded-[10px] hover:bg-slate-100 active:bg-slate-200 px-3 cursor-pointer"
                        onClick={() => {
                          handleSelectedAllShipment1('currentPage');
                          setDisplaySelectOption(false);
                        }}
                      >
                        <p>Select current page</p>
                      </div>
                      <div
                        className={`m-1 p-1.5 select-none rounded-[10px] px-3 flex items-center justify-between cursor-pointer ${
                          displayCustomeSelectOption
                            ? 'bg-sky-600 text-white hover:opacity-70 active:opacity-50'
                            : 'hover:bg-slate-100 active:bg-slate-200'
                        }`}
                        onClick={() => {
                          setDisplayCustomeSelectOption(!displayCustomeSelectOption);
                        }}
                      >
                        <p>More select option</p>
                        <MdKeyboardArrowRight size={28} />
                      </div>
                    </div>
                    {displayCustomeSelectOption && (
                      <CustomizingSelect
                        handleSelectedCustomizeShipment={handleSelectedCustomizeShipment}
                        headerGroups={headerGroups}
                        selected={selected}
                        handleSelectedCustomizeShipmentRemove={handleSelectedCustomizeShipmentRemove}
                        setDisplayCustomeSelectOption={setDisplayCustomeSelectOption}
                      />
                    )}
                  </div>
                )}
              </div>
            </OutsideClickHandler>
          </div>
          {headerGroups?.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps({})} className="tr">
              {headerGroup.headers.map((column, index) => (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    handleCloseContext(headerGroup, column.id, column.active);
                  }}
                >
                  <div className="relative">
                    {column.active && (
                      <div
                        {...column.getHeaderProps()}
                        className="th flex items-center justify-between text-gray-600 relative"
                      >
                        {index === 0 && searchClass !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 1 && searchCus !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 2 && searchDate !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 3 && searchFrom !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 4 && searchCountry !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 5 && searchStatus !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 6 && searchType !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 7 && searchWeight !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 8 && searchExactWeight !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 9 && searchExactCharge !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 10 && searchReweight !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 11 && searchPcs !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {/* {index === 14 && searchPack !== '' && (
                            <div
                              className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                              onClick={() => handleResetSearchColumn(index)}
                            >
                              <FaFilterCircleXmark size={17} />
                            </div>
                          )} */}
                        {index === 12 && searchPack !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 13 && searchAirline !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 14 && searchService !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 15 && searchAgent !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 16 && searchMawb !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 17 && searchSin !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 18 && searchRate !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 19 && searchComestic !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 20 && searchAddress !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 21 && searchRemote !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 22 && searchSurcharge !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 23 && searchFsc !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 24 && searchFscValue !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 25 && searchSurcharge2 !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 26 && searchVat !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 27 && searchVatValue !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 28 && searchSurcharge3 !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 29 && searchDemandCharge !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 30 && searchTotal !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 32 && searchSale !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 33 && searchAcc !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 34 && searchCompany !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 35 && searchNote !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 36 && searchSellNote !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 37 && searchAccNote !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 38 && searchPickupNote !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        {index === 39 && searchGnNote !== '' && (
                          <div
                            className="absolute right-[-7px] w-[23px] h-[23px] rounded-[3px] z-10 bg-rose-500 text-white top-0 flex items-center justify-center hover:bg-rose-600 active:bg-rose-700 active:scale-90 cursor-pointer skd-import-container-popup"
                            onClick={() => handleResetSearchColumn(index)}
                          >
                            <FaFilterCircleXmark size={17} />
                          </div>
                        )}
                        <p
                          style={
                            index + 1 === 1
                              ? { color: 'black', textAlign: 'center' }
                              : { color: 'black', textAlign: 'left' }
                          }
                          className="elipe-text"
                        >
                          {column.render('Header')}
                        </p>
                        <div
                          className="w-[30px] h-[30px] flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 active:scale-90 rounded-full cursor-pointer"
                          onClick={() => handleSelectedContext(headerGroup, column.id, column.active)}
                        >
                          {column.context ? (
                            <FaSortUp size={28} className="mt-[14px]" />
                          ) : (
                            <FaSortDown size={28} className="mt-[-10px]" />
                          )}
                        </div>
                        {column.canResize && (
                          <div
                            {...column.getResizerProps()}
                            className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                          />
                        )}
                      </div>
                    )}
                    {column.context && (
                      <div className="absolute bg-white w-[320px] mt-1.5 rounded-[10px] shadow-[rgba(0,0,0,0.1)_0px_10px_15px_-3px,rgba(0,0,0,0.15)_0px_4px_6px_-2px]">
                        <p className="p-[10px] text-slate-500 tracking-wide text-[15px]">
                          <span className="underline font-bold text-black">{column.render('Header')}</span>'s context
                          menu
                        </p>
                        <div className="p-2" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
                          {index !== 0 &&
                            index !== 2 &&
                            index !== 9 &&
                            index !== 8 &&
                            index !== 5 &&
                            index !== 6 &&
                            index !== 7 &&
                            index !== 10 &&
                            index !== 12 &&
                            index !== 14 &&
                            index !== 15 &&
                            index !== 18 &&
                            index !== 19 &&
                            index !== 20 &&
                            index !== 21 &&
                            index !== 22 &&
                            index !== 23 &&
                            index !== 24 &&
                            index !== 25 &&
                            index !== 26 &&
                            index !== 27 &&
                            index !== 28 &&
                            index !== 29 &&
                            index !== 30 &&
                            index !== 31 &&
                            index !== 32 &&
                            index !== 33 &&
                            index !== 34 &&
                            index !== 40 &&
                            index !== 41 && (
                              <OutsideClickHandler
                                onOutsideClick={() => {
                                  if (index === 1) {
                                    setDisplayCustomer(false);
                                  }
                                }}
                              >
                                <div className="relative">
                                  <input
                                    type="text"
                                    placeholder="Enter search value"
                                    className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] 
                                  focus:border-slate-300 search-column-input pl-2.5"
                                    onFocus={() => {
                                      if (index === 1) {
                                        setDisplayCustomer(true);
                                      }
                                    }}
                                    value={
                                      index === 0
                                        ? searchClass
                                        : index === 1
                                          ? searchCus
                                          : index === 2
                                            ? searchDate
                                            : index === 3
                                              ? searchFrom
                                              : index === 4
                                                ? searchCountry
                                                : index === 5
                                                  ? searchStatus
                                                  : index === 6
                                                    ? searchType
                                                    : index === 7
                                                      ? searchWeight
                                                      : index === 8
                                                        ? searchExactWeight
                                                        : index === 9
                                                          ? searchExactCharge
                                                          : index === 10
                                                            ? searchReweight
                                                            : index === 11
                                                              ? searchPcs
                                                              : index === 12
                                                                ? searchPack
                                                                : index === 13
                                                                  ? searchAirline
                                                                  : index === 15
                                                                    ? searchAgent
                                                                    : index === 16
                                                                      ? searchMawb
                                                                      : index === 17
                                                                        ? searchSin
                                                                        : index === 32
                                                                          ? searchAcc
                                                                          : index === 33
                                                                            ? searchCompany
                                                                            : index === 34
                                                                              ? searchNote
                                                                              : index === 35
                                                                                ? searchNote
                                                                                : index === 36
                                                                                  ? searchSellNote
                                                                                  : index === 37
                                                                                    ? searchAccNote
                                                                                    : index === 38
                                                                                      ? searchPickupNote
                                                                                      : index === 39
                                                                                        ? searchGnNote
                                                                                        : ''
                                    }
                                    onChange={(e) => handleChangeInputColumn(index, e)}
                                    autoFocus={index === 1 ? false : true}
                                  />
                                  {displayCustomer && index === 1 && (
                                    <div className="absolute bg-white mt-2 shadow-[rgba(0,0,0,0.25)_0px_0.0625em_0.0625em,rgba(0,0,0,0.25)_0px_0.125em_0.5em,rgba(255,255,255,0.1)_0px_0px_0px_1px_inset] w-full max-h-[200px] overflow-auto rounded-[10px] p-1 flex flex-col gap-y-1">
                                      {duplicateSender
                                        .filter((s) => {
                                          return s?.toLowerCase().includes(searchCus?.toLowerCase());
                                        })
                                        .map((item) => (
                                          <div
                                            className="p-1.5 bg-slate-100 px-3 rounded-[10px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer select-none"
                                            onClick={() => {
                                              setDisplayCustomer(false);
                                              setSearchCus(item);
                                              handleCloseContext(headerGroup, column.id, column.active);
                                            }}
                                          >
                                            <p>{item}</p>
                                          </div>
                                        ))}
                                    </div>
                                  )}

                                  <div
                                    className="absolute right-1.5 w-[25px] h-[25px] rounded-full flex items-center justify-center top-[5.5px] cursor-pointer hover: bg-slate-100 active:bg-slate-200 active:scale-90"
                                    onClick={() => {
                                      handleResetSearchColumn(index);
                                    }}
                                  >
                                    <MdOutlineClear size={23} />
                                  </div>
                                </div>
                              </OutsideClickHandler>
                            )}
                          {index === 2 && (
                            <input
                              type="date"
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchDate}
                              onChange={(e) => handleChangeInputColumn(index, e)}
                            />
                          )}
                          {index === 4 && (
                            <div>
                              <p className="p-1 mt-1 text-slate-600">Search by country code:</p>
                            </div>
                          )}
                          {index === 4 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchCode}
                              onChange={(e) => {
                                handleChangeCode(e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateCode
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 0 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer mt-1.5"
                              value={searchClass}
                              onChange={(e) => {
                                handleChangeClass(e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateClass
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 7 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchWeight}
                              onChange={(e) => handleChangeInputColumn(index, e)}
                            >
                              <option value="">Select value</option>
                              {duplicateWeight
                                .sort((x, y) => {
                                  if (x > 0 && y > 0) {
                                    return x - y;
                                  }
                                  return y - x;
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 8 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchExactWeight}
                              onChange={(e) => handleChangeInputColumn(index, e)}
                            >
                              <option value="">Select value</option>
                              {duplicateExactWeight
                                .sort((x, y) => {
                                  if (x > 0 && y > 0) {
                                    return x - y;
                                  }
                                  return y - x;
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 9 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchExactCharge}
                              onChange={(e) => handleChangeInputColumn(index, e)}
                            >
                              <option value="">Select value</option>
                              {duplicateChargeable
                                .sort((x, y) => {
                                  if (x > 0 && y > 0) {
                                    return x - y;
                                  }
                                  return y - x;
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 10 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchReweight}
                              onChange={(e) => handleChangeInputColumn(index, e)}
                            >
                              <option value="">Select value</option>
                              {duplicateReweight
                                .sort((x, y) => {
                                  if (x > 0 && y > 0) {
                                    return x - y;
                                  }
                                  return y - x;
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 12 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchPack}
                              onChange={(e) => handleChangeInputColumn(index, e)}
                            >
                              <option value="">Select value</option>
                              {duplicatePack
                                .sort((x, y) => {
                                  return y.localeCompare(x);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 14 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchService}
                              onChange={(e) => handleChangeInputColumn(index, e)}
                            >
                              <option value="">Select value</option>
                              {duplicateService
                                .sort((x, y) => {
                                  if (x > 0 && y > 0) {
                                    return x - y;
                                  }
                                  return y - x;
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 15 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchAgent}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateAgent
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 18 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchRate}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateRate
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 6 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchType}
                              onChange={(e) => handleChangeInputColumn(index, e)}
                            >
                              <option value="">Select value</option>
                              <option value="WPX">WPX</option>
                              <option value="DOX">DOX</option>
                            </select>
                          )}
                          {index === 5 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchStatus}
                              onChange={(e) => handleChangeInputColumn(index, e)}
                            >
                              <option value="">Select value</option>
                              <option value="Picked up">Picked up</option>
                              <option value="Arrived">Arrived</option>
                              <option value="In process">In process</option>
                              <option value="On hold">On hold</option>
                              <option value="Cleareance">Clearance</option>
                              <option value="Cleared">Cleared</option>
                              <option value="In transit">In transit</option>
                              <option value="Delivered">Delivered</option>
                            </select>
                          )}
                          {index === 19 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchComestic}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateComestic
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 20 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchAddress}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateAddress
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 21 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchRemote}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateRemote
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 22 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchSurcharge}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateSurcharge
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 23 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchFsc}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateFsc
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 24 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchFscValue}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateFscValue
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 25 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchSurcharge2}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateSurcharge2
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 26 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchVat}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateVat
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 27 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchVatValue}
                              onChange={(e) => {
                                handleChangeInputColumn(e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateVatValue
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 28 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchSurcharge3}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateSurcharge3
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 29 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchDemandCharge}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateDemandCharge
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 30 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchTotal}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateTotal
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 32 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchSale}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateSale
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 33 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchAcc}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateAcc
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 34 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer"
                              value={searchCompany}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateCompany
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 35 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer mt-1.5"
                              value={searchNote}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateNote
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 36 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer mt-1.5"
                              value={searchSellNote}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateSellNote
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 37 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer mt-1.5"
                              value={searchAccNote}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateAccNote
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 38 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer mt-1.5"
                              value={searchPickupNote}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicatePickupNote
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                          {index === 39 && (
                            <select
                              className="w-full p-[5px] rounded-[10px] bg-slate-100 outline-none focus:bg-white border-solid border-transparent border-[1px] focus:border-slate-300 search-column-input cursor-pointer mt-1.5"
                              value={searchGnNote}
                              onChange={(e) => {
                                handleChangeInputColumn(index, e);
                              }}
                            >
                              <option value="">Select value</option>
                              {duplicateGnNote
                                ?.sort((x, y) => {
                                  return x.localeCompare(y);
                                })
                                .map((element) => (
                                  <option value={element}>{element}</option>
                                ))}
                            </select>
                          )}
                        </div>
                        <div className="mx-1 pb-1.5">
                          <div
                            className="flex items-center p-1 m-0.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none"
                            onClick={() => {
                              handleSortShipmentColumn(index);
                              handleCloseContext(headerGroup, column.id, column.active);
                            }}
                          >
                            <AiOutlineSortAscending size={27} />
                            <p className="ml-1.5">Sort {column.render('Header')}</p>
                          </div>
                          <div
                            className="flex items-center p-1 m-0.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none"
                            onClick={() => {
                              handleSelectedHeader(headerGroup, column.id, column.active);
                              handleCloseContext(headerGroup, column.id, column.active);
                            }}
                          >
                            <PiColumnsLight size={27} />

                            <p className="ml-1.5">Hide column</p>
                          </div>
                          <div
                            className="flex items-center p-1 m-0.5 rounded-[10px] hover:bg-slate-100 active:bg-slate-200 cursor-pointer select-none"
                            onClick={() => {
                              handleCloseContext(headerGroup, column.id, column.active);
                            }}
                          >
                            <MdOutlineClear size={27} />

                            <p className="ml-1.5">Close context</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
              ))}
            </div>
          ))}
        </div>
        {rows?.length === 0 && (
          <div className="flex items-center justify-center h-full text-xl tracking-wide flex-col p-7">
            {showEmpty && <img src={emptyData} alt="" loading="lazy" className="w-[125px] h-[125px] object-cover" />}
            <p className={`${showEmpty ? 'text-slate-400 text-[15px]' : ''}`}>
              System is looking for shipments, please wait...
            </p>
            {onShowNoneShipment()}
            {showEmpty && (
              <div className="flex flex-col items-center">
                <p>Seems like we can't find any shipment recently</p>
                <button
                  type="button"
                  className="p-1.5 bg-sky-50 text-sky-600 font-bold rounded-[12px] px-7 cursor-pointer hover:bg-sky-100 active:bg-sky-200 my-2.5 mt-3.5 flex items-center"
                  onClick={() => window.location.reload()}
                >
                  <MdAutoFixHigh size={30} className="mr-2" />
                  Retry now
                </button>
              </div>
            )}
          </div>
        )}
        {rows?.length !== 0 &&
          rows.map((row, index) => {
            prepareRow(row);
            return (
              <OutsideClickHandler
                onOutsideClick={() => {
                  handleCloseContextMenu(row.original.hawb);
                }}
              >
                <div
                  className={`manifest-tr ${
                    row.original.select
                      ? 'bg-blue-500 hover:bg-blue-600 active:bg-blue-700'
                      : 'hover:bg-slate-100 active:bg-slate-200'
                  } `}
                >
                  <div
                    className={`sticky left-0 bg-white z-[8888] flex items-center shadow-[rgba(0,0,0,0.15)_2.4px_2.4px_3.2px]  ${
                      row.original.contextMenu ? 'search-column-input1' : ''
                    }`}
                    style={{ border: '1px solid lightgray', borderRight: 'none' }}
                  >
                    <Checkbox
                      color="primary"
                      style={{ padding: 11, margin: 1, height: 44 }}
                      checked={row.original.select}
                      onChange={(e) => {
                        let value = e.target.checked;
                        handleSelectedShipment(rows, row.id, value);
                      }}
                    />
                    <div
                      className="w-[50px] h-[47px] flex items-center justify-center"
                      style={{ borderRight: '1px solid lightgray', borderLeft: '1px solid lightgray' }}
                    >
                      <p className="font-semibold">{row.original.no}</p>
                    </div>
                    <div className="w-[177px] ml-2.5">
                      <p className="tracking-wide text-[17px] text-sky-800 overflow-hidden text-ellipsis">
                        {row.original.hawb}
                      </p>
                    </div>
                    {!row.original.loading && row.original.updateSuccess !== 'true' && !row.original.currentStatus && (
                      <div
                        className={`w-[30px] h-[30px] flex items-center justify-center rounded-[9px]  cursor-pointer mr-2 ${
                          row.original.contextMenu
                            ? 'bg-sky-600 hover:opacity-70 active:opacity-50 text-white'
                            : 'hover:bg-slate-300 active:bg-slate-400 bg-slate-200'
                        }`}
                        onClick={() => handleSelectedContextMenu(row.original.hawb)}
                      >
                        <TfiMoreAlt size={20} />
                      </div>
                    )}
                    {row.original.currentStatus !== '' &&
                      !row.original.loading &&
                      row.original.updateSuccess !== 'true' && (
                        <div className="flex items-center group">
                          {row.original.currentStatus === 'Arrived Depot' && (
                            <div
                              className={`w-[30px] h-[30px] flex items-center justify-center rounded-[9px]  cursor-pointer mr-2 hover:bg-sky-100 active:bg-sky-200 bg-sky-50 text-sky-600`}
                              onClick={() => handleSelectedContextMenu(row.original.hawb)}
                            >
                              <MdConnectingAirports size={20} />
                            </div>
                          )}
                          {row.original.currentStatus === 'In Process' && (
                            <div
                              className={`w-[30px] h-[30px] flex items-center justify-center rounded-[9px]  cursor-pointer mr-2 hover:bg-fuchsia-100 active:bg-fuchsia-200 bg-fuchsia-50 text-fuchsia-500`}
                              onClick={() => handleSelectedContextMenu(row.original.hawb)}
                            >
                              <LuFolderCheck size={20} />
                            </div>
                          )}
                          {row.original.currentStatus === 'On Hold' && (
                            <div
                              className={`w-[30px] h-[30px] flex items-center justify-center rounded-[9px]  cursor-pointer mr-2 hover:bg-rose-100 active:bg-rose-200 bg-rose-50 text-rose-500`}
                              onClick={() => handleSelectedContextMenu(row.original.hawb)}
                            >
                              <VscBeakerStop size={20} />
                            </div>
                          )}
                          {row.original.currentStatus === 'Removed' && (
                            <div
                              className={`w-[30px] h-[30px] flex items-center justify-center rounded-[9px]  cursor-pointer mr-2 hover:bg-rose-100 active:bg-rose-200 bg-rose-50 text-rose-500`}
                              onClick={() => handleSelectedContextMenu(row.original.hawb)}
                            >
                              <TbTrashOff size={20} />
                            </div>
                          )}
                          {row.original.currentStatus === 'Clearance' && (
                            <div
                              className={`w-[30px] h-[30px] flex items-center justify-center rounded-[9px]  cursor-pointer mr-2 hover:bg-orange-100 active:bg-orange-200 bg-orange-50 text-orange-500`}
                              onClick={() => handleSelectedContextMenu(row.original.hawb)}
                            >
                              <CiSliderVertical size={20} />
                            </div>
                          )}
                          {row.original.currentStatus === 'Cleared for export' && (
                            <div
                              className={`w-[30px] h-[30px] flex items-center justify-center rounded-[9px]  cursor-pointer mr-2 hover:bg-pink-100 active:bg-pink-200 bg-pink-50 text-pink-500`}
                              onClick={() => handleSelectedContextMenu(row.original.hawb)}
                            >
                              <GiConfirmed size={20} />
                            </div>
                          )}
                          {row.original.currentStatus === 'In Transit' && (
                            <div
                              className={`w-[30px] h-[30px] flex items-center justify-center rounded-[9px]  cursor-pointer mr-2 hover:bg-blue-100 active:bg-blue-200 bg-blue-50 text-blue-500`}
                              onClick={() => handleSelectedContextMenu(row.original.hawb)}
                            >
                              <MdTransitEnterexit size={20} />
                            </div>
                          )}
                          {row.original.currentStatus === 'Delivered' && (
                            <div
                              className={`w-[30px] h-[30px] flex items-center justify-center rounded-[9px]  cursor-pointer mr-2 hover:bg-emerald-100 active:bg-emerald-200 bg-emerald-50 text-emerald-500`}
                              onClick={() => handleSelectedContextMenu(row.original.hawb)}
                            >
                              <IoCheckmarkDoneSharp size={20} />
                            </div>
                          )}

                          <div className="absolute bg-white px-2.5 rounded-[5px] text-[12px] right-[-80px] z-10 shadow-[rgba(17,17,26,0.1)_0px_4px_16px,rgba(17,17,26,0.1)_0px_8px_24px,rgba(17,17,26,0.1)_0px_16px_56px] tracking-wider p-1 retriveShipment group-hover:block hidden">
                            <p className="">{row.original.currentStatus || 'Pending'}</p>
                          </div>
                        </div>
                      )}
                    {!row.original.loading && row.original.updateSuccess === 'true' && (
                      <div
                        className={`w-[30px] h-[30px] flex items-center justify-center rounded-full  cursor-pointer mr-2 ${
                          row.original.contextMenu
                            ? ' hover:opacity-70 active:opacity-50 text-white'
                            : 'text-emerald-500 bg-emerald-50'
                        }`}
                        onClick={() => handleSelectedContextMenu(row.original.hawb)}
                      >
                        <FcCheckmark size={20} />
                      </div>
                    )}
                    {row.original.loading && (
                      <div
                        className={`w-[30px] h-[30px] flex items-center justify-center rounded-full  cursor-pointer mr-2 ${
                          row.original.contextMenu
                            ? 'bg-sky-600 hover:opacity-70 active:opacity-50 text-white'
                            : 'hover:bg-slate-300 active:bg-slate-400 bg-slate-200'
                        }`}
                        onClick={() => handleSelectedContextMenu(row.original.hawb)}
                      >
                        <CircularProgress size={20} />
                      </div>
                    )}
                    {row.original.contextMenu && (
                      <div className="absolute bg-white min-w-[225px] z-[9999] ml-[333px] shadow-[rgba(136,165,191,0.48)_6px_2px_16px_0px,rgba(255,255,255,0.8)_-6px_-2px_16px_0px] mt-[174px] border-slate-200 border-solid border-[1px] search-column-input1">
                        <div className="flex flex-col p-1.5">
                          <p className="p-1 text-lg font-semibold tracking-wide" style={{ borderBottom: '1px solid' }}>
                            {row.original.hawb}
                          </p>
                          <div
                            className="p-1 px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300 mt-1.5"
                            onClick={() => {
                              handleShowShipmentLabel(row.original);
                            }}
                          >
                            <MdOutlineViewInAr size={23} />
                            <p className="mx-1.5">View shipment</p>
                            <IoIosArrowForward size={23} className="ml-auto" />
                          </div>
                          <div
                            className="p-1 px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300 "
                            onClick={() => {
                              handleShowShipment(row.original);
                            }}
                          >
                            <FaRegEdit size={23} />
                            <p className="mx-1.5">Edit shipment</p>
                          </div>
                          <div
                            className="p-1 px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300"
                            style={{ borderBottom: '1px solid' }}
                            onClick={() => {
                              navigate(`/tracking-result/${row.original.hawb}`);
                            }}
                          >
                            <MdOutlineTrackChanges size={23} />

                            <p className="mx-1.5">Track shipment</p>
                          </div>
                          <CopyToClipboard
                            onCopy={() => {
                              setCopyText(row.original.hawb);
                              setDisplayCopy(true);
                              handleCloseContextMenu(row.original.hawb);
                            }}
                            text={row.original.hawb}
                          >
                            <div className="p-1 px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300 mt-1.5">
                              <IoCopyOutline size={23} />
                              <p className="mx-1.5">Copy shipment</p>
                            </div>
                          </CopyToClipboard>
                          <div
                            className="p-1 px-2.5 flex items-center select-none cursor-pointer hover:bg-slate-200 active:bg-slate-300"
                            onClick={() => handleCloseContextMenu(row.original.hawb)}
                          >
                            <MdOutlineClear size={23} className="text-rose-500" />
                            <p className="mx-1.5">Close context</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <ShipmentBody
                    getTableBodyProps={getTableBodyProps}
                    prepareRow={prepareRow}
                    isColumnResizing={isColumnResizing}
                    row={row}
                    id={index}
                  />
                </div>
              </OutsideClickHandler>
            );
          })}
        <ShipmentBottom
          headerGroups={headerGroups}
          totalWeight={totalWeight}
          pages={pages}
          getCurrentPage={getCurrentPage}
          currentPage={currentPage}
          totalExactWeight={totalExactWeight}
          totalExactCharge={totalExactCharge}
          totalReweight={totalReweight}
          isColumnResizing={isColumnResizing}
          totalPieces={totalPieces}
          totalRate={totalRate}
          totalComestic={totalComestic}
          totalAddress={totalAddress}
          totalRemote={totalRemote}
          totalSurcharge={totalSurcharge}
          totalFscValue={totalFscValue}
          totalSurcharge2={totalSurcharge2}
          totalVatValue={totalVatValue}
          totalSurcharge3={totalSurcharge3}
          totalDemandCharge={totalDemandCharge}
          totalPrice={totalPrice}
        />
      </div>
      <div className="flex items-center mb-2" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
        <p className="m-[9px] text-slate-600 tracking-wide select-none">
          Skydart Express © 2024 | <span className="text-black">Shipments</span> |{' '}
          <span className="bg-emerald-50 p-1.5 rounded-[7px] text-emerald-700">
            Total weight:{' '}
            <span className="bg-emerald-600 text-white p-1 rounded-[7px] mr-[1px] font-semibold">{totalWeight} KG</span>
          </span>{' '}
          |{' '}
          <span className="relative">
            {showService && (
              <span className="absolute  bg-white z-[9999] h-[270px] top-[-285px] w-full rounded-[10px] shadow-[rgba(17,17,26,0.05)_0px_1px_0px,rgba(17,17,26,0.1)_0px_0px_8px] overflow-auto">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setShowService(false);
                  }}
                >
                  <span className="flex flex-col">
                    {duplicateService?.map((element) => (
                      <span
                        className="p-1 m-0.5 text-[14px] cursor-pointer hover:bg-slate-100 rounded-[10px] active:bg-slate-200 hover:font-bold hover:text-black"
                        onClick={() => {
                          setServiceStatus(element);
                          setShowService(false);
                        }}
                      >
                        {element}
                      </span>
                    ))}
                  </span>
                </OutsideClickHandler>
              </span>
            )}
            <span
              className="bg-slate-100 p-1.5 rounded-[7px] hover:opacity-70 active:opacity-50 cursor-pointer text-black"
              onClick={handleShowService}
            >
              {serviceStatus}:{' '}
              <span className="bg-black text-white p-1 rounded-[7px] mr-[1px] font-semibold">
                {serviceStatus === 'DHL'
                  ? totalDhl
                  : serviceStatus === 'FIP'
                    ? totalFip
                    : serviceStatus === 'FIE'
                      ? totalFie
                      : serviceStatus === 'F3P'
                        ? totalF3p
                        : serviceStatus === 'DHL-VN'
                          ? totalDhlVn
                          : serviceStatus === 'Fedex-VN'
                            ? totalFedexVn
                            : totalDus}{' '}
                KG
              </span>
            </span>
          </span>
          |{' '}
          <select
            value={itemPerPage}
            onChange={(e) => setItemPerPage(e.target.value)}
            className="p-1.5 rounded-[5px] cursor-pointer bg-slate-200 hover:bg-slate-300 active:bg-slate-400 outline-none"
          >
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="1000">1000</option>
          </select>
        </p>
        <div className="paginate">
          <IconButton
            type="button"
            disabled={currentPage == pages[0] ? true : false}
            style={{ width: 35, height: 35 }}
            onClick={() => handleFirstPage()}
          >
            <MdKeyboardDoubleArrowLeft className="prev-btn-page" size={40} />
          </IconButton>
          <IconButton
            type="button"
            disabled={currentPage == pages[0] ? true : false}
            style={{ width: 35, height: 35 }}
            onClick={() => handlePrevPage()}
          >
            <MdKeyboardArrowLeft className="prev-btn-page" size={40} />
          </IconButton>
          {renderPageNumbers}
          <IconButton
            type="button"
            disabled={currentPage == pages[pages.length - 1] ? true : false}
            style={{ width: 35, height: 35 }}
            onClick={() => handleNextPage()}
          >
            <MdKeyboardArrowRight className="prev-btn-page" />
          </IconButton>
          <IconButton
            type="button"
            disabled={currentPage == pages[pages.length - 1] ? true : false}
            style={{ width: 35, height: 35 }}
            onClick={() => handleLastPage()}
          >
            <MdKeyboardDoubleArrowRight className="prev-btn-page" />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

import { React, useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import CustomerFeedback from './customer/components/CustomerFeedback';
import CustomerMulti from './customer/components/CustomerMulti';
import CustomerSidebar from './customer/components/CustomerSidebar';
import Letter from './customer/components/Letter';
import MultiShipment from './customer/components/MultiShipment';
import Booking3 from './customer/routes/Booking3';
import BookingMenu from './customer/routes/BookingMenu';
import CustomerHome from './customer/routes/CustomerHome';
import Dashboard from './customer/routes/Dashboard';
import Draft from './customer/routes/Draft';
import Label from './customer/routes/Label';
import Personal from './customer/routes/Personal';
import ShipmentDetail from './customer/routes/ShipmentDetail';
import TextBooking from './customer/routes/TextBooking';
import TrackingContainer from './customer/routes/TrackingContainer';
import TrackingResult from './staff/pages/tracking/TrackingResult';

export default function CustomerView({ user }) {
  const [currentSidebar, setCurrentSidebar] = useState(true);
  const [displayFeedback, setDisplayFeedback] = useState(false);
  const [multiShipments, setMultiShipments] = useState([]);
  const [loadingContent, setLoadingContent] = useState('');
  const [errorContent, setErrorContent] = useState('');
  const handleShowFeedback = () => {
    setDisplayFeedback(true);
  };
  const handleHideFeedback = () => {
    setDisplayFeedback(false);
  };
  const handleKeyPress = (event) => {
    // Check for Ctrl + B or Cmd + B
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'b') {
      event.preventDefault(); // Prevent the default browser behavior
      setDisplayFeedback((prev) => !prev);
    }
  };
  // useEffect(() => {
  //   window.addEventListener('keydown', handleKeyPress);

  //   // Clean up the event listener
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, []);
  function removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }

  return (
    <Router>
      {/* <div className={`flex ${pathname === '/create' ? 'skydart-bookShipment' : 'customer-app'}`}> */}
      <div className={`customer-app`}>
        {displayFeedback && (
          <CustomerFeedback displayFeedback={displayFeedback} handleHideFeedback={handleHideFeedback} />
        )}
        <CustomerSidebar
          user={user}
          setCurrentSidebar={setCurrentSidebar}
          displayFeedback={displayFeedback}
          handleShowFeedback={handleShowFeedback}
        />
        <div className="flex-1">
          {/* <CustomerHome /> */}
          <Routes>
            <Route exact path="/" element={<CustomerHome user={user} />} />
            {/* <Route exact path="/create" element={<BookingMenu user={user} />} /> */}
            <Route exact path="/create" element={<Booking3 user={user} />} />
            {/* <Route exact path="/create/text-generating" element={<TextBooking user={user} />} /> */}
            {/* <Route exact path="/multi" element={<CustomerMulti user={user} />} /> */}
            <Route exact path="/create/shipment/:countryParam/:codeParam" element={<Booking3 user={user} />} />
            <Route exact path="/drafts" element={<Draft user={user} />} />
            <Route exact path="/labels" element={<Label user={user} />} />
            <Route exact path="/shipments" element={<Dashboard user={user} currentSidebar={currentSidebar} />} />
            <Route exact path="/settings" element={<Personal user={user} />} />
            <Route exact path="/tracking" element={<TrackingContainer user={user} />} />
            <Route exact path="/tracking-result/:hawb/:uid" element={<TrackingResult user={user} />} />
            {/* <Route exact path="/shipment/detail/:hawb" element={<ShipmentDetail user={user} />} /> */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

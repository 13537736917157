import React, { useState, useEffect } from 'react';
import './Label.css';
import { useNavigate } from 'react-router-dom';
import { GoCalendar } from 'react-icons/go';

import { MdLabelImportantOutline } from 'react-icons/md';
import { GrDocumentPdf } from 'react-icons/gr';
import { PiPackage } from 'react-icons/pi';
import { db } from '../../server/firebase';
export default function Label({ user }) {
  const [date, setDate] = useState('');
  const navigate = useNavigate();
  const [label, setLabel] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState([]);
  const [search, setSearch] = useState('');
  useEffect(() => {
    const today = new Date();
    let monthsName = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let dates = (today.getDate() < 10 ? '0' : '') + today.getDate();
    const months = monthsName[today.getMonth()];
    const years = today.getFullYear();
    setDate(`${dates} ${months} ${years}`);
  }, []);
  useEffect(() => {
    db.collection('label')
      .where('email', '==', user.email)
      .orderBy('timestamp', 'desc')
      .limit(100)
      .get()
      .then((snapshot) => {
        setLabel(
          snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              select: false,
              ...doc.data(),
            };
          })
        );
      });
  }, []);
  function formatDate(date) {
    return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/');
  }
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  const track = () => {
    if (search !== '') {
      if (search.startsWith('SKD')) {
        navigate(`/shipment/tracking/${search}`);
      } else {
        navigate(`/shipment/tracking/SKD${search}`);
      }
    } else {
      alert('Please type in a tracking number');
    }
  };

  const newLabel = label.filter((s) => {
    return s.select;
  });

  return (
    <div className="label-container">
      <div className="skydart-label-header">
        <p className="font-semibold text-xl tracking-wide">Shipping label</p>
      </div>
      <div className="skydart-label-nav">
        <div className="skydart-label-tracking">
          <input
            type="text"
            placeholder="Type in tracking number"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button type="button" onClick={track}>
            Track
          </button>
        </div>

        <div className="skydart-label-create-shipment" onClick={() => navigate('/create')}>
          <PiPackage size={25} className="flex-shrink-0" />
          <h5>Create a Shipment</h5>
        </div>
      </div>
      <div className="skydart-label-list">
        <div className="skydart-label-list-header">
          <div className="skydart-label-list-header-amount">
            <h5>AMOUNT</h5>
          </div>
          <div className="skydart-label-list-header-empty">{/* List */}</div>
          <div className="skydart-label-list-header-amount">
            <h5>ORIGIN</h5>
          </div>
          <div className="skydart-label-list-header-amount" style={{ marginLeft: 'auto', marginRight: 70 }}>
            <h5>PUBLISH DATE</h5>
          </div>
          <div className="skydart-label-list-header-label">{/* vIEW lABEL */}</div>
        </div>
        {label
          .filter((s) => {
            if (s.hawb.toLowerCase().includes(search.toLowerCase())) {
              return s;
            }
          })
          .map((s) => (
            <div className="skydart-label-list-body">
              <div className="skydart-label-list-body-amount">
                <h5>{s.amount} shipment</h5>
              </div>
              <div className={'skydart-label-list-body-empty1'}>
                <h5 style={{ marginLeft: 5, wordBreak: 'break-word' }}>{s.hawb.replaceAll(',', ' / ')}</h5>
                <a href={s.url} target="_blank" className="skydart-label-list-body-file">
                  <GrDocumentPdf style={{ color: 'red' }} />
                  <p>{s.fileName}</p>
                </a>
              </div>
              <div className="skydart-label-list-body-amount">
                <h5 style={{ marginLeft: 5 }}>VN</h5>
              </div>
              <div className="skydart-label-list-body-amount" style={{ marginLeft: 'auto' }}>
                <h5>{formatDate(new Date(s.date))}</h5>
              </div>
              <div className="p-2 bg-slate-200 px-4 rounded-[8px] font-semibold text-black cursor-pointer hover:bg-slate-300 active:bg-slate-400 transition-[95s]">
                <a href={s.url} target="_blank" style={{ color: 'black' }}>
                  Print label
                </a>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { IoMdAdd } from 'react-icons/io';
import skydartMan from '../../staff/pages/main/skydartMan.jpg';
import skydartCloud from './skydartCloud.png';
import { LuMoreHorizontal } from 'react-icons/lu';
import { PiPackage } from 'react-icons/pi';
import { FiRefreshCcw } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { countries } from '../../staff/api/countries';
import firebase from 'firebase/compat/app';
import OutsideClickHandler from 'react-outside-click-handler';
import { db } from '../../server/firebase';
import { IoDocument } from 'react-icons/io5';
import DashboardEdit from '../components/DashboardEdit';
import { CircularProgress } from '@mui/material';
export default function CustomerHome({ user }) {
  const [trackInput, setTrackInput] = useState('');
  const [displayMoreCountry, setDisplayMoreCountry] = useState(false);
  const [displayPayment, setDisplayPayment] = useState(false);
  const [labels, setLabels] = useState([]);
  const [pickUpShipment, setPickUpShipment] = useState([]);
  const [holdShipment, setHoldShipment] = useState([]);
  const navigate = useNavigate();
  const [loadingLabel, setLoadingLabel] = useState(false);
  const [loadingPickup, setLoadingPickup] = useState(false);
  const [displayLabel, setDisplayLabel] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState([]);
  const getTodayLabel = () => {
    const today = new Date();
    setLoadingLabel(true);
    today.setHours(0, 0, 0, 0); // Set time to the start of the day
    const startOfDayTimestamp = firebase.firestore.Timestamp.fromDate(today);
    db.collection('label')
      .where('email', '==', user.email)
      .where('timestamp', '>=', startOfDayTimestamp)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          setLoadingLabel(false);
          return;
        } else {
          let data = snapshot.docs.map((doc) => doc.data());
          setLabels(data);
          setLoadingLabel(false);
        }
      });
  };

  const getTodayPickUp = () => {
    const today = new Date();
    setLoadingPickup(true);
    today.setHours(0, 0, 0, 0); // Set time to the start of the day
    const startOfDayTimestamp = firebase.firestore.Timestamp.fromDate(today);
    db.collection('shipments')
      .where('fromEmail', '==', user.email)
      .where('timestamp', '>=', startOfDayTimestamp)
      .where('status', '==', 'Picked Up')
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          setLoadingPickup(false);
          return;
        } else {
          let data = snapshot.docs.map((doc) => doc.data());
          setPickUpShipment(data);
          setLoadingPickup(false);
        }
      });
  };

  useEffect(() => {
    getTodayLabel();
    getTodayPickUp();
  }, []);

  const handleClose = () => {
    setDisplayLabel(false);
  };
  const handleShowLabel = (chosenLabel) => {
    setDisplayLabel(!displayLabel);
    setSelectedLabel([chosenLabel]);
  };
  const finalLabel = selectedLabel.map((sd) => {
    return {
      ...sd,
      labelUrl: sd.url,
    };
  });

  return (
    <div className="w-full h-full overflow-y-auto">
      {displayLabel && (
        <DashboardEdit handleCloseShipment={handleClose} shipmentData={finalLabel[0]} user={user} stat="home" />
      )}
      <div className="p-5 flex items-center z-[9997] sticky top-0 bg-slate-100">
        <p className="font-extrabold tracking-wide text-3xl text-slate-600">
          Hi, <span className="text-black">{user.exporter}</span>, are you ready to ship yet ?
        </p>
      </div>
      <div className="p-5 flex">
        <div className="w-full">
          <p className="text-slate-600 tracking-wide">Countries that we mostly served</p>
          <div className="flex items-center my-2.5 gap-y-2.5 gap-x-2.5 flex-wrap">
            <div
              className="w-[100px] bg-white rounded-[20px] h-[125px] flex items-center justify-center flex-col shadow-[rgba(0,0,0,0.1)_0px_1px_2px_0px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none"
              onClick={() => {
                navigate(`/create/shipment/Vietnam/VN`);
              }}
            >
              <img src={`https://flagsapi.com/VN/shiny/64.png`} className="w-[40px] h-[40px]" />
              <p className="font-semibold mt-1 leading-5 text-center">Vietnam</p>
            </div>
            <div
              className="w-[100px] bg-white rounded-[20px] h-[125px] flex items-center justify-center flex-col shadow-[rgba(0,0,0,0.1)_0px_1px_2px_0px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none"
              onClick={() => {
                navigate(`/create/shipment/Australia/AU`);
              }}
            >
              <img src={`https://flagsapi.com/AU/shiny/64.png`} className="w-[40px] h-[40px]" />
              <p className="font-semibold mt-1">Australia</p>
            </div>
            <div className="w-[125px] bg-white rounded-[20px] h-[125px] flex items-center justify-center flex-col shadow-[rgba(0,0,0,0.1)_0px_1px_2px_0px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none">
              <img src={`https://flagsapi.com/US/shiny/64.png`} className="w-[40px] h-[40px]" />
              <p
                className="font-semibold mt-1 leading-5 text-center"
                onClick={() => {
                  navigate(`/create/shipment/United States/US`);
                }}
              >
                United States
              </p>
            </div>
            <div
              className="w-[100px] bg-white rounded-[20px] h-[125px] flex items-center justify-center flex-col shadow-[rgba(0,0,0,0.1)_0px_1px_2px_0px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none"
              onClick={() => {
                navigate(`/create/shipment/Canada/CA`);
              }}
            >
              <img src={`https://flagsapi.com/CA/shiny/64.png`} className="w-[40px] h-[40px]" />
              <p className="font-semibold mt-1 leading-5 text-center">Canada</p>
            </div>
            <div
              className="w-[100px] bg-white rounded-[20px] h-[125px] flex items-center justify-center flex-col shadow-[rgba(0,0,0,0.1)_0px_1px_2px_0px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none"
              onClick={() => {
                navigate(`/create/shipment/Singapore/SG`);
              }}
            >
              <img src={`https://flagsapi.com/SG/shiny/64.png`} className="w-[40px] h-[40px]" />
              <p className="font-semibold mt-1 leading-5 text-center">Singapore</p>
            </div>
            <OutsideClickHandler
              onOutsideClick={() => {
                setDisplayMoreCountry(false);
              }}
            >
              <div className="relative">
                <div
                  className={`w-[100px] rounded-[20px] h-[125px] flex items-center justify-center flex-col shadow-[rgba(0,0,0,0.1)_0px_1px_2px_0px] cursor-pointer select-none ${
                    displayMoreCountry ? 'bg-[darkblue] text-white' : 'bg-white hover:bg-slate-100 active:bg-slate-200'
                  }`}
                  onClick={() => {
                    setDisplayMoreCountry(!displayMoreCountry);
                  }}
                >
                  <IoMdAdd size={40} className={`p-1 ${displayMoreCountry ? '' : 'bg-slate-200'} rounded-[10px]`} />

                  <p className="font-semibold mt-1 leading-5 text-center">200 more</p>
                </div>
                {displayMoreCountry && (
                  <div className="absolute mt-2.5 bg-white w-[673px] rounded-[10px] right-0 shadow-[rgba(0,0,0,0.2)_0px_1px_2px_0px] max-h-[300px] overflow-auto">
                    {countries.map((item) => (
                      <div
                        className="cursor-pointer flex items-center gap-x-2 p-2 hover:bg-slate-200 active:bg-slate-300"
                        onClick={() => {
                          navigate(`/create/shipment/${item.label}/${item.code}`);
                        }}
                      >
                        <img
                          src={`https://flagsapi.com/${item.code.toUpperCase()}/shiny/64.png`}
                          className="w-[30px] h-[30px]"
                        />
                        <p>{item.label}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </OutsideClickHandler>
          </div>
          <div className="my-5">
            <div className="flex items-center">
              <p className="text-2xl font-bold text-slate-600">Today label returned</p>
              {loadingLabel && <p className="ml-2.5">Loading...</p>}
              <div
                className="w-[35px] h-[35px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 flex items-center justify-center ml-auto mr-9 cursor-pointer"
                onClick={() => {
                  if (loadingLabel) return;
                  getTodayLabel();
                }}
              >
                <FiRefreshCcw size={28} />
              </div>
            </div>
            {labels.length === 0 && (
              <div className="flex items-center justify-center flex-col my-5">
                <p>You haven't book any shipment for today</p>
                <button
                  type="button"
                  className="bg-sky-600 text-white font-semibold mt-2 p-1.5 hover:bg-sky-700 active:bg-sky-800 px-4 rounded-[12px] text-[17px]"
                  onClick={() => navigate('/create')}
                >
                  Ship Now
                </button>
              </div>
            )}
            <div className="my-2.5 flex flex-wrap items-center gap-[10px]">
              {labels.map((item) => (
                <div
                  className="w-[175px] h-[175px] flex flex-col items-center justify-center bg-white rounded-[12px] shadow-[rgba(0,0,0,0.1)_0px_1px_2px_0px] hover:bg-slate-200 active:bg-slate-300 cursor-pointer transition-[0.1s]"
                  onClick={() => {
                    handleShowLabel(item);
                  }}
                >
                  <PiPackage size={50} />
                  <p className="mt-1 font-semibold">{item.hawb}</p>
                  <p>{item.chargeable} KG</p>
                </div>
              ))}
            </div>
          </div>
          <p className="text-[14px]">Click to the box to view the label</p>
        </div>
        <div className="ml-2 w-[40%]">
          {/* <div className="relative">
            <img
              src={skydartMan}
              alt=""
              loading="lazy"
              className="z-[-1] object-cover select-none main-1 rounded-full shadow-[rgba(0,0,0,0.2)_0px_1px_2px_0px]"
            />
            <img
              src={skydartCloud}
              alt=""
              loading="lazy"
              className="w-[150px] object-cover absolute top-[-35px] right-[-20px]"
            />
          </div> */}
          <div className="p-2 mb-5">
            <div className="flex items-center">
              <p className="text-2xl font-bold text-slate-600">Today picked up</p>
              {!loadingPickup && (
                <div
                  className="w-[30px] h-[30px] rounded-full bg-white hover:bg-slate-200 active:bg-slate-300 flex items-center justify-center ml-auto mr-1 cursor-pointer"
                  onClick={getTodayPickUp}
                >
                  <FiRefreshCcw size={25} />
                </div>
              )}
              {loadingPickup && (
                <div className="w-[30px] h-[30px] rounded-full bg-white hover:bg-slate-200 active:bg-slate-300 flex items-center justify-center ml-auto mr-1 cursor-pointer">
                  <CircularProgress size={25} />
                </div>
              )}
            </div>
            <div className="mt-1">
              <p>Found {pickUpShipment.length} picked up shipments for today</p>
              <div className="flex flex-col gap-y-1.5 mt-2">
                {pickUpShipment.map((item) => {
                  const originalDateTime = new Date(item.desc[1]?.time);
                  const formattedDate =
                    originalDateTime.getDate() +
                    ' ' +
                    originalDateTime.toLocaleString('default', { month: 'short' }) +
                    ', ' +
                    originalDateTime.getFullYear();
                  const formattedTime = originalDateTime.toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: '2-digit',
                  });
                  return (
                    <div className="shadow-[rgba(0,0,0,0.1)_0px_1px_2px_0px] bg-white p-2 rounded-[10px]">
                      <div className="flex items-center">
                        {item.shipmentType === 'WPX' && <PiPackage size={27} className="text-[darkblue]" />}
                        {item.shipmentType === 'DOX' && <IoDocument size={27} className="text-[darkblue]" />}
                        <p className="font-semibold ml-1.5 text-[darkblue]">{item.hawb}</p>
                        <p className="ml-auto">{item.totalCharge} KG</p>
                      </div>
                      <p className="px-3 rounded-full bg-amber-50 text-amber-600 mt-2 w-fit">
                        Pick up at: {formattedDate} {formattedTime}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

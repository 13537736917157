import React, { useEffect, useState, useRef } from 'react';

import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { v1 as uuid } from 'uuid';
import { TbLayoutSidebarLeftCollapseFilled, TbLayoutSidebarRightCollapseFilled, TbTruckDelivery } from 'react-icons/tb';
import CustomerBill from './CustomerBill';
import { LuGrid, LuPackage, LuPackagePlus } from 'react-icons/lu';
import skydartLogo from '../../skydartLogo.png';
import {
  IoAdd,
  IoCreateOutline,
  IoDocumentAttachOutline,
  IoHome,
  IoHomeOutline,
  IoPricetagsOutline,
  IoSettingsOutline,
} from 'react-icons/io5';
import { PiPackageBold, PiUserCircleGearFill } from 'react-icons/pi';
import { FaQuestion, FaRegNoteSticky, FaSortDown } from 'react-icons/fa6';
import {
  MdMoreVert,
  MdOutlineClass,
  MdOutlineClear,
  MdOutlineFeedback,
  MdOutlineLanguage,
  MdOutlineTrackChanges,
} from 'react-icons/md';
import { IoMdAdd, IoMdOptions } from 'react-icons/io';
import { GoPackageDependents } from 'react-icons/go';
import { VscAccount, VscCommentDraft, VscSaveAs } from 'react-icons/vsc';
import { FaSignOutAlt } from 'react-icons/fa';
import { db, auth } from '../../server/firebase';
import OutsideClickHandler from 'react-outside-click-handler';
import { CiMenuBurger, CiSearch } from 'react-icons/ci';
import { FiMenu } from 'react-icons/fi';
import { GrContact } from 'react-icons/gr';
import MultiShipment from './MultiShipment';
import StaffBill2 from '../../components/StaffBill2';
import { TextField } from '@mui/material';
import PackageItem from './PackageItem';
import { BiRefresh } from 'react-icons/bi';
import { FcShop } from 'react-icons/fc';
import { BsShop } from 'react-icons/bs';

export default function CustomerSidebar({ user, setCurrentSidebar, handleShowFeedback }) {
  const pathName = useLocation();
  const navigate = useNavigate();
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const [displayUser, setDisplayUser] = useState(false);
  const [displayLang, setDisplayLang] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [displayAccount, setDisplayAccount] = useState(false);
  const [multiShipments, setMultiShipments] = useState([]);
  const [selectedShipments, setSelectedShipments] = useState([]);
  const [displayPackaging, setDisplayPackaging] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');
  const [packageValue, setPackageValue] = useState([
    {
      packageLength: 0,
      packageWidth: 0,
      packageHeight: 0,
      packageWeight: 0,
      packageCharge: 0,
      packNo: 1,
      pack: '',
      confirm: false,
      floatingPositions: [...Array(6)].map(() => ({
        top: `${Math.random() * 100}%`,
        left: `${Math.random() * 100}%`,
      })),
    },
  ]);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [invoiceValue, setInvoiceValue] = useState([
    {
      id: uuid(),
      isSelectItem: false,
      itemCommonity: '',
      itemVietnamCommonity: '',
      itemCountryOrigin: 'Vietnam',
      itemManufactAddress: '',
      itemPackType: '',
      itemPackType2: '',
      itemHsCode: '',
      itemPnc: '',
      itemUnit: '',
      itemOtherUnit: '',
      itemPurpose: '',
      itemManufact: '',
      itemUnitPrice: 0,
      itemQuantity: 0,
      itemValue: 0,
      itemBrand: '',
      itemIngredients: '',
      itemNo: 1,
      itemFda: '',
      itemUnit2: '',
    },
  ]);
  const [currentShipment, setCurrentShipment] = useState({});
  useEffect(() => {
    setCurrentSidebar(displaySidebar);
  }, [displaySidebar]);
  const menuArray = [
    { label: 'Home', path: '/', icon: <IoHomeOutline size={30} className="p-1" /> },
    // { label: 'Shop', path: '/shop', icon: <BsShop size={30} className="p-1" /> },
    // {
    //   label: 'My Shipping',
    //   path: '/my-shipping',
    //   icon: <LuPackage size={30} className="p-1" />,
    // },
    { label: 'Create shipments', path: '/create', icon: <LuPackagePlus size={30} className="p-1" /> },
    {
      label: 'Manage shipments',
      path: '/shipments',
      icon: <LuGrid size={30} className="p-1" />,
    },
    {
      label: 'Draft bookings',
      path: '/drafts',
      icon: <VscSaveAs size={30} className="p-1" />,
    },
    {
      label: 'Labels',
      path: '/labels',
      icon: <MdOutlineClass size={30} className="p-1" />,
    },
    {
      label: 'Track shipments',
      path: '/tracking',
      icon: <MdOutlineTrackChanges size={30} className="p-1" />,
    },

    // {
    //   label: 'Docs & Use cases',
    //   path: '/docs',
    //   icon: <IoDocumentAttachOutline size={30} className="p-1" />,
    // },
  ];
  function removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }
  const currentPath = pathName.pathname;
  const inActive =
    'flex items-center p-1 px-[7px] rounded-full border-slate-300 hover:bg-slate-100 select-none active:bg-slate-300 cursor-pointer my-0.5  group font-semibold text-slate-600 hover:text-black';

  const active =
    'flex items-center p-1 px-[7px] rounded-full border-slate-300 select-none cursor-pointer my-0.5  group font-semibold  bg-slate-200 hover:bg-slate-300 active:bg-slate-400';

  const onSearchShipment = (e) => {
    e.preventDefault();
    navigate(`/shipment/detail/${searchValue}`);
    setDisplaySearch(false);
  };
  const pathname = useLocation();
  if (pathname.pathname === '/create/text-generating') return null;
  return (
    <>
      {!displaySidebar && (
        <div
          className={` bg-white h-[100vh] shadow-[rgba(0,0,0,0.025)_1.95px_1.95px_2.6px] flex flex-col overflow-y-auto`}
        >
          <div
            className="p-2 px-[9px] flex items-center sticky top-0 bg-white z-10"
            style={{ borderBottom: '1px solid rgb(230,230,230)' }}
          >
            <img src={skydartLogo} alt="" loading="lazy" className="w-[40px] h-[40px] rounded-full object-contain" />
          </div>
          <div className="flex items-center justify-center group">
            <div className="absolute left-[65px] bg-white z-[9999] mt-1 p-1 text-[14px] px-2.5 rounded-[7px] shadow-[rgba(17,17,26,0.05)_0px_4px_16px,rgba(17,17,26,0.05)_0px_8px_32px] group-hover:block hidden">
              <p>Expand sidebar</p>
            </div>
            <div
              className="flex items-center justify-center w-[35px] h-[35px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer mt-1.5 text-slate-500"
              onClick={() => {
                setDisplaySidebar(!displaySidebar);
              }}
            >
              <FiMenu size={25} />
            </div>
          </div>
          <div className="p-2 flex flex-col">
            <OutsideClickHandler
              onOutsideClick={() => {
                setDisplayUser(false);
              }}
            >
              <div className="relative p-[7px]">
                <PiUserCircleGearFill size={30} className="p-1 rounded-full bg-emerald-600 text-white" />
              </div>
            </OutsideClickHandler>
            {menuArray.map((item) => (
              <NavLink exact to={item.path} className={currentPath === item.path ? active : inActive}>
                <div className={currentPath === item.path ? '' : ''}>{item.icon}</div>
                <div className="absolute left-[65px] bg-white z-[9999] mt-1 p-1 text-[14px] px-2.5 rounded-[7px] shadow-[rgba(17,17,26,0.05)_0px_4px_16px,rgba(17,17,26,0.05)_0px_8px_32px] group-hover:block hidden ">
                  <p>{item.label}</p>
                </div>
              </NavLink>
            ))}
          </div>
          <div className="p-2 mt-auto" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
            {/* <div className="flex items-center p-[7px] rounded-[7px] border-slate-300 bg-amberr-50 bg-amber-50 hover:bg-amber-100 select-none active:bg-amber-200 cursor-pointer my-1">
            <FaQuestion size={30} className="p-1" />

            <p className="ml-3">Services support</p>
          </div> */}
            <NavLink exact to="/settings" className={currentPath === '/settings' ? active : inActive}>
              <div
                className={
                  currentPath === '/settings'
                    ? 'bg-gradient-to-r from-emerald-500 via-blue-800 to-emerald-600 rounded-full text-white'
                    : ''
                }
              >
                <IoSettingsOutline size={30} className="p-1" />
                <div className="absolute left-[65px] bg-white z-[9999] mt-[-30px] p-1 text-[14px] px-2.5 rounded-[7px] shadow-[rgba(17,17,26,0.05)_0px_4px_16px,rgba(17,17,26,0.05)_0px_8px_32px] group-hover:block hidden ">
                  <p style={{ fontWeight: 500, color: 'black' }}>Setting</p>
                </div>
              </div>
            </NavLink>
            <div
              className="flex items-center p-[7px] rounded-[7px] border-slate-300  hover:bg-rose-100 select-none active:bg-rose-200 cursor-pointer my-1 text-rose-600 font-semibold group"
              onClick={() => {
                auth.signOut();
                navigate('/');
              }}
            >
              <FaSignOutAlt size={30} className="p-1" />
              <div className="absolute left-[65px] bg-white z-[9999] mt-1 p-1 text-[14px] px-2.5 rounded-[7px] shadow-[rgba(17,17,26,0.05)_0px_4px_16px,rgba(17,17,26,0.05)_0px_8px_32px] group-hover:block hidden ">
                <p style={{ fontWeight: 500, color: 'black' }}>Sign out</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {displaySidebar && (
        <div
          className={`w-[300px] bg-white h-[100vh] flex flex-col overflow-y-auto flex-shrink-0 sticky left-0 z-[9999] border-r-[1px] border-y-0 border-l-0 border-solid border-slate-300`}
        >
          <div className="p-2 px-[9px] flex items-center sticky top-0 bg-white z-20 pb-1 border-b-[1px] border-t-0 border-x-0 border-slate-300 border-solid">
            <img
              src={skydartLogo}
              alt=""
              loading="lazy"
              className="w-[40px] h-[40px] rounded-full object-contain hover:opacity-70 active:opacity-50 cursor-pointer transition-[95s]"
              onClick={() => {
                navigate('/');
              }}
            />
            <p className="text-lg ml-1.5 font-semibold text-[darkblue] tracking-wide">SKYDART EXPRESS</p>
            <div
              className="flex items-center justify-center w-[35px] h-[35px] rounded-[10px] bg-slate-100 hover:bg-slate-200 active:bg-slate-300 active:scale-90 cursor-pointer ml-auto text-slate-500"
              onClick={() => {
                setDisplaySidebar(!displaySidebar);
              }}
            >
              <FiMenu size={25} />
            </div>
          </div>
          <div className="p-2 flex flex-col">
            {/* <OutsideClickHandler
              onOutsideClick={() => {
                setDisplaySearch(false);
              }}
            >
              <form
                className={`bg-white p-1 ${
                  displaySearch ? 'shadow-[rgba(0,0,0,0.2)_0px_2px_8px]' : ''
                } relative  mb-1 rounded-tl-[9px] rounded-tr-[9px] z-10`}
                onSubmit={onSearchShipment}
              >
                <div
                  className={`flex items-center`}
                  onClick={() => {
                    setDisplaySearch(true);
                  }}
                >
                  <CiSearch size={25} className="absolute left-2.5" />
                  <input
                    type="text"
                    required
                    placeholder="Search with Skydart"
                    className="rounded-full bg-slate-100 w-[300px] p-[7px] pl-[47px] pr-[39px] outline-none"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <button type="submit" className="hidden">
                    Submit
                  </button>
                  {searchValue && (
                    <MdOutlineClear
                      size={25}
                      className="absolute right-2.5 rounded-full hover:bg-slate-200 p-1 cursor-pointer active:scale-90"
                      onClick={() => {
                        setSearchValue('');
                      }}
                    />
                  )}
                </div>
                {displaySearch && (
                  <div className="bg-white top-[46px] absolute left-0 w-full rounded-bl-[9px] rounded-br-[9px] shadow-[rgba(0,0,0,0.2)_0px_2px_2px] p-1">
                    <div className="flex items-center p-1.5 select-none">
                      <p className="font-semibold text-lg">Gần đây</p>
                      <p className="ml-auto text-[14px] text-[darkblue] hover:underline active:opacity-50 cursor-pointer">
                        Xoá hết
                      </p>
                    </div>
                    <div className="p-1.5 flex items-center hover:bg-blue-50 active:bg-blue-100 rounded-[8px] select-none cursor-pointer transition-[98s] text-slate-600 hover:text-[darkblue]">
                      <PiPackageBold
                        size={34}
                        className="rounded-full p-1.5 border-slate-300 border-solid border-[1px] bg-slate-200"
                      />
                      <p className=" font-semibold  ml-2 text-[15px]">SKD0230923092</p>
                    </div>
                  </div>
                )}
              </form>
            </OutsideClickHandler> */}
            {/* <div
              className="p-3 bg-gradient-to-tr from-green-600 to-[darkblue] w-fit rounded-full mb-2 font-semibold text-white px-5 cursor-pointer select-none flex items-center hover:opacity-70 active:opacity-50 transition-[98s]"
              onClick={() => {
                navigate('/multi');
              }}
            >
              <IoCreateOutline size={20} />

              <p className="ml-[9px]">Multiship</p>
            </div> */}
            {/* <div
              className="p-3 bg-slate-200 w-fit rounded-full mb-2 font-semibold  px-5 cursor-pointer select-none flex items-center hover:opacity-70 active:opacity-50 transition-[98s]"
              onClick={() => {
                navigate('/create/text-generating');
              }}
            >
              <IoCreateOutline size={20} />

              <p className="ml-[9px]">A.I</p>
            </div> */}
            {menuArray.map((item) => (
              <NavLink exact to={item.path} className={currentPath === item.path ? active : inActive}>
                <div>{item.icon}</div>
                <p className={`ml-3`}>{item.label}</p>
              </NavLink>
            ))}
            {/* <div
              className={inActive}
              onClick={() => {
                handleShowFeedback();
              }}
            >
              <div>
                <MdOutlineFeedback size={30} className="p-1" />
              </div>
              <p className={`ml-3`}>Feedback Skydart (Ctrl + B)</p>
            </div> */}
          </div>
          <div className="p-2 mt-auto sticky bottom-0 bg-white z-10 border-t-[1px] border-b-0 border-x-0 border-slate-300 border-solid">
            {/* <div className="flex items-center p-[7px] rounded-[7px] border-slate-300 bg-amberr-50 bg-amber-50 hover:bg-amber-100 select-none active:bg-amber-200 cursor-pointer my-1">
            <FaQuestion size={30} className="p-1" />

            <p className="ml-3">Services support</p>
          </div> */}
            {/* <div className="flex items-center p-1 px-[7px] rounded-[8px] border-slate-400 hover:bg-slate-100 select-none active:bg-slate-300 cursor-pointer my-0.5  group font-semibold text-slate-600 hover:text-black border-solid border-[1px]">
              <div>
                <MdOutlineLanguage size={30} className="p-1" />
              </div>
              <p className={`ml-3 `}>Languages</p>
              <FaSortDown size={28} className="mt-[-12px] ml-auto mr-1" />
            </div> */}
            {/* <div className="flex items-center p-1 mt-1 px-[7px] rounded-full  hover:bg-slate-100 select-none active:bg-slate-300 cursor-pointer my-0.5  group font-semibold text-slate-600 hover:text-black ">
              <div>
                <GrContact size={30} className="p-1" />
              </div>
              <p className={`ml-3 ${currentPath === '/settings' ? 'skydart-main-primary-left-header2' : ''}`}>
                Chat with Skydart
              </p>
            </div> */}
            <NavLink exact to="/settings" className={currentPath === '/settings' ? active : inActive}>
              <div
                className={
                  currentPath === '/settings'
                    ? 'bg-gradient-to-r from-emerald-500 via-blue-800 to-emerald-600 rounded-full text-white'
                    : ''
                }
              >
                <IoSettingsOutline size={30} className="p-1" />
              </div>
              <p className={`ml-3 ${currentPath === '/settings' ? 'skydart-main-primary-left-header2' : ''}`}>
                Settings account
              </p>
            </NavLink>
            <div
              className="flex items-center p-1 px-[7px] rounded-[7px]   hover:bg-rose-100 select-none active:bg-rose-200 cursor-pointer my-1 text-rose-500 font-semibold"
              onClick={() => {
                auth.signOut();
                navigate('/');
              }}
            >
              <div>
                <FaSignOutAlt size={30} className="p-1 rotate-[180deg]" />
              </div>
              <p className={`ml-3`}>Sign Out</p>
            </div>
            {/* <div className="relative">
              <div className="flex items-center p-[7px] rounded-[7px]   hover:bg-slate-100 select-none active:bg-slate-200 cursor-pointer my-1 text-slate-600 font-semibold">
                <VscAccount size={30} className="p-1 " />

                <p className="ml-3">Account</p>
              </div>
              <div className="absolute bg-white w-full z-10 top-[-32px] shadow-[rgba(0,0,0,0.08)_0px_4px_12px] p-1 rounded-[6px]">
                <div
                  className="p-1.5 flex items-center gap-1.5 rounded-[6px] bg-white hover:bg-slate-100 active:bg-slate-200 transition-[98s]"
                  role="button"
                  onClick={() => {
                    auth.signOut();
                    navigate('/');
                  }}
                >
                  <FaSignOutAlt size={30} className="p-1 rotate-[180deg]" />
                  <p className="font-medium">Sign out</p>
                </div>
                <div
                  className="p-1.5 flex items-center gap-1.5 rounded-[6px] bg-white hover:bg-slate-100 active:bg-slate-200 transition-[98s]"
                  role="button"
                >
                  <FaSignOutAlt size={30} className="p-1 rotate-[180deg]" />
                  <p className="font-medium">Upgrade account</p>
                </div>
              </div>
            </div> */}
            <p className="text-slate-600 text-[15px] p-1" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
              Skydart Express © 2025
            </p>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { FaSortDown } from 'react-icons/fa6';
import { MdSwitchAccessShortcutAdd } from 'react-icons/md';

function ShipmentBottom({
  headerGroups,
  totalWeight,
  totalExactWeight,
  totalExactCharge,
  totalReweight,
  totalPieces,
  totalRate,
  totalComestic,
  totalAddress,
  totalRemote,
  totalSurcharge,
  totalFscValue,
  totalSurcharge2,
  totalVatValue,
  totalSurcharge3,
  totalPrice,
  pages,
  currentPage,
  getCurrentPage,
  totalDemandCharge,
}) {
  const [pageInput, setPageInput] = useState(1);

  useEffect(() => {
    setPageInput(currentPage);
  }, [currentPage]);
  return (
    <div className="flex items-center sticky bottom-0 bg-white z-[9997] w-fit shadow-[0px_-4px_3px_rgba(50,50,50,0.1)] mt-auto">
      <div
        className="sticky left-0 z-[9998] bg-white flex items-center shadow-[rgba(0,0,0,0.05)_2.4px_0px_0px]"
        style={{ border: '1px solid lightgray', borderBottom: 'none', borderRight: 'none' }}
      >
        <form
          className="w-[303px] h-[52px] flex items-center currentPage-input mx-2.5"
          onSubmit={(e) => {
            e.preventDefault();
            getCurrentPage(pageInput);
          }}
        >
          <p className="font-semibold">Current page:</p>
          <input
            type="number"
            className=" text-2xl w-[55px] bg-slate-100 search-column-input rounded-[5px] focus:bg-white text-center mx-2"
            value={pageInput}
            onChange={(e) => setPageInput(e.target.value)}
          />
          <p className="text-2xl mr-2">/</p>
          <p className="max-w-[50px] min-w-[30px] h-[30px] rounded-full bg-white shadow-[rgba(0,0,0,0.25)_0px_0.0625em_0.0625em,rgba(0,0,0,0.25)_0px_0.125em_0.5em,rgba(255,255,255,0.1)_0px_0px_0px_1px_inset] flex items-center justify-center">
            {pages?.length}
          </p>
          <button
            type="submit"
            className="ml-auto p-1 bg-emerald-600 hover:bg-emerald-700 active:scale-90 px-2 text-white rounded-[4px] font-semibold"
          >
            Go {'->'}
          </button>
        </form>
      </div>
      {headerGroups?.map((headerGroup) => (
        <div {...headerGroup.getHeaderGroupProps({})} className="tr">
          {headerGroup.headers.map((column, index) => (
            <div className="relative">
              {column.active && (
                <div
                  {...column.getHeaderProps()}
                  className="th flex items-center justify-between text-gray-600 text-[17px] relative"
                >
                  {index === 7 && (
                    <p className="elipe-text" style={{ color: 'darkblue', textAlign: 'left' }}>
                      {totalWeight} KG
                    </p>
                  )}
                  {index === 8 && (
                    <p style={{ color: 'black', textAlign: 'left' }} className="elipe-text">
                      {totalExactWeight} KG
                    </p>
                  )}
                  {index === 9 && (
                    <p style={{ color: 'black', textAlign: 'left' }} className="elipe-text">
                      {totalExactCharge} KG
                    </p>
                  )}
                  {index === 10 && (
                    <p style={{ textAlign: 'left' }} className="elipe-text text-rose-600">
                      {totalReweight} KG
                    </p>
                  )}
                  {index === 11 && (
                    <p style={{ color: 'black', textAlign: 'left' }} className="elipe-text">
                      {totalPieces} pcs
                    </p>
                  )}
                  {index === 18 && (
                    <p
                      style={{ textAlign: 'left' }}
                      className="elipe-text px-2 bg-emerald-50 text-emerald-600 rounded-full"
                    >
                      {totalRate}
                    </p>
                  )}
                  {index === 19 && (
                    <p style={{ color: 'black', textAlign: 'left' }} className="elipe-text">
                      {totalComestic}
                    </p>
                  )}
                  {index === 20 && (
                    <p style={{ color: 'black', textAlign: 'left' }} className="elipe-text">
                      {totalAddress}
                    </p>
                  )}
                  {index === 21 && (
                    <p style={{ color: 'black', textAlign: 'left' }} className="elipe-text">
                      {totalRemote}
                    </p>
                  )}
                  {index === 22 && (
                    <p style={{ color: 'black', textAlign: 'left' }} className="elipe-text">
                      {totalSurcharge}
                    </p>
                  )}
                  {index === 24 && (
                    <p style={{ color: 'black', textAlign: 'left' }} className="elipe-text">
                      {totalFscValue}
                    </p>
                  )}
                  {index === 25 && (
                    <p style={{ color: 'black', textAlign: 'left' }} className="elipe-text">
                      {totalSurcharge2}
                    </p>
                  )}
                  {index === 27 && (
                    <p style={{ color: 'black', textAlign: 'left' }} className="elipe-text">
                      {totalVatValue}
                    </p>
                  )}
                  {index === 28 && (
                    <p style={{ color: 'black', textAlign: 'left' }} className="elipe-text">
                      {totalSurcharge3}
                    </p>
                  )}
                  {index === 29 && (
                    <p style={{ textAlign: 'left' }} className="elipe-text">
                      {totalDemandCharge || '0.00'}
                    </p>
                  )}
                  {index === 30 && (
                    <p style={{ textAlign: 'left' }} className="elipe-text text-rose-600">
                      {totalPrice}
                    </p>
                  )}
                  <div className="w-[30px] h-[30px] flex items-center justify-center hover:bg-slate-200 active:bg-slate-300 active:scale-90 rounded-full cursor-pointer opacity-0">
                    <FaSortDown size={28} className="mt-[-10px]" />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default React.memo(ShipmentBottom, (prevProps, nextProps) => nextProps.isColumnResizing);

import React, { useState, useEffect } from 'react';
import axios from 'axios';
export default function Debit2({
  exporter,
  currency,
  address,
  startDate,
  endDate,
  address2,
  address3,
  mst,
  displayName,
  email,
  phone,
  makh,
  getCustomer,
}) {
  const [debitData, setDebitData] = useState([]);
  const [startDateInput, setStartDateInput] = useState(startDate);
  const [endDateInput, setEndDateInput] = useState(endDate);
  const [currencyExchange, setCurrencyExchange] = useState(0);
  const [vnd, setVnd] = useState(0);

  // useEffect(() => {
  //     const getCompany = userData.exporter;
  //     axios.get(`https://3055-113-161-76-170.ngrok.io/shipment/debit/${getCompany}`).then((snapshot) => {
  //         setDebitData(snapshot.data);
  //     })
  // }, [])
  const cusDebit = getCustomer;
  const totalAmount = cusDebit.reduce((a, v) => (a = +a + +v.totalValue), 0);
  const totalVND = totalAmount.toFixed(2) * currency;
  const totalRate = cusDebit.reduce((a, v) => (a = +a + +v.rate), 0);
  const totalWeight = cusDebit.reduce((a, v) => (a = +a + +v.chargeable), 0);
  const totalPieces = cusDebit.reduce((a, v) => (a = +a + +v.amount), 0);
  const totalReweight = cusDebit.reduce((a, v) => (a = +a + +v.reweight), 0);
  const totalPrice = cusDebit.reduce((a, v) => (a = +a + +v.totalValue), 0);
  const totalSurcharge3 = cusDebit.reduce((a, v) => (a = +a + +v.surcharge3), 0);
  const totalVatValue = cusDebit.reduce((a, v) => (a = +a + +v.vatValue), 0);
  const totalSurcharge2 = cusDebit.reduce((a, v) => (a = +a + +v.surcharge2), 0);
  const totalFscValue = cusDebit.reduce((a, v) => (a = +a + +v.fscValue), 0);
  const totalSurcharge1 = cusDebit.reduce((a, v) => (a = +a + +v.surcharge), 0);
  const totalComestic = cusDebit.reduce((a, v) => (a = +a + +v.comestic), 0);
  const totalRemote = cusDebit.reduce((a, v) => (a = +a + +v.remote), 0);
  const totalDemandCharge = cusDebit.reduce((a, v) => (a = +a + +v.demandCharge || 0), 0);
  const totalAddressCorrection = cusDebit.reduce((a, v) => (a = +a + +v.addressCorrection), 0);
  const handleChangeTotal = (e) => {
    setCurrencyExchange(e.target.value);
  };
  return (
    <div className="debit-note2" style={{ overflow: 'hidden' }}>
      <div className="debit-note1-container">
        <div className="wrap-debit1" style={{ background: 'white', border: '2px solid' }}>
          <div className="debit-note1-content">
            <div className="debit-note1-content-header">
              <div className="debit-note1-content-header-left">
                <h3>CÔNG TY TNHH QUỲNH VŨ</h3>
                <h5 style={{ fontWeight: 600 }}>Địa chỉ: 32 Lam Sơn Phường 2 Quận Tân Bình thành phố Hồ Chí Minh</h5>
                <div className="debit-note1-content-nav-option">
                  <h5 style={{ width: '15%' }}>MST</h5>
                  <h5>
                    <i>0 3 0 3 3 6 9 2 0 5</i>
                  </h5>
                </div>
              </div>
              <div className="debit-note1-content-header-right">
                <h3>+84 28 3848 7976</h3>
                <h3>+84 28 3848 7980</h3>
              </div>
            </div>
            <div className="debit-note1-content-nav">
              <div className="debit-note1-content-nav-option">
                <h5 style={{ width: '30%' }}>Số TK VND 112 0000 13407</h5>
                <h5>
                  <i>Ngân Hàng thương mại cổ phần công thương Việt Nam - CN 1 - TPHCM</i>
                </h5>
              </div>
              <div className="debit-note1-content-nav-option">
                <h5 style={{ width: '30%' }}>Số TK USD 114 0001 98472</h5>
                <h5>VIETINBANK</h5>
              </div>
              <div className="debit-note1-content-nav-option">
                <h5 style={{ width: '30%' }}>Swif Code ICBVVNVX902</h5>
                <h5> Địa chỉ 93 - 95 Hàm Nghi Quận 1 - TP Hồ Chí Minh</h5>
              </div>
            </div>
          </div>
          <div className="debit-note1-content" style={{ borderTop: 'none', borderBottom: 'none' }}>
            <center style={{ padding: 20 }}>
              <h3>THÔNG BÁO THANH TOÁN - PAYMENT NOTIFICATION</h3>
            </center>
            <div className="debit-note1-body">
              <div className="debit-note1-body-left">
                <div className="debit-note1-body-option">
                  <h5 style={{ width: '25%' }}>Company Name:</h5>
                  <h5>{exporter}</h5>
                </div>
                <div className="debit-note1-body-option">
                  <h5 style={{ width: '25%' }}>Address:</h5>
                  <h5>{address}</h5>
                </div>
                <div className="debit-note1-body-option">
                  <h5 style={{ width: '25%' }}>Address2:</h5>
                  <h5>{address2}</h5>
                </div>
                <div className="debit-note1-body-option">
                  <h5 style={{ width: '25%' }}>Address3:</h5>
                  <h5>{address3}</h5>
                </div>
                <div className="debit-note1-body-option">
                  <h5 style={{ width: '25%' }}>MST:</h5>
                  <h5>{mst}</h5>
                </div>
                <div className="debit-note1-body-option">
                  <h5 style={{ width: '25%' }}>Contact Name:</h5>
                  <h5>{displayName}</h5>
                </div>
                <div className="debit-note1-body-option">
                  <h5 style={{ width: '25%' }}>E-mail Address:</h5>
                  <h5>{email}</h5>
                </div>
                <div className="debit-note1-body-option">
                  <h5 style={{ width: '25%' }}>Tel:</h5>
                  <h5>{phone}</h5>
                </div>
              </div>
              <div className="debit-note1-body-right">
                <div className="debit-note2-body-option">
                  <h5>Debit Note NC</h5>
                </div>
                <div className="debit-note2-body-option1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h5>Date Of Issue</h5>
                  <h5 style={{ marginRight: 35 }}>{endDate}</h5>
                </div>
                <div className="debit-note2-body-option">
                  <h5 style={{ width: '60%' }}>MAKH</h5>
                  <h5>{makh}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="skydart-customer-debit">
            <div className="skydart-customer-debit-header">
              <div className="skydart-customer-debit-header-option">
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>Date</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '16%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>SKD HAWB</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '13%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>INTL AWB</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '13%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>Ship To</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>Type</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>Weight (KG)</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>RW (KG)</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>Rate (USD)</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>Extra (USD)</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>Add (USD)</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>RM (USD)</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>Extra 1</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>Demand charge</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>FSC (USD)</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>Extra 2</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>VAT (USD)</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>Extra 3</h5>
              </div>
              <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                <h5 style={{ fontWeight: 700, fontSize: 11 }}>TOTAL (USD)</h5>
              </div>
            </div>

            {cusDebit.map((s, index) => {
              return (
                <div className="skydart-customer-debit-header">
                  <div className="skydart-customer-debit-header-option">
                    <h5>{s.date}</h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ width: '16%' }}>
                    <h5>{s.hawb}</h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ width: '13%' }}>
                    <h5>{s.sin}</h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ width: '13%' }}>
                    <h5>{s.country}</h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ width: '5%' }}>
                    <h5>{s.type}</h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ display: 'flex', width: '5%' }}>
                    <h5 style={{ marginLeft: 'auto' }}>{(Math.round(s.chargeable * 100) / 100).toFixed(2)}</h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ display: 'flex', width: '5%' }}>
                    <h5 style={{ marginLeft: 'auto' }}>{(Math.round(s.reweight * 100) / 100).toFixed(2)}</h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ display: 'flex', width: '5%' }}>
                    <h5 style={{ marginLeft: 'auto' }}>
                      {s.rate.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ display: 'flex', width: '5%' }}>
                    <h5 style={{ marginLeft: 'auto' }}>
                      {s.comestic.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ display: 'flex', width: '5%' }}>
                    <h5 style={{ marginLeft: 'auto' }}>
                      {s.addressCorrection.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ display: 'flex', width: '5%' }}>
                    <h5 style={{ marginLeft: 'auto' }}>
                      {s.remote.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ display: 'flex', width: '5%' }}>
                    <h5 style={{ marginLeft: 'auto' }}>{(Math.round(s.surcharge * 100) / 100).toFixed(2)}</h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ display: 'flex', width: '5%' }}>
                    <h5 style={{ marginLeft: 'auto' }}>{(Math.round(s.demandCharge * 100) / 100).toFixed(2)}</h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ display: 'flex', width: '5%' }}>
                    <h5 style={{ marginLeft: 'auto' }}>{(Math.round(s.fscValue * 100) / 100).toFixed(2)}</h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ display: 'flex', width: '5%' }}>
                    <h5 style={{ marginLeft: 'auto' }}>{(Math.round(s.surcharge2 * 100) / 100).toFixed(2)}</h5>
                  </div>

                  <div className="skydart-customer-debit-header-option" style={{ display: 'flex', width: '5%' }}>
                    <h5 style={{ marginLeft: 'auto' }}>{(Math.round(s.vatValue * 100) / 100).toFixed(2)}</h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ display: 'flex', width: '5%' }}>
                    <h5 style={{ marginLeft: 'auto' }}>{(Math.round(s.surcharge3 * 100) / 100).toFixed(2)}</h5>
                  </div>
                  <div className="skydart-customer-debit-header-option" style={{ display: 'flex', width: '5%' }}>
                    <h5 style={{ marginLeft: 'auto' }}>
                      {s.totalValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h5>
                  </div>
                </div>
              );
            })}
            <div className="skydart-customer-debit-header" style={{ borderTop: '1px solid', flexDirection: 'column' }}>
              <div className="skydart-customer-debit-sum" style={{ borderBottom: '1px solid' }}>
                <h3 style={{ fontWeight: 600, fontSize: 15 }}>Summary</h3>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Period:</h5>
                <div className="debit-period">
                  <h5>{startDate}</h5>
                  <h5>To</h5>
                  <h5>{endDate}</h5>
                </div>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Total Weight:</h5>
                <h5 style={{ textDecoration: 'underline' }}>{(Math.round(totalWeight * 100) / 100).toFixed(2)} KG</h5>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Total Reweight:</h5>
                <h5 style={{ textDecoration: 'underline' }}>{(Math.round(totalReweight * 100) / 100).toFixed(2)} KG</h5>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Total Rate:</h5>
                <h5 style={{ textDecoration: 'underline' }}>{totalRate.toLocaleString('en-US')} USD</h5>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Total Address Correction:</h5>
                <h5 style={{ textDecoration: 'underline' }}>
                  {totalAddressCorrection.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{' '}
                  USD
                </h5>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Total Remote:</h5>
                <h5 style={{ textDecoration: 'underline' }}>
                  {totalRemote.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                </h5>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Total Extra 1:</h5>
                <h5 style={{ textDecoration: 'underline' }}>
                  {totalSurcharge1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
                  USD
                </h5>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Total Demand Charge:</h5>
                <h5 style={{ textDecoration: 'underline' }}>
                  {totalDemandCharge.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
                  USD
                </h5>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Total Fuel Value:</h5>
                <h5 style={{ textDecoration: 'underline' }}>
                  {totalFscValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                </h5>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Total Comestic:</h5>
                <h5 style={{ textDecoration: 'underline' }}>
                  {totalComestic.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                </h5>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Total Extra 2:</h5>
                <h5 style={{ textDecoration: 'underline' }}>
                  {totalSurcharge2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
                  USD
                </h5>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Total VAT Value:</h5>
                <h5 style={{ textDecoration: 'underline' }}>
                  {totalVatValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                </h5>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Total Extra 3:</h5>
                <h5 style={{ textDecoration: 'underline' }}>
                  {totalSurcharge3.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{' '}
                  USD
                </h5>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Total Value In USD:</h5>
                <h5 style={{ textDecoration: 'underline', color: '#0068be' }}>
                  {totalAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                </h5>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Currency Exchange:</h5>
                <h5 style={{ textDecoration: 'underline' }}>{currency}</h5>
              </div>
              <div className="skydart-customer-debit-sum">
                <h5>Total Value In VND:</h5>
                <h5 style={{ textDecoration: 'underline', color: 'red' }}>
                  {totalVND.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} VND
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

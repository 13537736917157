import { START_DATE, useDatepicker } from '@datepicker-react/hooks';
import React, { useEffect, useState } from 'react';
import { AiOutlineMinus, AiTwotoneInteraction } from 'react-icons/ai';
import { BsArrowLeft, BsArrowRight, BsColumnsGap } from 'react-icons/bs';
import { FaDownload, FaSortDown, FaTruckPickup } from 'react-icons/fa6';
import { useTable, useResizeColumns, useFlexLayout, useColumnOrder } from 'react-table';
import { FcCheckmark } from 'react-icons/fc';
import { FiMoreVertical, FiRefreshCcw } from 'react-icons/fi';
import { GoCalendar, GoSearch } from 'react-icons/go';
import { SiGoogleanalytics } from 'react-icons/si';
import { SlWallet } from 'react-icons/sl';
import { TfiMoreAlt } from 'react-icons/tfi';
import firebase from 'firebase/compat/app';
import { LiaWeightSolid } from 'react-icons/lia';

import { useBlockLayout } from 'react-table/dist/react-table.development';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowDropdown, IoMdPricetags } from 'react-icons/io';
import { IoAddOutline, IoCheckmarkDoneSharp, IoReturnUpBack, IoSearchOutline } from 'react-icons/io5';
import {
  MdConnectingAirports,
  MdEditSquare,
  MdOutlineFlight,
  MdOutlineStickyNote2,
  MdOutlineTrackChanges,
  MdTransitEnterexit,
} from 'react-icons/md';
import { TbPackages } from 'react-icons/tb';
import { LuFolderCheck } from 'react-icons/lu';
import { VscBeakerStop } from 'react-icons/vsc';
import { CiSliderVertical } from 'react-icons/ci';

import OutsideClickHandler from 'react-outside-click-handler';
import DatepickerContext from '../new-booking/components/datepickerContext';
import Month1 from '../new-booking/components/Month1';
import { GiConfirmed } from 'react-icons/gi';
import axios from 'axios';
import ShipmentsGrid from './components/ShipmentsGrid';
import { CircularProgress, LinearProgress, TextareaAutosize } from '@mui/material';
import EditScreen from './components/EditScreen';
import TracingScreen from './components/TracingScreen';
import NoteScreen from './components/NoteScreen';
import FlightScreen from './components/FlightScreen';
import PricingScreen from './components/PricingScreen';
import SurchargeScreen from './components/SurchargeScreen';
import ExportScreen from './components/ExportScreen';
import { db } from '../../../server/firebase';
import ActionScreen from './components/ActionScreen';
import PaymentScreen from './components/PaymentScreen';
import WorkSpace from '../../components/WorkSpace';
import ImportScreen from './components/ImportScreen';
import CreateShipment from '../../components/CreateShipment';
import { BiEdit } from 'react-icons/bi';
import Endpoint from './components/Endpoint';

export default function Shipments({ user }) {
  let newDate = new Date();
  const navigate = useNavigate();
  const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
  let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
  const hour1 = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
  const min1 = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
  const etdHour = +hour1.substring(0, 2) + 2;
  const finalEtd = `${etdHour}:${min1}`;
  const month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
  const year = newDate.getFullYear();
  let firstDayOfCurrentWeek = date - (newDate.getDay() < 10 ? '0' : '');
  let last = firstDayOfCurrentWeek + 6;
  let firstday = firstDayOfCurrentWeek - 6;

  let lastDayOfCurrentWeek = last < 10 ? `0${last}` : last;
  let firstDayOfLastWeek = firstday < 10 ? `0${firstday}` : firstday;
  const first = (firstDay.getDate() < 10 ? '0' : '') + firstDay.getDate();
  let yesterday = date - 1;
  let lastMonth = month - 1;
  let lastDay = new Date(year, lastMonth, 0);
  let firstDayOfYear = new Date(year, 0, 1);
  let lastPrevMonthDay = newDate.setMonth(newDate.getMonth(), 0);
  let prevMonth = lastMonth < 10 ? `0${lastMonth}` : lastMonth;
  const [displayDate, setDisplayDate] = useState(false);
  const [displayEndpoint, setDisplayEndpoint] = useState(false);
  const [displayMissing, setDisplayMissing] = useState(false);
  const [displaySearchShipmentType, setDisplaySearchShipmentType] = useState(false);
  const [displaySelectList, setDisplaySelectList] = useState(false);
  const [displayDeleteConfirm, setDisplayDeleteConfirm] = useState(false);
  const [searchShipmentType, setSearchShipmentType] = useState('HAWB');
  const [searchSelected, setSearchSelected] = useState('');
  const [showMoreSelected, setShowMoreSelected] = useState(false);
  const [searchDemandCharge, setSearchDemandCharge] = useState('');
  const [searchTotal, setSearchTotal] = useState('');
  const [dateStatus, setDateStatus] = useState('Period');
  const [startDate, setStartDate] = useState(`${year}-${month}-${first}`);
  const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10));
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFail, setAlertFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [traceTime, setTraceTime] = useState(`${year}-${month}-${date}`);
  const [shipments, setShipments] = useState([]);
  const [exportStatus, setExportStatus] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE,
  });
  const [statusInput, setStatusInput] = useState('');
  const [searchMultipleShipment, setSearchMultipleShipment] = useState('');
  const [searchCus, setSearchCus] = useState('');
  const [searchPack, setSearchPack] = useState('');
  const [searchClass, setSearchClass] = useState('');
  const [searchCountry, setSearchCountry] = useState('');
  const [searchFrom, setSearchFrom] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchPcs, setSearchPcs] = useState('');
  const [searchAirline, setSearchAirline] = useState('');
  const [searchService, setSearchService] = useState('');
  const [searchAgent, setSearchAgent] = useState('');
  const [searchMawb, setSearchMawb] = useState('');
  const [searchSin, setSearchSin] = useState('');
  const [searchDate, setSearchDate] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchRate, setSearchRate] = useState('');
  const [searchAddress, setSearchAddress] = useState('');
  const [searchVat, setSearchVat] = useState('');
  const [lastUpdateStatus, setLastUpdateStatus] = useState('');
  const [searchRemote, setSearchRemote] = useState('');
  const [searchSurcharge, setSearchSurcharge] = useState('');
  const [searchSurcharge2, setSearchSurcharge2] = useState('');
  const [searchSurcharge3, setSearchSurcharge3] = useState('');
  const [searchFsc, setSearchFsc] = useState('');
  const [searchComestic, setSearchComestic] = useState('');
  const [searchFscValue, setSearchFscValue] = useState('');
  const [searchVatValue, setSearchVatValue] = useState('');
  const [searchSale, setSearchSale] = useState('');
  const [searchPickup, setSearchPickup] = useState('');
  const [searchAcc, setSearchAcc] = useState('');
  const [searchCompany, setSearchCompany] = useState('');
  const [searchHawb, setSearchHawb] = useState('');
  const [findSin, setFindSin] = useState('');
  const [searchNote, setSearchNote] = useState('');
  const [searchSellNote, setSearchSellNote] = useState('');
  const [searchAccNote, setSearchAccNote] = useState('');
  const [searchGnNote, setSearchGnNote] = useState('');
  const [searchEndpoint, setSearchEndpoint] = useState('');
  const [searchExtraSellNote, setSearchExtraSellNote] = useState('');
  const [searchPickupNote, setSearchPickupNote] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [searchExactWeight, setSearchExactWeight] = useState('');
  const [searchExactCharge, setSearchExactCharge] = useState('');
  const [searchWeight, setSearchWeight] = useState('');
  const [sortClass, setSortClass] = useState(false);
  const [sortCustomer, setSortCustomer] = useState(false);
  const [sortHawb, setSortHawb] = useState(false);
  const [sortDate, setSortDate] = useState(false);
  const [sortFrom, setSortFrom] = useState(false);
  const [sortCountry, setSortCountry] = useState(false);
  const [sortStatus, setSortStatus] = useState(false);
  const [sortType, setSortType] = useState(false);
  const [sortWeight, setSortWeight] = useState(false);
  const [sortExactWeight, setSortExactWeight] = useState(false);
  const [includeInfo, setIncludeInfo] = useState(false);
  const [sortChargeable, setSortChargeable] = useState(false);
  const [sortReweight, setSortReweight] = useState(false);
  const [sortPcs, setSortPcs] = useState(false);
  const [sortPack, setSortPack] = useState(false);
  const [sortAirline, setSortAirline] = useState(false);
  const [sortService, setSortService] = useState(false);
  const [sortAgent, setSortAgent] = useState(false);
  const [sortMawb, setSortMawb] = useState(false);
  const [sortSin, setSortSin] = useState(false);
  const [sortRate, setSortRate] = useState(false);
  const [sortComestic, setSortComestic] = useState(false);
  const [sortAddress, setSortAddress] = useState(false);
  const [sortRemote, setSortRemote] = useState(false);
  const [sortSurcharge1, setSortSurcharge1] = useState(false);
  const [sortFsc, setSortFsc] = useState(false);
  const [sortFscValue, setSortFscValue] = useState(false);
  const [sortSurcharge2, setSortSurcharge2] = useState(false);
  const [sortVat, setSortVat] = useState(false);
  const [sortVatValue, setSortVatValue] = useState(false);
  const [sortSurcharge3, setSortSurcharge3] = useState(false);
  const [sortTotal, setSortTotal] = useState(false);
  const [sortLastUpdate, setSortLastUpdate] = useState(false);
  const [sortSale, setSortSale] = useState(false);
  const [sortAcc, setSortAcc] = useState(false);
  const [sortCompany, setSortCompany] = useState(false);
  const [sortNote, setSortNote] = useState(false);
  const [sortSellNote, setSortSellNote] = useState(false);
  const [sortAccNote, setSortAccNote] = useState(false);
  const [sortPickupNote, setSortPickupNote] = useState(false);
  const [sortGnNote, setSortGnNote] = useState(false);
  const [searchReweight, setSearchReweight] = useState('');
  const [searchCode, setSearchCode] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [pageNumberLimit, setPageNumberLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [currentPageInput, setCurrentPageInput] = useState(currentPage);
  const [selectedShipment, setSelectedShipment] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState([]);
  const [flights, setFlights] = useState([]);
  const [findMawb, setFindMawb] = useState('');
  const [displayWorkspace, setDisplayWorkspace] = useState(false);
  const [displayEdit, setDisplayEdit] = useState(false);
  const [displayTracing, setDisplayTracing] = useState(false);

  const [displayNote, setDisplayNote] = useState(false);
  const [displayFlight, setDisplayFlight] = useState(false);
  const [displayPricing, setDisplayPricing] = useState(false);
  const [displaySurcharge, setDisplaySurcharge] = useState(false);
  const [displayAction, setDisplayAction] = useState(false);
  const [displayPayment, setDisplayPayment] = useState(false);
  const [displayExport, setDisplayExport] = useState(false);
  const [displayMore, setDisplayMore] = useState(false);
  const [displayImport, setDisplayImport] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const [displayColumn, setDisplayColumn] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [systemStatus, setSystemStatus] = useState('Very good');
  const [displayCreateShipment, setDisplayCreateShipment] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [surchargeData, setSurchargeData] = useState([]);
  const [shipmentData, setShipmentData] = useState({});
  const [displayShipment, setDisplayShipment] = useState(false);
  const [displayShipmentLabel, setDisplayShipmentLabel] = useState(false);
  const [fileName, setFileName] = useState('');
  const hour = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
  const min = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
  const [dateTime, setDateTime] = useState(`${hour}:${min}`);

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    focusedInput: dateRange.focusedInput,
    onDatesChange: handleDateChange,
  });

  const startDay = new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const endDay = new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const agentHeader = [
    { label: 'ACCOUNT', key: 'account' }, //
    { label: 'MAKH', key: 'makh' },
    { label: 'HAWB', key: 'removeSkd' },
    { label: 'SERVICE', key: 'eamService' },
    { label: 'MAWB', key: 'mawb' },
    { label: 'DATE', key: 'date' },
    { label: 'COMPANY', key: 'company' },
    { label: 'CONTACT', key: 'contact' },
    { label: 'ADDRESS 1', key: 'address' },
    { label: 'ADDRESS 2', key: 'address2' },
    { label: 'ADDRESS 3', key: 'address3' },
    { label: 'Town', key: 'town' },
    { label: 'COUNTRY', key: 'code' },
    { label: 'Post Code', key: 'postal' },
    { label: 'Telephone', key: 'phone' },
    { label: 'Total No Of Packages', key: 'amount' },
    { label: 'Chargeable Weight', key: 'chargeable' },
    { label: 'TYPE', key: 'eamFormat' },
    { label: 'DESCRIP', key: 'description' },
    { label: 'VALUE', key: 'value' },
    { label: 'CURRENCY', key: 'currency' },
    { label: 'Customer', key: 'sender' },
    { label: 'PACK', key: 'pack' },
  ];

  const trackingHeader = [{ label: 'HAWB', key: 'hawb' }];

  const title = [
    { label: 'OVERRIDESITEID', key: 'overidedsiteId' },
    { label: 'ArrivalAirport', key: 'arrivalAirport' },
    { label: 'WaybillOriginator', key: 'waybill' },
    { label: 'AirlinePrefix', key: 'removeMawb' },
    { label: 'AWBSerialNumber', key: 'removeMawb1' },
    { label: 'HouseAWB', key: 'removeSkd' },
    { label: 'MasterAWBIndicator', key: 'master' },
    { label: 'OriginAirport', key: 'originAirport' },
    { label: 'Pieces', key: 'amount' },
    { label: 'WeightCode', key: 'weightCode' },
    { label: 'Weight', key: 'exactWeight' },
    { label: 'Description', key: 'description' },
    { label: 'FDAIndicator', key: 'fdaIndicator' },
    { label: 'ImportingCarrier', key: 'importingCarrier' },
    { label: 'FlightNumber', key: 'mawb' },
    { label: 'ArrivalDay', key: 'arrivalDay' },
    { label: 'ArrivalMonth', key: 'arrivalMonth' },
    { label: 'ShipperName', key: 'sender' },
    { label: 'ShipperStreetAddress', key: 'fromAddress' },
    { label: 'ShipperCity', key: 'shipperCity' },
    { label: 'ShipperStateOrProvince', key: 'fromTown' },
    { label: 'ShipperPostalCode', key: 'fromPostal' },
    { label: 'ShipperCountry', key: 'fromCode' },
    { label: 'ShipperTelephone', key: 'fromPhone' },
    { label: 'ConsigneeName', key: 'company' },
    { label: 'ConsigneeStreetAddress', key: 'address' },
    { label: 'ConsigneeCity', key: 'city' },
    { label: 'ConsigneeStateOrProvince', key: 'state' },
    { label: 'ConsigneePostalCode', key: 'postal' },
    { label: 'ConsigneeCountry', key: 'country' },
    { label: 'ConsigneeTelephone', key: 'phone' },
    { label: 'AmendmentFlag', key: 'amendmentFlag' },
    { label: 'AmendmentCode', key: 'amendmentCode' },
    { label: 'AmendmentReason', key: 'amendmentReason' },
    { label: 'ptpDestination', key: 'ptpDestination' },
    { label: 'ptpDestinationDay', key: 'ptpDestinationDay' },
    { label: 'ptpDestinationMonth', key: 'ptpDestinationMonth' },
    { label: 'CustomsValue', key: 'value' },
    { label: 'CurrencyCode', key: 'currency' },
    { label: 'HTSNumber', key: 'htsNumber' },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: 'Class',
        minWidth: 50,
        width: 90,
        accessor: 'plan',
        key: 'plan',
        active: true,
        id: 2,
        context: false,
      },
      {
        Header: 'Customer & Exporter',
        minWidth: 220,
        width: 280,
        accessor: 'sender',
        key: 'sender',
        active: true,
        id: 3,
        context: false,
      },
      {
        Header: 'Date - Time',
        minWidth: 150,
        accessor: 'dateTime',
        active: true,
        id: 5,
        key: 'date',
        context: false,
      },
      {
        Header: 'From',
        minWidth: 50,
        accessor: 'fromCity',
        active: true,
        id: 6,
        key: 'fromCity',
        context: false,
      },
      {
        Header: 'Destination',
        minWidth: 50,
        accessor: 'country',
        active: true,
        id: 7,
        key: 'country',
        context: false,
      },
      { Header: 'Status', minWidth: 50, accessor: 'status', active: true, id: 8, key: 'status', context: false },
      {
        Header: 'Type',
        minWidth: 50,
        width: 70,
        accessor: 'type',
        active: true,
        id: 9,
        key: 'type',
        context: false,
      },
      {
        Header: 'Booked weight',
        minWidth: 50,
        width: 100,
        accessor: 'chargeable',
        active: true,
        id: 10,
        key: 'chargeable',
        context: false,
      },
      {
        Header: 'Exact Weight',
        minWidth: 50,
        width: 100,
        accessor: 'exactWeight',
        active: true,
        id: 11,
        key: 'exactWeight',
        context: false,
      },
      {
        Header: 'Chargeable',
        minWidth: 50,
        width: 100,
        accessor: 'exactCharge',
        active: true,
        id: 12,
        key: 'exactCharge',
        context: false,
      },
      {
        Header: 'Reweight',
        minWidth: 50,
        width: 100,
        accessor: 'reweight',
        active: true,
        id: 13,
        key: 'reweight',
        context: false,
      },
      {
        Header: 'Pieces',
        minWidth: 50,
        width: 70,
        accessor: 'amountPcs',
        active: true,
        id: 14,
        key: 'amount',
        context: false,
      },
      { Header: 'Packing', minWidth: 50, accessor: 'pack', active: true, id: 15, key: 'pack', context: false },
      {
        Header: 'Airline',
        minWidth: 50,
        accessor: 'airline',
        active: true,
        id: 16,
        key: 'airline',
        context: false,
      },
      {
        Header: 'Service',
        minWidth: 50,
        width: 70,
        accessor: 'service',
        active: true,
        id: 17,
        key: 'service',
        context: false,
      },
      { Header: 'Agent', minWidth: 50, accessor: 'agent', active: true, id: 18, key: 'agent', context: false },
      { Header: 'MAWB', minWidth: 50, accessor: 'mawb', active: true, id: 19, key: 'mawb', context: false },
      { Header: 'Intl AWB', minWidth: 50, accessor: 'sin', active: true, id: 20, key: 'sin', context: false },
      {
        Header: 'Rate',
        minWidth: 50,
        width: 80,
        accessor: 'rate',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 21,
        key: 'rate',
        context: false,
      },
      {
        Header: 'Surcharge',
        minWidth: 50,
        width: 80,
        accessor: 'comestic',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 22,
        key: 'comestic',
        context: false,
      },
      {
        Header: 'Address Correction',
        minWidth: 50,
        width: 80,
        accessor: 'addressCorrection',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 23,
        key: 'addressCorrection',
        context: false,
      },
      {
        Header: 'Remote',
        minWidth: 50,
        width: 80,
        accessor: 'remote',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 24,
        key: 'remote',
        context: false,
      },
      {
        Header: 'Surcharge 1',
        minWidth: 50,
        width: 80,
        accessor: 'surcharge',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 25,
        key: 'surcharge',
        context: false,
      },
      {
        Header: 'FSC (%)',
        minWidth: 50,
        width: 80,
        accessor: 'fsc',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 26,
        key: 'fsc',
        context: false,
      },
      {
        Header: 'FSC Value',
        minWidth: 50,
        width: 80,
        accessor: 'fscValue',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 27,
        key: 'fscValue',
        context: false,
      },
      {
        Header: 'Surcharge 2',
        minWidth: 50,
        width: 80,
        accessor: 'surcharge2',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 28,
        key: 'surcharge2',
        context: false,
      },
      {
        Header: 'VAT (%)',
        minWidth: 50,
        width: 80,
        accessor: 'vat',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 29,
        key: 'vat',
        context: false,
      },
      {
        Header: 'VAT Value',
        minWidth: 50,
        width: 80,
        accessor: 'vatValue',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 30,
        key: 'vatValue',
        context: false,
      },
      {
        Header: 'Surcharge 3',
        minWidth: 50,
        width: 80,
        accessor: 'surcharge3',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 31,
        key: 'surcharge3',
        context: false,
      },
      {
        Header: 'Demand charge',
        minWidth: 50,
        width: 80,
        accessor: 'demandCharge',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 32,
        key: 'demandCharge',
        context: false,
      },
      {
        Header: 'Total Value',
        minWidth: 50,
        width: 80,
        accessor: 'totalValue',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 33,
        key: 'totalValue',
        context: false,
      },
      {
        Header: 'Last Updated',
        minWidth: 50,
        accessor: 'rateUpdate',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 34,
        key: 'rateUpdate',
        context: false,
      },
      {
        Header: 'Sales / Controller',
        minWidth: 50,
        accessor: 'controller',
        active: true,
        id: 35,
        key: 'controller',
        context: false,
      },
      {
        Header: 'ACC',
        minWidth: 50,
        accessor: 'paymentAcc',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 36,
        key: 'paymentAcc',
        context: false,
      },
      {
        Header: 'Company',
        minWidth: 50,
        accessor: 'paymentCompany',
        active: user.role === 'Courier' ? false : user.role === 'OPS' ? false : true,
        id: 37,
        key: 'paymentCompany',
        context: false,
      },
      { Header: 'OPS Note', minWidth: 50, accessor: 'note', active: true, id: 38, key: 'note', context: false },
      {
        Header: 'Selling Note',
        minWidth: 50,
        accessor: 'sellNote',
        active: true,
        id: 39,
        key: 'sellNote',
        context: false,
      },
      {
        Header: 'Acc Note',
        minWidth: 50,
        accessor: 'accNote',
        active: true,
        id: 40,
        key: 'accNote',
        context: false,
      },
      {
        Header: 'Pick up Note',
        minWidth: 50,
        accessor: 'pickupNote',
        active: true,
        id: 41,
        key: 'pickupNote',
        context: false,
      },
      {
        Header: 'GN - Note',
        minWidth: 50,
        accessor: 'gnNote',
        active: true,
        id: 42,
        key: 'gnNote',
        context: false,
      },
      {
        Header: 'Endpoint',
        minWidth: 50,
        accessor: 'endpoint',
        active: true,
        id: 'ending-point-note',
        key: 'endpoint',
        context: false,
      },
      {
        Header: 'Missing Shipment',
        minWidth: 50,
        accessor: 'extraSellNote',
        active: true,
        id: 'extra-selling-note',
        key: 'extraSellNote',
        context: false,
      },
    ],
    []
  );

  useEffect(() => {
    let newDate = new Date();
    const firstDay = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    const first = (firstDay.getDate() < 10 ? '0' : '') + firstDay.getDate();
    const date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
    const month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
    const year = newDate.getFullYear();
    setStartDate(`${year}-${month}-${first}`);
  }, []);
  const d = new Date(dateRange.startDate);
  const selectMonth = (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1);
  const selectDate = (d.getDate() < 10 ? '0' : '') + d.getDate();
  const selectYear = d.getFullYear();

  const e = new Date(dateRange.endDate);
  const selectEndMonth = (e.getMonth() + 1 < 10 ? '0' : '') + (e.getMonth() + 1);
  const selectEndDate = (e.getDate() < 10 ? '0' : '') + e.getDate();
  const selectEndYear = e.getFullYear();
  const currentYear = new Date().getFullYear();
  const handleApplyDate = () => {
    if (!dateRange.endDate) {
      setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setEndDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setDisplayDate(false);
      setDateStatus('Period');
    } else {
      setStartDate(`${selectYear}-${selectMonth}-${selectDate}`);
      setEndDate(`${selectEndYear}-${selectEndMonth}-${selectEndDate}`);
      setDisplayDate(false);
      setDateStatus('Period');
    }
  };
  const handleCancelDate = () => {
    setDisplayDate(false);
  };
  const handleShowDate = () => {
    setDisplayDate(!displayDate);
  };
  const handleCloseDate = () => {
    setDisplayDate(false);
  };
  const handleCloseImport = () => {
    setDisplayImport(false);
  };
  const handleCloseTracing = () => {
    setDisplayTracing(false);
  };
  const handleCloseNote = () => {
    setDisplayNote(false);
  };
  const handleCloseFlight = () => {
    setDisplayFlight(false);
  };
  const handleClosePricing = () => {
    setDisplayPricing(false);
  };
  const handleCloseSurcharge = () => {
    setDisplaySurcharge(false);
  };
  const handleClosePayment = () => {
    setDisplayPayment(false);
  };
  const handleCloseExport = () => {
    setDisplayExport(false);
  };

  function handleDateChange(data) {
    if (!data.focusedInput) {
      setDateRange({ ...data, focusedInput: START_DATE });
    } else {
      setDateRange(data);
    }
  }
  const handleToday = () => {
    setStartDate(`${year}-${month}-${date}`);
    setEndDate(`${year}-${month}-${date}`);
    setDisplayDate(false);
    setDateStatus('Today');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleYesterday = () => {
    if (yesterday === 0) {
      setStartDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
      setEndDate(`${year}-${prevMonth}-${lastDay.getDate()}`);
      setDisplayDate(false);
      setDateStatus('Yesterday');
      setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
    } else {
      setStartDate(`${year}-${month}-${yesterday}`);
      setEndDate(`${year}-${month}-${yesterday}`);
      setDisplayDate(false);
      setDateStatus('Yesterday');
      setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
    }
  };

  const handleThisWeek = () => {
    setStartDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
    setEndDate(`${year}-${month}-${lastDayOfCurrentWeek}`);
    setDisplayDate(false);
    setDateStatus('This week');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleLastWeek = () => {
    setStartDate(`${year}-${month}-${firstDayOfLastWeek}`);
    setEndDate(`${year}-${month}-${firstDayOfCurrentWeek}`);
    setDisplayDate(false);
    setDateStatus('Last week');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };

  const handleThisMonth = () => {
    setStartDate(`${year}-${month}-${first}`);
    setEndDate(new Date(currentYear, new Date().getMonth() + 1).toISOString().split('T')[0]);
    setDisplayDate(false);
    setDateStatus('This month');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleLastMonth = () => {
    setStartDate(`${year}-${prevMonth}-01`);
    setEndDate(`${year}-${prevMonth}-${new Date(lastPrevMonthDay).getDate().toString()}`);
    setDisplayDate(false);
    setDateStatus('Last month');
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };
  const handleThisYear = () => {
    setStartDate(`${year}-01-01`);
    setEndDate(`${year}-12-31`);
    setDateStatus('This year');
    setDisplayDate(false);
    setDateRange({ startDate: null, endDate: null, focusedInput: START_DATE });
  };

  function removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'IÌỈĨÍỊ',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }

  const fetchMultipleSearched = async () => {
    setLoading(true);
    axios
      .get(`https://test.skydart.com.vn/api/getShipment/byArray`, {
        params: {
          searchString: trackArray,
        },
      })
      .then((snapshot) => {
        let data = snapshot.data;
        getCurrentDateTime();
        setShipments(
          data
            .filter((s, i) => {
              return data.map((val) => val.hawb).indexOf(s.hawb) == i;
            })
            .filter((s) => {
              if (user?.role === 'Sales') {
                return s.controller === user?.displayName;
              } else {
                return s;
              }
            })
            .map((s, i) => {
              let fsc = s.fsc;
              let fuelValue =
                ((+s.rate + +s.addressCorrection + +s.remote + +s.surcharge + +s.demandCharge) * +s.fsc) / 100;
              let vatValue =
                ((+s.rate + +s.addressCorrection + +s.remote + +s.surcharge + +s.demandCharge ||
                  0 + s.comestic + +fuelValue + +s.surcharge2) *
                  +s.vat) /
                100;
              let total =
                +s.rate +
                +s.addressCorrection +
                +s.remote +
                +s.comestic +
                +s.surcharge +
                +fuelValue +
                +s.surcharge2 +
                +vatValue +
                +s.surcharge3;
              let value = parseFloat(s.value).toFixed(2) || '';
              let updated = s.updated || 'false';
              let removeSkd = s.hawb.substring(3);
              return {
                no: i + 1,
                select: false,
                contextMenu: false,
                updated: updated,
                account: s.account || '',
                currency: s.currency || '',
                service: s.service === 'VDH' ? 'DHL-VN' : s.service,
                date: s.date || '',
                dateTime: `${new Date(s.date).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })} - ${s.time}`,
                hawb: s.hawb || '',
                removeSkd: removeSkd,
                mawb: s.mawb || 'Pending',
                makh: s.makh || '',
                company: s.company || '',
                contact: s.contact || '',
                type: s.type || '',
                status: s.status || '',
                term: s.term || '',
                time: s.time || '',
                address: s.address || '',
                address2: s.address2 || '',
                address3: s.address3 || '',
                city: s.city || '',
                country: s.country || '',
                phone: s.phone || '',
                postal: s.postal || '',
                state: s.state || '',
                term: s.term,
                town: s.town || '',
                email: s.email || '',
                chargeable: (Math.round(s.chargeable * 100) / 100).toFixed(2).toString() || '',
                exactWeight: (Math.round(s.exactWeight * 100) / 100).toFixed(2).toString() || '',
                exactCharge: (Math.round(s.exactCharge * 100) / 100).toFixed(2).toString() || '',
                pickupNote: s.pickupNote || 'Pending',
                gnNote: s.gnNote || 'Pending',
                accountNote: s.accountNote,
                amount: s.amount || '',
                amountPcs: `${s.amount} pcs`,
                totalItem: s.totalItem || '',
                transport: s.transport || '',
                description: s.description || '',
                controller: s.controller || '',
                eamFormat: s.eamFormat || '',
                value: s.type === 'DOX' ? '0.00' : value,
                airline: s.airline || '',
                reweight: (Math.round(s.reweight * 100) / 100).toFixed(2).toString() || '',
                agent: s.agent || 'Pending',
                etd: s.etd || '',
                subStatus: s.subStatus || '',
                sin: s.sin || 'Pending',
                sender: removeAccents(s.sender) || '',
                note: s.note || '',
                perform: s.perform || '',
                rate: s.rate?.toString() || '',
                fsc: s.fsc || '',
                vat: s.vat.toString() || '',
                remote: s.remote || '0.00',
                comestic: s.comestic || '0.00',
                addressCorrection: s.addressCorrection || '0.00',
                endpoint: s.endpoint || 'Pending',
                extraSellNote: s.extraSellNote || 'Pending',
                totalValue: total.toFixed(2) || '',
                surcharge: s.surcharge || '',
                surcharge2: s.surcharge2 || '',
                surcharge3: s.surcharge3 || '',
                fscValue: fuelValue.toFixed(2).toString() || '',
                vatValue: vatValue.toString() || '',
                sellNote: s.sellNote || '',
                paymentPickup: s.paymentPickup || '',
                paymentAcc: s.paymentAcc || '',
                paymentCompany: s.paymentCompany || '',
                fromEmail: s.fromEmail || '',
                fromCity: s.fromCity || '',
                com: s.com || '',
                labelUrl: s.labelUrl || '',
                rateUpdate:
                  `${new Date(s.rateUpdate).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}` || '',
                paymentStatus: s.paymentStatus || '',
                paymentSubStatus: s.paymentSubStatus || '',
                code: s.code || '',
                eamService: s.eamService || '',
                pack: s.pack || '',
                plan: s.plan || 'Pending',
                accNote: s.accountNote || 'Pending',
                accountNoteBy: s.accountNoteBy || 'Pending',
                pickupBy: s.pickupBy,
                ref: 'Pending',
                overidedsiteId: '8423',
                arrivalAirport: 'JFK',
                weightCode: 'K',
                originAirport: 'SGN',
                shipperCity: 'Ho Chi Minh',
                waybill: 'F709',
                importingCarrier: '',
                gross: s.exactWeight,
                amendmentCode: '21',
                amendmentFlag: 'A',
                period: traceTime,
                demandCharge: s.demandCharge?.toString() || '0.00',
              };
            })
        );
        setLoading(false);
      });
  };

  const refreshShipment = () => {
    setLoading(true);
    axios
      .get(`https://test.skydart.com.vn/api/shipmentttt/${startDate}/${endDate}/**/skd/shipment`)
      .then((snapshot) => {
        let data = snapshot.data;
        getCurrentDateTime();
        setShipments(
          data
            .filter((s, i) => {
              return data.map((val) => val.hawb).indexOf(s.hawb) == i;
            })
            .filter((s) => {
              if (user?.role === 'Sales') {
                return s.controller === user?.displayName;
              } else {
                return s;
              }
            })
            .map((s, i) => {
              let fsc = s.fsc;
              let fuelValue =
                ((+s.rate + +s.addressCorrection + +s.remote + +s.surcharge + +s.demandCharge) * +s.fsc) / 100;
              let vatValue =
                ((+s.rate +
                  +s.addressCorrection +
                  +s.remote +
                  +s.surcharge +
                  +s.demandCharge +
                  +s.comestic +
                  +fuelValue +
                  +s.surcharge2) *
                  +s.vat) /
                100;
              let total =
                +s.rate +
                +s.addressCorrection +
                +s.remote +
                +s.demandCharge +
                +s.comestic +
                +s.surcharge +
                +fuelValue +
                +s.surcharge2 +
                +vatValue +
                +s.surcharge3;
              let value = parseFloat(s.value).toFixed(2) || '';
              let updated = s.updated || 'false';
              let removeSkd = s.hawb.substring(3);
              return {
                no: i + 1,
                select: false,
                contextMenu: false,
                updated: updated,
                account: s.account || '',
                currency: s.currency || '',
                service: s.service === 'VDH' ? 'DHL-VN' : s.service,
                date: s.date || '',
                dateTime: `${new Date(s.date).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })} - ${s.time}`,
                hawb: s.hawb || '',
                removeSkd: removeSkd,
                mawb: s.mawb || 'Pending',
                makh: s.makh || '',
                company: s.company || '',
                contact: s.contact || '',
                type: s.type || '',
                status: s.status || '',
                term: s.term || '',
                time: s.time || '',
                address: s.address || '',
                address2: s.address2 || '',
                address3: s.address3 || '',
                city: s.city || '',
                country: s.country || '',
                phone: s.phone || '',
                postal: s.postal || '',
                state: s.state || '',
                term: s.term,
                town: s.town || '',
                email: s.email || '',
                chargeable: (Math.round(s.chargeable * 100) / 100).toFixed(2).toString() || '',
                exactWeight: (Math.round(s.exactWeight * 100) / 100).toFixed(2).toString() || '',
                exactCharge: (Math.round(s.exactCharge * 100) / 100).toFixed(2).toString() || '',
                pickupNote: s.pickupNote || 'Pending',
                gnNote: s.gnNote || 'Pending',
                accountNote: s.accountNote,
                amount: s.amount || '',
                amountPcs: `${s.amount} pcs`,
                totalItem: s.totalItem || '',
                transport: s.transport || '',
                description: s.description || '',
                controller: s.controller || '',
                eamFormat: s.eamFormat || '',
                value: s.type === 'DOX' ? '0.00' : value,
                airline: s.airline || '',
                reweight: (Math.round(s.reweight * 100) / 100).toFixed(2).toString() || '',
                agent: s.agent || 'Pending',
                etd: s.etd || '',
                endpoint: s.endpoint || 'Pending',
                extraSellNote: s.extraSellNote || 'Pending',
                subStatus: s.subStatus || '',
                sin: s.sin || 'Pending',
                sender: removeAccents(s.sender) || '',
                note: s.note || '',
                perform: s.perform || '',
                rate: s.rate?.toString() || '',
                fsc: s.fsc || '',
                vat: s.vat.toString() || '',
                remote: s.remote || '0.00',
                comestic: s.comestic || '0.00',
                addressCorrection: s.addressCorrection || '0.00',
                totalValue: total.toFixed(2) || '',
                surcharge: s.surcharge || '',
                surcharge2: s.surcharge2 || '',
                surcharge3: s.surcharge3 || '',
                fscValue: fuelValue.toFixed(2).toString() || '',
                vatValue: vatValue.toString() || '',
                sellNote: s.sellNote || '',
                paymentPickup: s.paymentPickup || '',
                paymentAcc: s.paymentAcc || '',
                paymentCompany: s.paymentCompany || '',
                fromEmail: s.fromEmail || '',
                fromCity: s.fromCity || '',
                com: s.com || '',
                labelUrl: s.labelUrl || '',
                rateUpdate:
                  `${new Date(s.rateUpdate).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}` || '',
                paymentStatus: s.paymentStatus || '',
                paymentSubStatus: s.paymentSubStatus || '',
                code: s.code || '',
                eamService: s.eamService || '',
                pack: s.pack || '',
                plan: s.plan || 'Pending',
                accNote: s.accountNote || 'Pending',
                accountNoteBy: s.accountNoteBy || 'Pending',
                pickupBy: s.pickupBy,
                ref: 'Pending',
                overidedsiteId: '8423',
                arrivalAirport: 'JFK',
                weightCode: 'K',
                originAirport: 'SGN',
                shipperCity: 'Ho Chi Minh',
                waybill: 'F709',
                importingCarrier: '',
                gross: s.exactWeight,
                amendmentCode: '21',
                amendmentFlag: 'A',
                period: traceTime,
                demandCharge: s.demandCharge?.toString() || '0.00',
              };
            })
        );
        setLoading(false);
      });
  };
  const handleShowShipment = (shipmentValue) => {
    setShipmentData(shipmentValue);
    setDisplayShipment(!displayShipment);
  };

  const handleShowShipmentLabel = (shipmentValue) => {
    setShipmentData(shipmentValue);
    setDisplayShipmentLabel(!displayShipmentLabel);
  };

  const handleCloseShipmentLabel = () => {
    setShipmentData({});
    setDisplayShipmentLabel(false);
  };
  const handleCloseShipmentEdit = () => {
    setShipmentData({});
    setDisplayShipment(false);
  };

  const handleSelectedAllShipment1 = (propsValue) => {
    if (propsValue === 'all') {
      setSelectedShipment(
        getWeight.map((sd) => {
          sd.select = !sd.select;
        })
      );
    } else if (propsValue === 'wpx') {
      setSelectedShipment(
        getWeight
          .filter((s) => {
            return s.type === 'WPX';
          })
          .map((sd) => {
            sd.select = !sd.select;
            return sd;
          })
      );
    } else if (propsValue === 'dox') {
      setSelectedShipment(
        getWeight
          .filter((s) => {
            return s.type === 'DOX';
          })
          .map((sd) => {
            sd.select = !sd.select;
            return sd;
          })
      );
    } else if (propsValue === 'currentPage') {
      setSelectedShipment(
        currentShipment.map((sd) => {
          sd.select = !sd.select;
        })
      );
    }
  };
  const handleSelectedHeader = (headerGroupObject, headerId, headerActive) => {
    setSelectedHeader(
      headerGroupObject?.headers?.map((sd) => {
        if (sd.id === headerId) {
          if (headerActive) {
            sd.active = false;
          } else {
            sd.active = true;
          }
        }
        return sd;
      })
    );
  };
  const handleSelectedAllHeader = () => {
    setSelectedHeader(
      headerGroups[0]?.headers?.map((sd) => {
        sd.active = true;
      })
    );
  };
  const handleSelectedShipment = (data, rowId, checkValue) => {
    setSelectedShipment(
      data?.map((sd) => {
        if (rowId === sd.id) {
          sd.original.select = checkValue;
        }
        return sd;
      })
    );
  };

  const handleSelectedCustomizeShipmentRemove = () => {
    setSelectedShipment(
      shipments.map((sd) => {
        sd.select = false;
        return sd;
      })
    );
  };
  const handleSelectedCustomizeShipment = (
    shipmentType,
    shipmentCountry,
    shipmentFromWeight,
    shipmentToWeight,
    columnArray,
    propsValue
  ) => {
    let selectedShipmentCopy = [...selectedShipment]; // Create a copy of the selectedShipment state

    const getShipmentByTypeAndCountry = shipments.filter((sd) => {
      return (
        +sd.chargeable >= shipmentFromWeight &&
        +sd.chargeable <= shipmentToWeight &&
        sd.type.toLowerCase().includes(shipmentType.toLowerCase()) &&
        sd.country.toLowerCase().startsWith(shipmentCountry.toLowerCase())
      );
    });
    if (propsValue === 'Weight') {
      setSelectedShipment(
        getShipmentByTypeAndCountry.map((el) => {
          el.select = !el.select;
          return el;
        })
      );
    } else if (propsValue === 'Columns') {
      const getShipmentByColumn = columnArray?.map((item) => {
        if (item.headerValue === 'Class') {
          const findData = shipments.filter((element) => {
            return element.plan === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Customer & Exporter') {
          const findData = shipments.filter((element) => {
            return element.sender === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'HAWB') {
          const findData = shipments.filter((element) => {
            return element.hawb === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Date - Time') {
          const findData = shipments.filter((element) => {
            return element.date === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'From') {
          const findData = shipments.filter((element) => {
            return element.fromCity === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Destination') {
          const findData = shipments.filter((element) => {
            return element.country === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Status') {
          const findData = shipments.filter((element) => {
            return element.status === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Type') {
          const findData = shipments.filter((element) => {
            return element.type === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Booked weight') {
          const findData = shipments.filter((element) => {
            return element.chargeable === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Exact weight') {
          const findData = shipments.filter((element) => {
            return element.exactWeight === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Chargeable') {
          const findData = shipments.filter((element) => {
            return element.exactCharge === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Reweight') {
          const findData = shipments.filter((element) => {
            return element.reweight === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Pieces') {
          const findData = shipments.filter((element) => {
            return element.amount === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Packing') {
          const findData = shipments.filter((element) => {
            return element.packing === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Airline') {
          const findData = shipments.filter((element) => {
            return element.airline === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Service') {
          const findData = shipments.filter((element) => {
            return element.service === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Agent') {
          const findData = shipments.filter((element) => {
            return element.agent === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'MAWB') {
          const findData = shipments.filter((element) => {
            return element.mawb === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Intl AWB') {
          const findData = shipments.filter((element) => {
            return element.sin === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Rate') {
          const findData = shipments.filter((element) => {
            return element.rate === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Surcharge') {
          const findData = shipments.filter((element) => {
            return element.comestic === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Address Correction') {
          const findData = shipments.filter((element) => {
            return element.addressCorrection === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Remote') {
          const findData = shipments.filter((element) => {
            return element.remote === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Surcharge 1') {
          const findData = shipments.filter((element) => {
            return element.surcharge === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'FSC (%)') {
          const findData = shipments.filter((element) => {
            return element.fsc === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'FSC Value') {
          const findData = shipments.filter((element) => {
            return element.fscValue === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Surcharge 2') {
          const findData = shipments.filter((element) => {
            return element.surcharge2 === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'VAT (%)') {
          const findData = shipments.filter((element) => {
            return element.vat === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'VAT Value') {
          const findData = shipments.filter((element) => {
            return element.vatValue === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Surcharge 3') {
          const findData = shipments.filter((element) => {
            return element.surcharge3 === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Total Value') {
          const findData = shipments.filter((element) => {
            return element.totalValue === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Last Updated') {
          const findData = shipments.filter((element) => {
            return element.rateUpdate === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Sales / Controller') {
          const findData = shipments.filter((element) => {
            return element.controller === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'ACC') {
          const findData = shipments.filter((element) => {
            return element.paymentAcc === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Company') {
          const findData = shipments.filter((element) => {
            return element.paymentCompany === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'OPS Note') {
          const findData = shipments.filter((element) => {
            return element.note === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Selling Note') {
          const findData = shipments.filter((element) => {
            return element.sellNote === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Acc Note') {
          const findData = shipments.filter((element) => {
            return element.accNote === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Pick up Note') {
          const findData = shipments.filter((element) => {
            return element.pickupNote === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'GN - Note') {
          const findData = shipments.filter((element) => {
            return element.gnNote === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        }
        return item;
      });

      let finalArray = getShipmentByColumn.flat(); // Flatten the array of arrays into a single array

      finalArray.forEach((element) => {
        element.select = true; // Set select to true for all elements in finalArray
      });

      // Set select to false for all elements not present in finalArray
      selectedShipmentCopy.forEach((item) => {
        if (!finalArray.find((element) => element.id === item.id)) {
          item.select = false;
        }
      });

      setSelectedShipment(selectedShipmentCopy); // Set the updated selectedShipment state
    } else if (propsValue === 'All') {
      const getShipmentByColumn = columnArray?.map((item) => {
        if (item.headerValue === 'Class') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.plan === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Customer & Exporter') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.sender === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'HAWB') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.hawb === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Date - Time') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.date === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'From') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.fromCity === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Destination') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.country === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Status') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.status === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Type') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.type === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Booked weight') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.chargeable === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Exact weight') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.exactWeight === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Chargeable') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.exactCharge === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Reweight') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.reweight === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Pieces') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.amount === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Packing') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.packing === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Airline') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.airline === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Service') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.service === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Agent') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.agent === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'MAWB') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.mawb === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Intl AWB') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.sin === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Rate') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.rate === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Surcharge') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.comestic === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Address Correction') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.addressCorrection === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Remote') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.remote === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Surcharge 1') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.surcharge === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'FSC (%)') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.fsc === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'FSC Value') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.fscValue === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Surcharge 2') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.surcharge2 === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'VAT (%)') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.vat === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'VAT Value') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.vatValue === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Surcharge 3') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.surcharge3 === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Total Value') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.totalValue === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Last Updated') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.rateUpdate === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Sales / Controller') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.controller === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'ACC') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.paymentAcc === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Company') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.paymentCompany === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'OPS Note') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.note === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Selling Note') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.sellNote === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Acc Note') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.accNote === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'Pick up Note') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.pickupNote === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        } else if (item.headerValue === 'GN - Note') {
          const findData = getShipmentByTypeAndCountry.filter((element) => {
            return element.gnNote === item.wrapValue;
          });

          return findData; // Return the findData directly without pushing to arr
        }
        return item;
      });

      let finalArray = getShipmentByColumn.flat(); // Flatten the array of arrays into a single array

      finalArray.forEach((element) => {
        element.select = true; // Set select to true for all elements in finalArray
      });

      // Set select to false for all elements not present in finalArray
      selectedShipmentCopy.forEach((item) => {
        if (!finalArray.find((element) => element.id === item.id)) {
          item.select = false;
        }
      });

      setSelectedShipment(selectedShipmentCopy); // Set the updated selectedShipment state
    }
  };

  const pickedupShipment = shipments.filter((s, i) => {
    return s.status === 'Picked Up';
  });
  const arrivedShipment = shipments.filter((s, i) => {
    return s.status === 'Arrived At Skydart Origin Depot';
  });
  const processShipment = shipments.filter((s) => {
    return s.status === 'In Process';
  });
  const holdShipment = shipments.filter((s) => {
    return s.status === 'On Hold';
  });
  const clearanceShipment = shipments.filter((s) => {
    return s.status === 'In Clearance';
  });
  const clearedShipment = shipments.filter((s) => {
    return s.status === 'Cleared For Export';
  });
  const transitShipment = shipments.filter((s) => {
    return s.status === 'In Transit';
  });
  const deliveredShipment = shipments.filter((s) => {
    return s.status === 'Delivered';
  });
  const findExactDate = shipments.filter((s) => {
    if (searchDate !== '') {
      return s.date === searchDate;
    } else {
      return s;
    }
  });

  const finalShipment = findExactDate.filter((s, i) => {
    let plan = s.plan || '';
    let rate = s.rate;
    let addressCorrect = s.addressCorrection;
    let remote = s.remote;
    let surcharge = s.surcharge;
    let fsc = s.fsc;
    let fscValue = s.fscValue;
    let surcharge2 = s.surcharge2;
    let vat = s.vat;
    let vatValue = s.vatValue;
    let accountNote = s.accountNote || '';
    let pickupNote = s.pickupNote || '';
    let gnNote = s.gnNote || '';
    let surcharge3 = s.surcharge3;
    let total = s.totalValue;
    return (
      s.sender.toLowerCase().includes(searchCus.toLowerCase()) &&
      s.pack.toLowerCase().includes(searchPack.toLowerCase()) &&
      plan.toLowerCase().includes(searchClass.toLowerCase()) &&
      s.status.toLowerCase().includes(searchStatus.toLowerCase()) &&
      s.country.toLowerCase().startsWith(searchCountry.toLowerCase()) &&
      s.fromCity.toLowerCase().startsWith(searchFrom.toLowerCase()) &&
      s.type.toLowerCase().includes(searchType.toLowerCase()) &&
      s.code.toLowerCase().startsWith(searchCode.toLowerCase()) &&
      s.amount.toLowerCase().includes(searchPcs.toLowerCase()) &&
      s.airline.toLowerCase().includes(searchAirline.toLowerCase()) &&
      s.service.toLowerCase().includes(searchService.toLowerCase()) &&
      s.agent.toLowerCase().includes(searchAgent.toLowerCase()) &&
      s.mawb.toLowerCase().includes(searchMawb.toLowerCase()) &&
      s.sin.toLowerCase().includes(searchSin.toLowerCase()) &&
      s.controller.toLowerCase().includes(searchSale.toLowerCase()) &&
      s.paymentPickup.toLowerCase().endsWith(searchPickup.toLowerCase()) &&
      s.paymentAcc.toLowerCase().endsWith(searchAcc.toLowerCase()) &&
      s.paymentCompany.toLowerCase().endsWith(searchCompany.toLowerCase()) &&
      s.status.toLowerCase().includes(statusInput.toLowerCase()) &&
      s.hawb.toLowerCase().includes(searchHawb.toLowerCase()) &&
      s.sin.toLowerCase().includes(findSin.toLowerCase()) &&
      s.mawb.toLowerCase().includes(findMawb.toLowerCase()) &&
      s.note.toLowerCase().includes(searchNote.toLowerCase()) &&
      s.sellNote.toLowerCase().includes(searchSellNote.toLowerCase()) &&
      accountNote.toLowerCase().includes(searchAccNote.toLowerCase()) &&
      gnNote.toLowerCase().includes(searchGnNote.toLowerCase()) &&
      pickupNote.toLowerCase().includes(searchPickupNote.toLowerCase()) &&
      s.fromCity.toLowerCase().includes(searchCity.toLowerCase()) &&
      s.exactWeight.toLowerCase().startsWith(searchExactWeight.toLowerCase()) &&
      s.exactCharge.toLowerCase().startsWith(searchExactCharge.toLowerCase()) &&
      s.endpoint.toLowerCase().includes(searchEndpoint.toLowerCase()) &&
      s.extraSellNote.toLowerCase().includes(searchExtraSellNote.toLowerCase())
    );
  });
  const statusArray = [
    {
      label: 'All',
      value: '',
      icon: <TbPackages size={23} className="mr-1.5" />,
      id: 0,
      shipmentLength: shipments.length,
    },
    {
      label: 'Picked up',
      value: 'Picked Up',
      icon: <FaTruckPickup size={23} className="mr-1.5" />,
      id: 1,
      shipmentLength: pickedupShipment.length,
    },
    {
      label: 'Arrived',
      value: 'Arrived',
      icon: <MdConnectingAirports size={23} className="mr-1.5" />,
      id: 2,
      shipmentLength: arrivedShipment.length,
    },
    {
      label: 'In process',
      value: 'In Process',
      icon: <LuFolderCheck size={23} className="mr-1.5" />,
      id: 3,
      shipmentLength: processShipment.length,
    },
    {
      label: 'On hold',
      value: 'On Hold',
      icon: <VscBeakerStop size={23} className="mr-1.5" />,
      id: 4,
      shipmentLength: holdShipment.length,
    },
    {
      label: 'Clearance',
      value: 'In Clearance',
      icon: <CiSliderVertical size={23} className="mr-1.5" />,
      id: 5,
      shipmentLength: clearanceShipment.length,
    },
    {
      label: 'Cleared',
      value: 'Cleared For Export',
      icon: <GiConfirmed size={23} className="mr-1.5" />,
      id: 6,
      shipmentLength: clearedShipment.length,
    },
    {
      label: 'In transit',
      value: 'In Transit',
      icon: <MdTransitEnterexit size={23} className="mr-1.5" />,
      id: 7,
      shipmentLength: transitShipment.length,
    },
    {
      label: 'Delivered',
      value: 'Delivered',
      icon: <IoCheckmarkDoneSharp size={23} className="mr-1.5" />,
      id: 8,
      shipmentLength: deliveredShipment.length,
    },
  ];
  const getClass = finalShipment.filter((s) => {
    if (searchClass !== '') {
      return s.plan === searchClass;
    } else {
      return s;
    }
  });
  const getTrueWeight = getClass.filter((s) => {
    if (searchWeight !== '') {
      return s.chargeable === searchWeight;
    } else {
      return s;
    }
  });
  const getTrueExactWeight = getTrueWeight.filter((s) => {
    if (searchExactWeight !== '') {
      return s.exactWeight === searchExactWeight;
    } else {
      return s;
    }
  });
  const getTrueExactCharge = getTrueExactWeight.filter((s) => {
    if (searchExactCharge !== '') {
      return s.exactCharge === searchExactCharge;
    } else {
      return s;
    }
  });
  const sortedShipment = getTrueExactCharge.sort((x, y) => {
    if (sortClass) {
      return x.plan.localeCompare(y.plan);
    } else if (sortCustomer) {
      return x.sender.localeCompare(y.sender);
    } else if (sortHawb) {
      return x.hawb.localeCompare(y.hawb);
    } else if (sortDate) {
      return x.date.localeCompare(y.date) || x.time.localeCompare(y.time);
    } else if (sortFrom) {
      return x.fromCity.localeCompare(y.fromCity);
    } else if (sortCountry) {
      return x.country.localeCompare(y.country);
    } else if (sortStatus) {
      return x.status.localeCompare(y.status);
    } else if (sortType) {
      return x.type.localeCompare(y.type);
    } else if (sortWeight) {
      return x.chargeable.localeCompare(y.chargeable);
    } else if (sortExactWeight) {
      return x.exactWeight.localeCompare(y.exactWeight);
    } else if (sortChargeable) {
      return x.exactCharge.localeCompare(y.exactCharge);
    } else if (sortReweight) {
      return x.reweight.localeCompare(y.reweight);
    } else if (sortPcs) {
      return x.amount.localeCompare(y.amount);
    } else if (sortPack) {
      return x.pack.localeCompare(y.pack);
    } else if (sortAirline) {
      return x.airline.localeCompare(y.airline);
    } else if (sortService) {
      return x.service.localeCompare(y.service);
    } else if (sortAgent) {
      return x.agent.localeCompare(y.agent);
    } else if (sortMawb) {
      return x.mawb.localeCompare(y.mawb);
    } else if (sortSin) {
      return x.sin.localeCompare(y.sin);
    } else if (sortRate) {
      return x.rate.localeCompare(y.rate);
    } else if (sortComestic) {
      return x.comestic.localeCompare(y.comestic);
    } else if (sortAddress) {
      return x.addressCorrection.localeCompare(y.addressCorrection);
    } else if (sortRemote) {
      return x.remote.localeCompare(y.remote);
    } else if (sortSurcharge1) {
      return x.surcharge.localeCompare(y.surcharge);
    } else if (sortFsc) {
      return x.fsc.localeCompare(y.fsc);
    } else if (sortFscValue) {
      return x.fscValue.localeCompare(y.fscValue);
    } else if (sortSurcharge2) {
      return x.surcharge2.localeCompare(y.surcharge2);
    } else if (sortVat) {
      return x.vat.localeCompare(y.vat);
    } else if (sortVatValue) {
      return x.vatValue.localeCompare(y.vatValue);
    } else if (sortSurcharge3) {
      return x.surcharge3.localeCompare(y.surcharge3);
    } else if (sortTotal) {
      return x.totalValue.localeCompare(y.totalValue);
    } else if (sortLastUpdate) {
      return x.rateUpdate.localeCompare(y.rateUpdate);
    } else if (sortSale) {
      return x.controller.localeCompare(y.controller);
    } else if (sortAcc) {
      return x.paymentAcc.localeCompare(y.paymentAcc);
    } else if (sortCompany) {
      return x.paymentCompany.localeCompare(y.paymentCompany);
    } else if (sortNote) {
      return x.note.localeCompare(y.note);
    } else if (sortSellNote) {
      return x.sellNote.localeCompare(y.sellNote);
    } else if (sortAccNote) {
      return x.accNote.localeCompare(y.accNote);
    } else if (sortPickupNote) {
      return x.pickupNote.localeCompare(y.pickupNote);
    } else if (sortGnNote) {
      return x.gnNote.localeCompare(y.gnNote);
    }
  });

  const getRate = sortedShipment.filter((s) => {
    if (searchRate !== '') {
      return s.rate === searchRate;
    } else {
      return s;
    }
  });
  const getRemote = getRate.filter((s) => {
    if (searchRemote !== '') {
      return s.remote === searchRemote;
    } else {
      return s;
    }
  });
  const getAddressCorrection = getRemote.filter((s) => {
    if (searchAddress !== '') {
      return s.addressCorrectoin === searchAddress;
    } else {
      return s;
    }
  });

  const getComestic = getAddressCorrection.filter((s) => {
    if (searchComestic !== '') {
      return s.comestic === searchComestic;
    } else {
      return s;
    }
  });
  const getSurcharge = getComestic.filter((s) => {
    if (searchSurcharge !== '') {
      return s.surcharge === searchSurcharge;
    } else {
      return s;
    }
  });
  const getFsc = getSurcharge.filter((s) => {
    if (searchFsc !== '') {
      return s.fsc === searchFsc;
    } else {
      return s;
    }
  });
  const getFscValue = getFsc.filter((s) => {
    if (searchFscValue !== '') {
      return s.fscValue === searchFscValue;
    } else {
      return s;
    }
  });
  const getSurcharge2 = getFscValue.filter((s) => {
    if (searchSurcharge2 !== '') {
      return s.surcharge2 === searchSurcharge2;
    } else {
      return s;
    }
  });
  const getVat = getSurcharge2.filter((s) => {
    if (searchVat !== '') {
      return s.vat === searchVat;
    } else {
      return s;
    }
  });
  const getVatValue = getVat.filter((s) => {
    if (searchVatValue !== '') {
      return s.vatValue === searchVatValue;
    } else {
      return s;
    }
  });
  const getSurcharge3 = getVatValue.filter((s) => {
    if (searchSurcharge3 !== '') {
      return s.surcharge3 === searchSurcharge3;
    } else {
      return s;
    }
  });
  const getTotal = getSurcharge3.filter((s) => {
    if (searchTotal !== '') {
      return s.totalValue === searchTotal;
    } else {
      return s;
    }
  });
  const getDemandCharge = getTotal.filter((s) => {
    if (searchDemandCharge !== '') {
      return s.demandCharge === searchDemandCharge;
    } else {
      return s;
    }
  });
  const getWeight = getDemandCharge.filter((s) => {
    if (searchReweight !== '') {
      return s.reweight === searchReweight;
    } else {
      return s;
    }
  });
  /// Select multiple shipment
  const handleSelectedAllShipment = (e) => {
    setSelectedShipment(
      getWeight.map((sd) => {
        sd.select = e.target.checked;
      })
    );
  };
  const pages = [];
  for (let i = 1; i <= Math.ceil(getWeight.length / itemPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentShipment = getWeight.slice(indexOfFirstItem, indexOfLastItem);
  const handleChangePage = (e) => {
    setCurrentPage(+e.target.id);
  };
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageLimit + 1 && number > minPageLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleChangePage}
          className={currentPage == number ? 'active-page' : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });
  const data = React.useMemo(() => currentShipment);
  const { headerGroups, rows, prepareRow, state, getTableProps, getTableBodyProps, setColumnOrder } = useTable(
    { columns, data },
    useResizeColumns,
    useFlexLayout,
    useBlockLayout,
    useColumnOrder
  );
  const isColumnResizing = !!state.columnResizing.isResizingColumn;
  const selected = shipments
    .sort((a, b) => {
      return b.dateTime - a.dateTime;
    })
    .filter((s) => {
      return s.select;
    });
  const exportSelected = selected
    .sort((a, b) => {
      if (includeInfo) {
        a = a.sender || '';
        b = b.sender || '';
        return a.localeCompare(b);
      }
    })
    .map((s, index) => {
      return {
        no: s.no,
        loading: s.loading,
        plan: s.plan,
        makh: s.makh,
        overidedsiteId: '8423',
        arrivalAirport: s.arrivalAirport,
        waybill: s.waybill,
        removeMawb: s.removeMawb,
        removeMawb1: s.removeMawb1,
        master: s.master,
        originAirpot: s.originAirpot,
        amount: s.amount,
        weightCode: s.weightCode,
        fdaIndicator: s.fdaIndicator,
        importingCarrier: s.importingCarrier,
        arrivalDay: s.arrivalDay,
        arrivalMonth: s.arrivalMonth,
        fromAddress: s.fromAddress,
        shipperCity: s.fromCity,
        sender: s.sender,
        hawb: s.hawb,
        removeSkd: s.removeSkd,
        date: s.date,
        fromCity: s.fromCity,
        country: s.country,
        city: s.city,
        state: s.state,
        postal: s.postal,
        phone: s.phone,
        amendmentCode: s.amendmentCode,
        amendmentFlag: s.amendmentFlag,
        amendentReason: s.amendentReason,
        ptpDestination: s.ptpDestination,
        ptpDestinationDay: s.ptptDestinationDay,
        ptpDestinationMonth: s.ptptDestinationMonth,
        status: s.status,
        address: s.address,
        address2: s.address2,
        address3: s.address3,
        town: s.town,
        type: s.type,
        chargeable: s.chargeable,
        exactWeight: s.exactWeight,
        exactCharge: s.exactCharge,
        reweight: s.reweight,
        amount: s.amount,
        pack: s.pack,
        airline: s.airline,
        service: s.service,
        agent: s.agent,
        mawb: s.mawb,
        sin: s.sin,
        rate: s.rate,
        comestic: s.comestic,
        addressCorrection: s.addressCorrection,
        remote: s.remote,
        surcharge: s.surcharge,
        fsc: s.fsc,
        fscValue: s.fscValue,
        surcharge2: s.surcharge2,
        vat: s.vat,
        vatValue: s.vatValue,
        surcharge3: s.surcharge3,
        totalValue: s.totalValue,
        rateUpdate: s.rateUpdate,
        controller: s.controller,
        paymentAcc: s.paymentAcc,
        paymentCompany: s.paymentCompany,
        note: s.note,
        sellNote: s.sellNote,
        accNote: s.accNote,
        pickupNote: s.pickupNote,
        gnNote: s.gnNote,
        account: s.account,
        eamService: s.eamService,
        company: s.company,
        contact: s.contact,
        code: s.code,
        postal: s.postal,
        phone: s.phone,
        eamFormat: s.eamFormat,
        description: s.description,
        value: s.value,
        currency: s.currency,
        htsNumber: s.htsNumber,
        demandCharge: s.demandCharge,
      };
    });
  useEffect(() => {
    refreshShipment();
  }, [startDate, endDate]);
  const handleChangeCode = (e) => {
    setSearchCode(e.target.value);
  };
  const handleChangeClass = (e) => {
    setSearchClass(e.target.value);
  };
  const dus = getWeight.filter((s) => {
    return s.service === 'DUS';
  });
  const dhl = getWeight.filter((s) => {
    return s.service === 'DHL';
  });
  const dhlVn = getWeight.filter((s) => {
    return s.service === 'DHL-VN';
  });
  const fedexVn = getWeight.filter((s) => {
    return s.service === 'Fedex-VN';
  });
  const fip = getWeight.filter((s) => {
    return s.service === 'FIP';
  });
  const f3p = getWeight.filter((s) => {
    return s.service === 'F3P';
  });
  const fie = getWeight.filter((s) => {
    return s.service === 'FIE';
  });

  const duplicateComestic = [...new Set(getWeight.map((s) => s.comestic))];
  const duplicateSender = [...new Set(getWeight.map((s) => s.sender))];
  const duplicateWeight = [...new Set(getWeight.map((s) => s.chargeable))];
  const duplicateService = [...new Set(getWeight.map((s) => s.service))];
  const duplicateCode = [...new Set(getWeight.map((s) => s.code))];
  const duplicateExactWeight = [...new Set(getWeight.map((s) => s.exactWeight))];
  const duplicateChargeable = [...new Set(getWeight.map((s) => s.exactCharge))];
  const duplicateReweight = [...new Set(getWeight.map((s) => s.reweight))];
  const duplicateAirline = [...new Set(getWeight.map((s) => s.airline))];
  const duplicateAgent = [...new Set(getWeight.map((s) => s.agent))];
  const duplicatePack = [...new Set(getWeight.map((s) => s.pack))];
  const duplicateRate = [...new Set(getWeight.map((s) => s.rate))];
  const duplicateAddress = [...new Set(getWeight.map((s) => s.addressCorrection))];
  const duplicateRemote = [...new Set(getWeight.map((s) => s.remote))];
  const duplicateExtra1 = [...new Set(getWeight.map((s) => s.surcharge))];
  const duplicateExtra2 = [...new Set(getWeight.map((s) => s.surcharge2))];
  const duplicateExtra3 = [...new Set(getWeight.map((s) => s.surcharge3))];
  const duplicateFsc = [...new Set(getWeight.map((s) => s.fsc))];
  const duplicateFscValue = [...new Set(getWeight.map((s) => s.fscValue))];
  const duplicateVat = [...new Set(getWeight.map((s) => s.vat))];
  const duplicateVatValue = [...new Set(getWeight.map((s) => s.vatValue))];
  const duplicateSale = [...new Set(getWeight.map((s) => s.controller))];
  const duplicateClass = [...new Set(getWeight.map((s) => s.plan))];
  const duplicateTotal = [...new Set(getWeight.map((s) => s.totalValue))];
  const duplicateDemandCharge = [...new Set(getWeight.map((s) => s.demandCharge))];
  const duplicatePaymentAccountant = [...new Set(getWeight.map((s) => s.paymentAcc))];
  const duplicatePaymentCompany = [...new Set(getWeight.map((s) => s.paymentCompany))];
  const duplicateNote = [...new Set(getWeight.map((s) => s.note))];
  const duplicateSellNote = [...new Set(getWeight.map((s) => s.sellNote))];
  const duplicateAccNote = [...new Set(getWeight.map((s) => s.accNote))];
  const duplicatePickupNote = [...new Set(getWeight.map((s) => s.pickupNote))];
  const duplicateGnNote = [...new Set(getWeight.map((s) => s.gnNote))];

  const handleResetSearchColumn = (index) => {
    if (index === 0) {
      setSearchClass('');
    } else if (index === 1) {
      setSearchCus('');
    } else if (index === 2) {
      setSearchDate('');
    } else if (index === 3) {
      setSearchFrom('');
    } else if (index === 4) {
      setSearchCountry('');
    } else if (index === 5) {
      setSearchStatus('');
    } else if (index === 6) {
      setSearchType('');
    } else if (index === 7) {
      setSearchWeight('');
    } else if (index === 8) {
      setSearchExactWeight('');
    } else if (index === 9) {
      setSearchExactCharge('');
    } else if (index === 10) {
      setSearchReweight('');
    } else if (index === 11) {
      setSearchPcs('');
    } else if (index === 12) {
      setSearchPack('');
    } else if (index === 13) {
      setSearchAirline('');
    } else if (index === 14) {
      setSearchService('');
    } else if (index === 15) {
      setSearchAgent('');
    } else if (index === 16) {
      setSearchMawb('');
    } else if (index === 17) {
      setSearchSin('');
    } else if (index === 18) {
      setSearchRate('');
    } else if (index === 19) {
      setSearchComestic('');
    } else if (index === 20) {
      setSearchAddress('');
    } else if (index === 21) {
      setSearchRemote('');
    } else if (index === 22) {
      setSearchSurcharge('');
    } else if (index === 23) {
      setSearchFsc('');
    } else if (index === 24) {
      setSearchFscValue('');
    } else if (index === 25) {
      setSearchSurcharge2('');
    } else if (index === 26) {
      setSearchVat('');
    } else if (index === 27) {
      setSearchVatValue('');
    } else if (index === 28) {
      setSearchSurcharge3('');
    } else if (index === 29) {
      setSearchDemandCharge('');
    } else if (index === 30) {
      setSearchTotal('');
    } else if (index === 32) {
      setSearchSale('');
    } else if (index === 33) {
      setSearchAcc('');
    } else if (index === 34) {
      setSearchCompany('');
    } else if (index === 35) {
      setSearchNote('');
    } else if (index === 36) {
      setSearchSellNote('');
    } else if (index === 37) {
      setSearchAccNote('');
    } else if (index === 38) {
      setSearchPickupNote('');
    } else if (index === 39) {
      setSearchGnNote('');
    } else if (index === 40) {
      setSearchEndpoint('');
    } else if (index === 41) {
      setSearchExtraSellNote('');
    }
  };
  const handleSelectedContext = (headerGroupObject, headerId, headerActive) => {
    setSelectedHeader(
      headerGroupObject?.headers?.map((sd) => {
        if (sd.id === headerId) {
          sd.context = !sd.context;
        } else {
          sd.context = false;
        }
        return sd;
      })
    );
  };

  const handleCloseContext = (headerGroupObject, headerId, headerActive) => {
    setSelectedHeader(
      headerGroupObject?.headers?.map((sd) => {
        if (sd.id === headerId) {
          sd.context = false;
        }
        return sd;
      })
    );
  };
  const handleSelectedContextMenu = (hawb) => {
    setSelectedShipment(
      shipments.map((sd) => {
        if (sd.hawb === hawb) {
          sd.contextMenu = !sd.contextMenu;
        } else {
          sd.contextMenu = false;
        }
        return sd;
      })
    );
  };
  const handleCloseContextMenu = (hawb) => {
    setSelectedShipment(
      shipments.map((sd) => {
        if (sd.hawb === hawb) {
          sd.contextMenu = false;
        }
        return sd;
      })
    );
  };

  const totalRate = getWeight.reduce((a, v) => (a = +a + +v.rate), 0);
  const totalWeight = getWeight.reduce((a, v) => (a = +a + +v.chargeable), 0);
  const totalExactCharge = getWeight.reduce((a, v) => (a = +a + +v.exactCharge), 0);
  const totalExactWeight = getWeight.reduce((a, v) => (a = +a + +v.exactWeight), 0);
  const totalPieces = getWeight.reduce((a, v) => (a = +a + +v.amount), 0);
  const totalReweight = getWeight.reduce((a, v) => (a = +a + +v.reweight), 0);
  const totalPrice = getWeight.reduce((a, v) => (a = +a + +v.totalValue), 0);
  const totalComestic = getWeight.reduce((a, v) => (a = +a + +v.comestic), 0);
  const totalAddress = getWeight.reduce((a, v) => (a = +a + +v.addressCorrection), 0);
  const totalRemote = getWeight.reduce((a, v) => (a = +a + +v.remote), 0);
  const totalSurcharge = getWeight.reduce((a, v) => (a = +a + +v.surcharge), 0);
  const totalFscValue = getWeight.reduce((a, v) => (a = +a + +v.fscValue), 0);
  const totalSurcharge2 = getWeight.reduce((a, v) => (a = +a + +v.surcharge2), 0);
  const totalVatValue = getWeight.reduce((a, v) => (a = +a + +v.vatValue), 0);
  const totalDemandCharge = getWeight.reduce((a, v) => (a = +a + +v.demandCharge || 0), 0);
  const totalSurcharge3 = getWeight.reduce((a, v) => (a = +a + +v.surcharge3), 0);

  const totalSelectedWeight = selected.reduce((a, v) => (a = +a + +v.chargeable), 0);
  const totalDus = dus.reduce((a, v) => (a = +a + +v.chargeable), 0);
  const totalDhl = dhl.reduce((a, v) => (a = +a + +v.chargeable), 0);
  const totalDhlVn = dhlVn.reduce((a, v) => (a = +a + +v.chargeable), 0);
  const totalFedexVn = fedexVn.reduce((a, v) => (a = +a + +v.chargeable), 0);
  const totalFie = fie.reduce((a, v) => (a = +a + +v.chargeable), 0);
  const totalFip = fip.reduce((a, v) => (a = +a + +v.chargeable), 0);
  const totalF3p = f3p.reduce((a, v) => (a = +a + +v.chargeable), 0);

  const handleSortShipmentColumn = (index, hawb) => {
    if (index === 0) {
      setSortClass(!sortClass);
    } else if (index === 1) {
      setSortCustomer(!sortCustomer);
    } else if (index === 2) {
      setSortDate(!sortDate);
    } else if (index === 3) {
      setSortFrom(!sortFrom);
    } else if (index === 4) {
      setSortCountry(!sortCountry);
    } else if (index === 5) {
      setSortStatus(!sortStatus);
    } else if (index === 6) {
      setSortType(!sortType);
    } else if (index === 7) {
      setSortWeight(!sortWeight);
    } else if (index === 8) {
      setSortExactWeight(!sortExactWeight);
    } else if (index === 9) {
      setSortChargeable(!sortChargeable);
    } else if (index === 10) {
      setSortReweight(!sortReweight);
    } else if (index === 11) {
      setSortPcs(!sortPcs);
    } else if (index === 12) {
      setSortPack(!sortPack);
    } else if (index === 13) {
      setSortAirline(!sortAirline);
    } else if (index === 14) {
      setSortService(!sortService);
    } else if (index === 15) {
      setSortAgent(!sortAgent);
    } else if (index === 16) {
      setSortMawb(!sortMawb);
    } else if (index === 17) {
      setSortSin(!sortSin);
    } else if (index === 18) {
      setSortRate(!sortRate);
    } else if (index === 19) {
      setSortComestic(!sortComestic);
    } else if (index === 20) {
      setSortAddress(!sortAddress);
    } else if (index === 21) {
      setSortRemote(!sortRemote);
    } else if (index === 22) {
      setSortSurcharge1(!sortSurcharge1);
    } else if (index === 23) {
      setSortFsc(!sortFsc);
    } else if (index === 24) {
      setSortFscValue(!sortFscValue);
    } else if (index === 25) {
      setSortSurcharge2(!sortSurcharge2);
    } else if (index === 26) {
      setSortVat(!sortVat);
    } else if (index === 27) {
      setSortVatValue(!sortVatValue);
    } else if (index === 28) {
      setSortSurcharge3(!sortSurcharge3);
    } else if (index === 30) {
      setSortTotal(!sortTotal);
    } else if (index === 31) {
      setSortLastUpdate(!sortLastUpdate);
    } else if (index === 32) {
      setSortSale(!sortSale);
    } else if (index === 33) {
      setSortAcc(!sortAcc);
    } else if (index === 34) {
      setSortCompany(!sortCompany);
    } else if (index === 35) {
      setSortNote(!sortNote);
    } else if (index === 36) {
      setSortSellNote(!sortSellNote);
    } else if (index === 37) {
      setSortAccNote(!sortAccNote);
    } else if (index === 38) {
      setSortPickupNote(!sortPickupNote);
    } else if (index === 39) {
      setSortGnNote(!sortGnNote);
    }
  };
  const handleChangeInputColumn = (index, e) => {
    setCurrentPage(1);
    if (index === 1) {
      setSearchCus(e.target.value);
    } else if (index === 2) {
      setSearchDate(e.target.value);
    } else if (index === 3) {
      setSearchFrom(e.target.value);
    } else if (index === 4) {
      setSearchCountry(e.target.value);
    } else if (index === 5) {
      setSearchStatus(e.target.value);
    } else if (index === 6) {
      setSearchType(e.target.value);
    } else if (index === 7) {
      setSearchWeight(e.target.value);
    } else if (index === 8) {
      setSearchExactWeight(e.target.value);
    } else if (index === 9) {
      setSearchExactCharge(e.target.value);
    } else if (index === 10) {
      setSearchReweight(e.target.value);
    } else if (index === 11) {
      setSearchPcs(e.target.value);
    } else if (index === 12) {
      setSearchPack(e.target.value);
    } else if (index === 13) {
      setSearchAirline(e.target.value);
    } else if (index === 14) {
      setSearchService(e.target.value);
    } else if (index === 15) {
      setSearchAgent(e.target.value);
    } else if (index === 16) {
      setSearchMawb(e.target.value);
    } else if (index === 17) {
      setSearchSin(e.target.value);
    } else if (index === 18) {
      setSearchRate(e.target.value);
    } else if (index === 19) {
      setSearchComestic(e.target.value);
    } else if (index === 20) {
      setSearchAddress(e.target.value);
    } else if (index === 21) {
      setSearchRemote(e.target.value);
    } else if (index === 22) {
      setSearchSurcharge(e.target.value);
    } else if (index === 23) {
      setSearchFsc(e.target.value);
    } else if (index === 24) {
      setSearchFscValue(e.target.value);
    } else if (index === 25) {
      setSearchSurcharge2(e.target.value);
    } else if (index === 26) {
      setSearchVat(e.target.value);
    } else if (index === 27) {
      setSearchVatValue(e.target.value);
    } else if (index === 28) {
      setSearchSurcharge3(e.target.value);
    } else if (index === 29) {
      setSearchDemandCharge(e.target.value);
    } else if (index === 30) {
      setSearchTotal(e.target.value);
    } else if (index === 32) {
      setSearchSale(e.target.value);
    } else if (index === 33) {
      setSearchAcc(e.target.value);
    } else if (index === 34) {
      setSearchCompany(e.target.value);
    } else if (index === 35) {
      setSearchNote(e.target.value);
    } else if (index === 36) {
      setSearchSellNote(e.target.value);
    } else if (index === 37) {
      setSearchAccNote(e.target.value);
    } else if (index === 38) {
      setSearchPickupNote(e.target.value);
    } else if (index === 39) {
      setSearchGnNote(e.target.value);
    } else if (index === 40) {
      setSearchEndpoint(e.target.value);
    } else if (index === 0) {
      setSearchClass(e.target.value);
    }
  };
  const handleFirstPage = () => {
    setCurrentPage(1);
    setMaxPageLimit(10);
    setMinPageLimit(0);
  };
  const handleLastPage = () => {
    setCurrentPage(pages[pages.length - 1]);
    setMaxPageLimit(pages.length);
    setMinPageLimit(pages.length - 5);
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
  };
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
  };
  const navArrays = [
    { label: 'New Booking', id: 0, path: '/' },
    { label: 'Shipments', id: 1, path: '/shipments' },
    { label: 'Packing', id: 2, path: '/packing' },
    { label: 'Customers', id: 3, path: '/customers' },
    { label: 'Transactions', id: 4, path: '/transaction' },
    { label: 'Docs', id: 5, path: '/docs' },
    { label: 'Report Error', id: 6, path: '/report' },
  ];

  const trackArray = searchMultipleShipment.split(/\r?\n/);

  const handleShowEdit = () => {
    setDisplayEdit(!displayEdit);
  };
  const handleCloseEdit = () => {
    setDisplayEdit(false);
  };

  const handleShowTracing = () => {
    setDisplayTracing(!displayTracing);
  };
  const selectedTracing = exportSelected.slice(0, 100);
  const handleTracing = () => {
    selectedTracing.forEach((s) => {
      if (s.status === 'Cleared For Export') {
        db.collection('shipments')
          .doc(s.hawb)
          .update({
            status: 'In Transit',
            desc: firebase.firestore.FieldValue.arrayUnion({
              time: traceTime + 'T' + finalEtd + ':00',
              location: 'SINGAPORE - SINGAPORE',
              stat: 'Shipment in transit',
            }),
          });
        axios.put('https://test.skydart.com.vn/api/update/hub', { hawb: s.hawb, status: 'In Transit' });
      } else {
      }
    });
  };
  const handleShowNote = () => {
    setDisplayNote(!displayNote);
  };
  const handleShowFlight = () => {
    setDisplayFlight(!displayFlight);
  };
  const handleShowPricing = () => {
    setDisplayPricing(!displayPricing);
  };
  const handleShowSurcharge = () => {
    setDisplaySurcharge(!displaySurcharge);
  };
  const handleShowAction = () => {
    setDisplayAction(!displayAction);
  };
  const handleShowPayment = () => {
    setDisplayPayment(!displayPayment);
  };
  const handleShowExport = () => {
    setDisplayExport(!displayExport);
  };
  const handleShowMore = () => {
    setDisplayMore(!displayMore);
  };
  const handleCloseMore = () => {
    setDisplayMore(false);
  };

  const handleShowColumn = () => {
    setDisplayColumn(!displayColumn);
  };

  const onUpdateAll = (mawbInput, sinInput, flightInput, agentInput, packingInput) => {
    setLoadingAction(true);
    exportSelected.forEach((s) => {
      db.collection('shipments')
        .doc(s.hawb)
        .update({
          sin: sinInput || s.sin,
          mawb: mawbInput || s.mawb,
          pack: packingInput || s.pack,
        });
      axios
        .put('https://test.skydart.com.vn/api/update/all', {
          hawb: s.hawb,
          mawb: mawbInput || s.mawb,
          sin: sinInput || s.sin,
          airline: flightInput || s.airline,
          agent: agentInput || s.agent,
          service: s.service,
          pack: packingInput || s.pack,
        })
        .then(() => {
          setSelectedShipment(
            shipments.map((val) => {
              let mawbValue = mawbInput || s.mawb;
              let sinValue = sinInput || s.sin;
              let airlineValue = flightInput || s.airline;
              let agentValue = agentInput || s.agent;
              let serviceValue = s.service;
              let packValue = packingInput || s.pack;
              if (val.hawb === s.hawb) {
                val.mawb = mawbValue;
                val.sin = sinValue;
                val.airline = airlineValue;
                val.agent = agentValue;
                val.service = serviceValue;
                val.pack = packValue;
              }
              return val;
            })
          );
          setLoadingAction(false);
          setAlertSuccess(true);
        });
    });
  };
  const newSingleNote = (hawbValue, noteContent) => {
    setSelectedShipment(
      shipments.map((sd) => {
        if (sd.hawb === hawbValue) {
          if (user.role === 'OPS') {
            sd.note = noteContent;
          } else if (user.role === 'Finance') {
            sd.accNote = noteContent;
          } else if (user.role === 'Courier') {
            sd.gnNote = noteContent;
          } else if (user.role === 'CEO') {
            sd.note = noteContent;
          }
        }
        return sd;
      })
    );
  };

  const onUpdateNote = async (note) => {
    setLoadingAction(true);
    setDisplayNote(false);
    const url =
      user.role === 'OPS'
        ? 'https://test.skydart.com.vn/api/update/note'
        : user.role === 'Finance'
          ? 'https://test.skydart.com.vn/api/accountant/note'
          : user.role?.includes('Courier')
            ? 'https://test.skydart.com.vn/api/courier/note'
            : user.role === 'CEO'
              ? 'https://test.skydart.com.vn/api/update/note'
              : user.role === 'Sales'
                ? 'https://test.skydart.com.vn/api/update/sellNote'
                : '';

    const batchSize = 10;

    function throttleBatchUpdate(batch, delay) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(batch);
        }, delay);
      });
    }
    setSelectedShipment(
      selected.map((element) => {
        element.loading = true;
        return element;
      })
    );

    try {
      const updateWithThrottle = async () => {
        const batches = [];
        for (let i = 0; i < exportSelected.length; i += batchSize) {
          batches.push(exportSelected.slice(i, i + batchSize));
        }
        for (let batch of batches) {
          await throttleBatchUpdate(batch, 1000); // 1 second delay between batches
          const updatePromises = batch.map((s) => {
            let updateData;

            if (user.role === 'OPS') {
              updateData = {
                hawb: s.hawb,
                note: note,
                opsNoteBy: user.displayName, // Replace with actual user display name
              };
            } else if (user.role === 'Finance') {
              updateData = {
                hawb: s.hawb,
                accountNote: note,
                accountNoteBy: user.displayName, // Replace with actual user display name
              };
            } else if (user.role === 'CFO') {
              updateData = {
                hawb: s.hawb,
                accountNote: note,
                accountNoteBy: user.displayName, // Replace with actual user display name
              };
            } else if (user.role?.includes('Courier')) {
              updateData = {
                hawb: s.hawb,
                gnNote: note,
                gnNoteBy: user.displayName, // Replace with actual user display name
              };
            } else if (user.role === 'CEO') {
              updateData = {
                hawb: s.hawb,
                note: note,
                opsNoteBy: user.displayName, // Replace with actual user display name
              };
            } else if (user.role === 'Sales') {
              if (s.controller === user.displayName) {
                updateData = {
                  hawb: s.hawb,
                  sellNote: note,
                };
              } else {
                alert('You can not other person shipment');
              }
            } else {
              throw new Error('You do not have permission for this action');
            }
            return axios.put(url, updateData).then(() => {
              setAlertSuccess(true);
              setLoadingAction(false);
              setSelectedShipment(
                shipments.map((el) => {
                  if (el.hawb === s.hawb) {
                    if (user.role === 'OPS') {
                      el.note = note;
                      el.loading = false;
                      el.updateSuccess = 'true';
                    } else if (user.role === 'Finance') {
                      el.accNote = note;
                      el.loading = false;
                      el.updateSuccess = 'true';
                    } else if (user.role === 'CFO') {
                      el.accNote = note;
                      el.loading = false;
                      el.updateSuccess = 'true';
                    } else if (user.role === 'Courier') {
                      el.gnNote = note;
                      el.loading = false;
                      el.updateSuccess = 'true';
                    } else if (user.role === 'Sales') {
                      el.sellNote = note;
                      el.loading = false;
                      el.updateSuccess = 'true';
                    } else if (user.role === 'CEO') {
                      el.note = note;
                      el.loading = false;
                      el.updateSuccess = 'true';
                    } else {
                      return el;
                    }
                    return el;
                  }
                })
              );
            });
          });

          await Promise.all(updatePromises);
        }
      };

      await updateWithThrottle();
    } catch (error) {
      // Handle error and display appropriate messages to the user
      console.error('Error updating notes:', error);
      setAlertSuccess(false);
      setLoadingAction(false);
    }
  };
  const refreshFlight = () => {
    setLoadingAction(true);
    axios.get('https://test.skydart.com.vn/api/flight/info').then((res) => {
      setFlights(res.data);
      setLoadingAction(false);
    });
  };

  const refreshSurcharge = () => {
    setLoadingAction(true);
    db.collection('surcharge')
      .doc('skd-surcharge')
      .get()
      .then((doc) => {
        if (doc.exists) {
          setSurchargeData(doc.data());
          setLoadingAction(false);
        }
      });
  };

  const handleSurcharge = (fetchDhl, fetchFedex, fetchUps, exchangeCurrency) => {
    if (user.role === 'CEO') {
      setDisplaySurcharge(false);
      setLoadingAction(true);
      db.collection('surcharge')
        .doc('skd-surcharge')
        .set({
          dhl: fetchDhl,
          fedex: fetchFedex,
          ups: fetchUps,
          exchange: exchangeCurrency,
          lastUpdate: user.displayName,
        })
        .then(() => {
          setAlertSuccess(true);
          setLoadingAction(false);
        });
    } else if (user.role === 'Pricing') {
      setDisplaySurcharge(false);
      setLoadingAction(true);
      db.collection('surcharge')
        .doc('skd-surcharge')
        .set({
          dhl: fetchDhl,
          fedex: fetchFedex,
          exchange: exchangeCurrency,
          ups: fetchUps,
          lastUpdate: user.displayName,
        })
        .then(() => {
          setAlertSuccess(true);
          setLoadingAction(false);
        });
    } else if (user.role === 'Finance') {
      setDisplaySurcharge(false);
      setLoadingAction(true);
      db.collection('surcharge')
        .doc('skd-surcharge')
        .set({
          dhl: fetchDhl,
          fedex: fetchFedex,
          exchange: exchangeCurrency,
          ups: fetchUps,
          lastUpdate: user.displayName,
        })
        .then(() => {
          setAlertSuccess(true);
          setLoadingAction(false);
        });
    } else {
      alert('You are not allowed to run this function');
    }
  };
  useEffect(() => {
    if (displayFlight) {
      refreshFlight();
    }
  }, [displayFlight]);
  useEffect(() => {
    if (displaySurcharge) {
      refreshSurcharge();
    }
  }, [displaySurcharge]);

  const mergeFlight = (flightData) => {
    setFlights([...flights, flightData]);
  };
  const todayFlight = flights.filter((s) => {
    return s.createAt === traceTime;
  });

  const submitRate = (
    rateInput,
    remoteInput,
    fuel,
    fuelValue,
    comesticInput,
    addressCorrectionInput,
    vatInput,
    vatValue,
    surchargeInput,
    surchargeInput2,
    surchargeInput3,
    sellNoteInput,
    demandCharge,
    comInput
  ) => {
    setLoadingAction(true);
    exportSelected.forEach((s) => {
      axios
        .put('https://test.skydart.com.vn/api/update/rate', {
          rate: rateInput || s.rate,
          fsc: fuel || s.fsc,
          fscValue: fuelValue,
          comestic: comesticInput || s.comestic,
          remote: remoteInput || s.remote,
          addressCorrection: addressCorrectionInput || s.addressCorrection,
          vat: vatInput || s.vat,
          vatValue: vatValue,
          surcharge: surchargeInput || s.surcharge,
          surcharge2: surchargeInput2 || s.surcharge2,
          surcharge3: surchargeInput3 || s.surcharge3,
          sellNote: sellNoteInput || s.sellNote,
          totalValue: '',
          rateUpdate: `${user.displayName}, ${traceTime}, ${dateTime}`,
          com: comInput || s.com,
          demandCharge: demandCharge || s.demandCharge,
          hawb: s.hawb,
        })
        .then(() => {
          setSelectedShipment(
            shipments.map((sd) => {
              if (sd.hawb === s.hawb) {
                sd.rate = rateInput || s.rate;
                sd.fsc = fuel || s.fsc;
                sd.fscValue = fuelValue || s.fscValue;
                sd.comestic = comesticInput || s.comestic;
                sd.remote = remoteInput || s.remote;
                sd.addressCorrection = addressCorrectionInput || s.addressCorrection;
                sd.vat = vatInput || s.vat;
                sd.vatValue = vatValue || s.vatValue;
                sd.surcharge = surchargeInput || s.surcharge;
                sd.surcharge2 = surchargeInput2 || s.surcharge2;
                sd.surcharge3 = surchargeInput || s.surcharge3;
                sd.sellNote = sellNoteInput || s.sellNote;
                sd.com = comInput || s.com;
                sd.demandCharge = demandCharge || s.demandCharge;
              }
              return sd;
            })
          );
          setLoadingAction(false);
          setDisplayPricing(false);
        })
        .then(() => {
          setAlertSuccess(true);
          setTimeout(() => {
            setAlertSuccess(false);
          }, 2000);
        });
    });
  };
  const updateAllMode = (mode, note) => {
    setDisplayPayment(false);
    setLoading(true);
    const selectedShipments = selected.slice();
    const processBatch = (startIndex) => {
      const endIndex = Math.min(startIndex + 10, selectedShipments.length);
      Promise.all(
        selectedShipments.slice(startIndex, endIndex).map((s) => {
          return new Promise((resolve, reject) => {
            s.loading = true;
            db.collection('shipments').doc(s.hawb).update({
              paymentStatus: 'Pay',
              collect: user.displayName,
            });
            axios
              .put('https://test.skydart.com.vn/api/update/shipment-paid', {
                hawb: s.hawb,
                paymentStatus: 'Pay',
              })
              .then(() => {
                axios
                  .put('https://test.skydart.com.vn/api/payment/all', {
                    hawb: s.hawb,
                    paymentPickup: mode,
                    paymentAcc: mode,
                    paymentCompany: mode,
                    collectBy: user.displayName,
                    paymentSubStatus: 'Paid To Company',
                  })
                  .then(() => {
                    setSelectedShipment(
                      shipments.map((val) => {
                        let paymentPickupValue = mode;
                        let paymentAccountValue = mode;
                        let paymentCompanyValue = mode;
                        if (val.hawb === s.hawb) {
                          val.paymentPickup = paymentPickupValue;
                          val.paymentAcc = paymentAccountValue;
                          val.paymentCompany = paymentCompanyValue;
                          val.loading = false;
                          val.updateSuccess = 'true';
                        }
                        return val;
                      })
                    );
                    if (note) {
                      exportSelected.forEach((s) => {
                        axios
                          .put('https://test.skydart.com.vn/api/accountant/note', {
                            hawb: s.hawb,
                            accountNote: note,
                            accountNoteBy: user.displayName,
                          })
                          .then(() => {
                            setLoadingAction(false);
                            setAlertSuccess(true);
                            setTimeout(() => {
                              setAlertSuccess(false);
                            }, 2000);
                            setSelectedShipment(
                              shipments.map((val) => {
                                let noteValue = note || s.accNote;
                                if (val.hawb === s.hawb) {
                                  val.accNote = noteValue;
                                }
                                return val;
                              })
                            );
                          });
                      });
                    } else {
                      setLoading(false);
                      setAlertSuccess(true);
                    }
                  });
              });
          }).then(() => {
            if (endIndex < selectedShipments.length) {
              processBatch(endIndex);
            } else {
              setLoadingAction(false);
              setAlertSuccess(true);
            }
          });
        })
      );
    };
    setSelectedShipment(
      selected.forEach((s, index) => {
        s.loading = true;
        db.collection('shipments').doc(s.hawb).update({
          paymentStatus: 'Pay',
          collect: user.displayName,
        });
        axios
          .put('https://test.skydart.com.vn/api/update/shipment-paid', {
            hawb: s.hawb,
            paymentStatus: 'Pay',
          })
          .then(() => {
            axios
              .put('https://test.skydart.com.vn/api/payment/all', {
                hawb: s.hawb,
                paymentPickup: mode,
                paymentAcc: mode,
                paymentCompany: mode,
                collectBy: user.displayName,
                paymentSubStatus: 'Paid To Company',
              })
              .then(() => {
                setSelectedShipment(
                  shipments.map((val) => {
                    let paymentPickupValue = mode;
                    let paymentAccountValue = mode;
                    let paymentCompanyValue = mode;
                    if (val.hawb === s.hawb) {
                      val.paymentPickup = paymentPickupValue;
                      val.paymentAcc = paymentAccountValue;
                      val.paymentCompany = paymentCompanyValue;
                      val.loading = false;
                      val.updateSuccess = 'true';
                    }
                    return val;
                  })
                );
                if (index === exportSelected.length - 1) {
                  setLoadingAction(false);
                  setAlertSuccess(true);
                }
                if (note) {
                  exportSelected.forEach((s) => {
                    axios
                      .put('https://test.skydart.com.vn/api/accountant/note', {
                        hawb: s.hawb,
                        accountNote: note,
                        accountNoteBy: user.displayName,
                      })
                      .then(() => {
                        setLoadingAction(false);
                        setAlertSuccess(true);
                        setTimeout(() => {
                          setAlertSuccess(false);
                        }, 2000);
                        setSelectedShipment(
                          shipments.map((val) => {
                            let noteValue = note || s.accNote;
                            if (val.hawb === s.hawb) {
                              val.accNote = noteValue;
                            }
                            return val;
                          })
                        );
                      });
                  });
                } else {
                  setLoading(false);
                  setAlertSuccess(true);
                }
              });
          });
      })
    );
  };
  const updateMode = (mode, accountNote) => {
    setLoadingAction(true);
    setDisplayPayment(false);
    setLoading(true);
    const selectedShipments = selected.slice();
    const processBatch = (startIndex) => {
      const endIndex = Math.min(startIndex + 10, selectedShipments.length);
      Promise.all(
        selectedShipments.slice(startIndex, endIndex).map((s) => {
          return new Promise((resolve, reject) => {
            s.loading = true;
            db.collection('shipments').doc(s.hawb).update({
              paymentStatus: 'Pay',
              accountant: user.displayName,
            });
            axios
              .put('https://test.skydart.com.vn/api/update/shipment-paid', { hawb: s.hawb, paymentStatus: 'Pay' })
              .then(() => {
                axios
                  .put('https://test.skydart.com.vn/api/payment-mode', {
                    hawb: s.hawb,
                    paymentPickup: mode,
                    paymentAcc: mode,
                    collectBy: user.displayName,
                    paymentSubStatus: 'Paid To Accountant',
                  })
                  .then(() => {
                    setSelectedShipment(
                      shipments.map((val) => {
                        let paymentPickupValue = mode;
                        let paymentAccountValue = mode;
                        if (val.hawb === s.hawb) {
                          val.paymentPickup = paymentPickupValue;
                          val.paymentAcc = paymentAccountValue;
                          val.loading = false;
                          val.updateSuccess = 'true';
                        }
                        return val;
                      })
                    );
                    resolve();
                    if (accountNote) {
                      exportSelected.forEach((s) => {
                        axios
                          .put('https://test.skydart.com.vn/api/accountant/note', {
                            hawb: s.hawb,
                            accountNote: accountNote,
                            accountNoteBy: user.displayName,
                          })
                          .then(() => {
                            setSelectedShipment(
                              shipments.map((val) => {
                                let noteValue = accountNote || s.accNote;
                                if (val.hawb === s.hawb) {
                                  val.accNote = noteValue;
                                }
                                return val;
                              })
                            );
                          });
                      });
                    } else {
                      setLoadingAction(false);
                      setAlertSuccess(true);
                      setTimeout(() => {
                        setAlertSuccess(false);
                      }, 2000);
                    }
                  });
              });
          }).then(() => {
            if (endIndex < selectedShipments.length) {
              processBatch(endIndex);
            } else {
              setLoadingAction(false);
              setAlertSuccess(true);
            }
          });
        })
      );
    };
    processBatch(0);
  };
  const updatePrepaid = (mode, note) => {
    setDisplayPayment(false);
    setLoading(true);
    const url = 'https://test.skydart.com.vn/api/update/shipment-paid';
    const selectedShipments = selected.slice();

    const processBatch = (startIndex) => {
      const endIndex = Math.min(startIndex + 10, selectedShipments.length);

      Promise.all(
        selectedShipments.slice(startIndex, endIndex).map((s) => {
          return new Promise((resolve, reject) => {
            s.loading = true;
            db.collection('shipments').doc(s.hawb).update({
              paymentStatus: 'Un Paid',
              collect: user.displayName,
            });
            axios.put(url, {
              hawb: s.hawb,
              paymentStatus: 'Un Paid',
            });
            axios
              .put('https://test.skydart.com.vn/api/payment/all', {
                hawb: s.hawb,
                paymentPickup: mode,
                paymentAcc: mode,
                paymentCompany: mode,
                collectBy: user.displayName,
                paymentSubStatus: 'Paid To Company',
              })
              .then(() => {
                setSelectedShipment((shipments) =>
                  shipments.map((val) => {
                    let paymentPickupValue = mode;
                    let paymentAccountValue = mode;
                    let paymentCompanyValue = mode;
                    if (val.hawb === s.hawb) {
                      val.paymentPickup = paymentPickupValue;
                      val.paymentAcc = paymentAccountValue;
                      val.paymentCompany = paymentCompanyValue;
                      val.loading = false;
                      val.updateSuccess = 'true';
                    }
                    return val;
                  })
                );
                resolve();
              })
              .catch((error) => reject(error));
          });
        })
      ).then(() => {
        if (endIndex < selectedShipments.length) {
          processBatch(endIndex);
        } else {
          setLoadingAction(false);
          setAlertSuccess(true);
        }
      });
    };

    processBatch(0);
  };
  const agentExport = {
    data: exportSelected,
    headers: agentHeader,
    filename: fileName || `Report ${startDay} to ${endDay}`,
    delimiter: ',',
  };

  const trackingExport = {
    data: exportSelected,
    headers: trackingHeader,
    filename: fileName || `Report ${startDay} to ${endDay}`,
    delimiter: ',',
  };
  const dusReport = {
    data: exportSelected,
    headers: title,
    filename: fileName || `Report ${startDay} to ${endDay}`,
    delimiter: ',',
  };
  const mockHeader = headerGroups[0].headers.filter((s) => {
    return s.active;
  });
  const trueHeader = mockHeader.map((s) => {
    return {
      label: s.Header,
      key: s.key,
    };
  });

  const getMoreHeader = [{ label: 'HAWB', key: 'removeSkd' }, ...trueHeader];

  const debitExport = {
    data: exportSelected,
    headers: '',
    filename: fileName || `Report ${startDay} to ${endDay}`,
    delimiter: ',',
  };
  const updateBatchReweight = (reweightResult) => {
    setLoadingAction(true);
    reweightResult.map((item) => {
      const hawbDoc = 'SKD' + item.HAWB;
      db.collection('shipments')
        .doc(hawbDoc)
        .update({
          reweight: item.Chargeable,
        })
        .then(() => {
          axios
            .put('https://test.skydart.com.vn/api/reweight', { hawb: hawbDoc, reweight: item.Chargeable })
            .then(() => {
              setSelectedShipment(
                shipments.map((sd) => {
                  if (sd.hawb === hawbDoc) {
                    sd.reweight = item.Chargeable;
                  }
                  return sd;
                })
              );
              setLoadingAction(false);
              setAlertSuccess(true);
              setTimeout(() => {
                setAlertSuccess(false);
              }, 2000);
            });
        })
        .catch((err) => {
          setLoadingAction(false);
        });
    });
  };
  const updateBatchTracking = (trackingResult) => {
    setLoadingAction(true);
    trackingResult.map((item) => {
      const hawbDoc = 'SKD' + item.HAWB;
      db.collection('shipments')
        .doc(hawbDoc)
        .update({
          sin: item.TrackingNumber,
        })
        .then(() => {
          axios
            .put('https://test.skydart.com.vn/api/update/tracking', { hawb: hawbDoc, sin: item.TrackingNumber })
            .then(() => {
              setSelectedShipment(
                shipments.map((sd) => {
                  if (sd.hawb === hawbDoc) {
                    sd.sin = item.TrackingNumber;
                  }
                  return sd;
                })
              );
              setLoadingAction(false);
              setAlertSuccess(true);
              setTimeout(() => {
                setAlertSuccess(false);
              }, 2000);
            });
        })
        .catch((err) => {
          setLoadingAction(false);
        });
    });
  };
  const shipmentArrived = () => {
    setLoading(true);
    setDisplayAction(false);
    setSelectedShipment(
      selected.map((s, index) => {
        s.loading = true;
        s.currentStatus = '';
        db.collection('shipments')
          .doc(s.hawb)
          .update({
            status: 'Arrived At Skydart Origin Depot',
            desc: firebase.firestore.FieldValue.arrayUnion({
              time: traceTime + 'T' + dateTime + ':00',
              location: 'VIETNAM - HO CHI MINH',
              stat: 'Shipment arrived at Skydart Origin Depot',
            }),
            receiveBy: user.displayName,
          })
          .then(() => {
            axios
              .put('https://test.skydart.com.vn/api/update/arrived', {
                hawb: s.hawb,
                status: 'Arrived At Skydart Origin Depot',
              })
              .then(() => {
                setSelectedShipment(
                  selected.map((sd) => {
                    sd.loading = false;
                    sd.currentStatus = 'Arrived Depot';
                    sd.status = 'Arrived At Skydart Origin Depot';
                  })
                );
                if (index === selected.length - 1) {
                  setLoading(false);
                  setAlertSuccess(true);
                  setTimeout(() => {
                    setAlertSuccess(false);
                  }, 2000);
                }
              });
          });
      })
    );
  };
  const shipmentProcess = () => {
    setLoading(true);
    setDisplayAction(false);
    setSelectedShipment(
      selected.map((s, index) => {
        s.loading = true;
        s.currentStatus = '';
        db.collection('shipments')
          .doc(s.hawb)
          .update({
            status: 'In Process',
            desc: firebase.firestore.FieldValue.arrayUnion({
              time: traceTime + 'T' + dateTime + ':00',
              location: 'VIETNAM - HO CHI MINH',
              stat: 'Shipment is in process',
            }),
            processBy: user.displayName,
          })
          .then(() => {
            axios
              .put('https://test.skydart.com.vn/api/update/process', { hawb: s.hawb, status: 'In Process' })
              .then(() => {
                setSelectedShipment(
                  selected.map((sd) => {
                    sd.loading = false;
                    sd.currentStatus = 'In Process';
                    sd.status = 'In Process';
                  })
                );
                if (index === selected.length - 1) {
                  setLoading(false);
                  setAlertSuccess(true);
                  setTimeout(() => {
                    setAlertSuccess(false);
                  }, 2000);
                }
              });
          });
      })
    );
  };
  const shipmentHold = () => {
    setLoading(true);
    setDisplayAction(false);
    setSelectedShipment(
      selected.map((s, index) => {
        s.loading = true;
        s.currentStatus = '';
        db.collection('shipments')
          .doc(s.hawb)
          .update({
            status: 'On Hold',
            desc: firebase.firestore.FieldValue.arrayUnion({
              time: traceTime + 'T' + dateTime + ':00',
              location: 'VIETNAM - HO CHI MINH',
              stat: 'Shipment On Hold',
            }),
            holdBy: user.displayName,
          })
          .then(() => {
            axios.put('https://test.skydart.com.vn/api/update/hold', { hawb: s.hawb, status: 'On Hold' }).then(() => {
              setSelectedShipment(
                selected.map((sd) => {
                  sd.loading = false;
                  sd.currentStatus = 'On Hold';
                  sd.status = 'On Hold';
                })
              );
              if (index === selected.length - 1) {
                setLoading(false);
                setAlertSuccess(true);
                setTimeout(() => {
                  setAlertSuccess(false);
                }, 2000);
              }
            });
          });
      })
    );
  };
  const shipmentClearance = () => {
    setLoading(true);
    setDisplayAction(false);
    setSelectedShipment(
      selected.map((s, index) => {
        s.loading = true;
        s.currentStatus = '';
        db.collection('shipments')
          .doc(s.hawb)
          .update({
            status: 'In Clearance',
            desc: firebase.firestore.FieldValue.arrayUnion({
              time: traceTime + 'T' + dateTime + ':00',
              location: 'VIETNAM - HO CHI MINH',
              stat: 'Shipment is in clearance at Tan Son Nhat airport',
            }),
            clearanceBy: user.displayName,
          })
          .then(() => {
            axios
              .put('https://test.skydart.com.vn/api/update/clearance', { hawb: s.hawb, status: 'In Clearance' })
              .then(() => {
                setSelectedShipment(
                  selected.map((sd) => {
                    sd.loading = false;
                    sd.currentStatus = 'Clearance';
                    sd.status = 'In Clearance';
                  })
                );
                if (index === selected.length - 1) {
                  setLoading(false);
                  setAlertSuccess(true);
                  setTimeout(() => {
                    setAlertSuccess(false);
                  }, 2000);
                }
              });
          });
      })
    );
  };
  const shipmentCleared = () => {
    setLoading(true);
    setDisplayAction(false);
    setSelectedShipment(
      selected.forEach((s, index) => {
        s.loading = true;
        s.currentStatus = '';
        db.collection('shipments')
          .doc(s.hawb)
          .update({
            status: 'Cleared For Export',
            desc: firebase.firestore.FieldValue.arrayUnion({
              time: traceTime + 'T' + dateTime + ':00',
              location: 'VIETNAM - HO CHI MINH',
              stat: 'Shipment cleared for export',
            }),
            clearedBy: user.displayName,
          })
          .then(() => {
            axios
              .put('https://test.skydart.com.vn/api/update/transit', { hawb: s.hawb, status: 'Cleared For Export' })
              .then(() => {
                setSelectedShipment(
                  selected.map((sd) => {
                    sd.loading = false;
                    sd.currentStatus = 'Cleared for export';
                    sd.status = 'Cleared For Export';
                  })
                );
                if (index === selected.length - 1) {
                  setLoading(false);
                  setAlertSuccess(true);
                  setTimeout(() => {
                    setAlertSuccess(false);
                  }, 2000);
                }
              });
          });
      })
    );
  };
  const shipmentTransit = () => {
    setLoading(true);
    setDisplayAction(false);
    setSelectedShipment(
      selected.forEach((s, index) => {
        s.loading = true;
        s.currentStatus = '';
        db.collection('shipments')
          .doc(s.hawb)
          .update({
            status: 'In Transit',
            desc: firebase.firestore.FieldValue.arrayUnion({
              time: traceTime + 'T' + dateTime + ':00',
              location: 'VIETNAM - HO CHI MINH',
              stat: 'Shipment in transit',
            }),
            transitBy: user.displayName,
          })
          .then(() => {
            axios
              .put('https://test.skydart.com.vn/api/update/cleared', { hawb: s.hawb, status: 'In Transit' })
              .then(() => {
                setSelectedShipment(
                  selected.map((sd) => {
                    sd.loading = false;
                    sd.currentStatus = 'In Transit';
                    sd.status = 'In Transit';
                  })
                );
                if (index === selected.length - 1) {
                  setLoading(false);
                  setAlertSuccess(true);
                  setTimeout(() => {
                    setAlertSuccess(false);
                  }, 2000);
                }
              });
          });
      })
    );
  };
  const shipmentDelivered = () => {
    setLoading(true);
    setDisplayAction(false);
    setSelectedShipment(
      selected.forEach((s, index) => {
        s.loading = true;
        s.currentStatus = '';
        db.collection('shipments')
          .doc(s.hawb)
          .update({
            status: 'Delivered',
            deliveredBy: user.displayName,
          })
          .then(() => {
            axios
              .put('https://test.skydart.com.vn/api/update/delivered', { hawb: s.hawb, status: 'Delivered' })
              .then(() => {
                setSelectedShipment(
                  selected.map((sd) => {
                    sd.loading = false;
                    sd.currentStatus = 'Delivered';
                    sd.status = 'Delivered';
                  })
                );
                if (index === selected.length - 1) {
                  setLoading(false);
                  setAlertSuccess(true);
                  setTimeout(() => {
                    setAlertSuccess(false);
                  }, 2000);
                }
              });
          });
      })
    );
  };
  const returnShipment = () => {
    setLoading(true);
    setDisplayDeleteConfirm(false);
    setSelectedShipment(
      selected.forEach((s, index) => {
        s.loading = true;
        s.currentStatus = '';
        db.collection('shipments')
          .doc(s.hawb)
          .update({
            status: 'Pending',
            desc: firebase.firestore.FieldValue.arrayUnion({
              time: traceTime + 'T' + dateTime + ':00',
              location: 'VIETNAM - HO CHI MINH',
              stat: 'Shipment returned to origin',
              person: user.displayName,
            }),
            returnAt: traceTime,
          })
          .then(() => {
            axios.delete(`https://test.skydart.com.vn/api/delete/${s.hawb}`).then(() => {
              setSelectedShipment(
                selected.map((sd) => {
                  sd.loading = false;
                  sd.currentStatus = 'Removed';
                })
              );
              if (index === selected.length - 1) {
                setLoading(false);
                setAlertSuccess(true);
                setTimeout(() => {
                  setAlertSuccess(false);
                }, 2000);
              }
            });
          });
      })
    );
  };
  const convertToShortNotation = (num) => {
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k';
    } else {
      return num;
    }
  };
  const fixedCss =
    'fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.55)] flex items-center justify-center z-[9999] create-shipment-fade';
  const handleCloseShipment = () => {
    setDisplayCreateShipment(false);
  };
  const getCurrentDateTime = () => {
    const options = { weekday: 'short', day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit' };
    const currentDate = new Date().toLocaleDateString('en-US', options);
    setLastUpdateStatus(currentDate);
  };

  const getCurrentPage = (pageInput) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setCurrentPage(pageInput);
    }, 1000);
  };
  return (
    <div className="w-full overflow-auto">
      {displayCreateShipment && <CreateShipment handleCloseShipment={handleCloseShipment} />}
      {displayEdit && (
        <EditScreen
          fixedCss={fixedCss}
          handleCloseEdit={handleCloseEdit}
          onUpdateAll={onUpdateAll}
          loading={loadingAction}
          alertSuccess={alertSuccess}
          selected={selected}
        />
      )}
      {displayTracing && (
        <TracingScreen fixedCss={fixedCss} handleCloseTracing={handleCloseTracing} selected={selected} />
      )}
      {displayNote && (
        <NoteScreen
          fixedCss={fixedCss}
          handleCloseNote={handleCloseNote}
          selected={selected}
          loading={loadingAction}
          alertSuccess={alertSuccess}
          user={user}
          onUpdateNote={onUpdateNote}
          newSingleNote={newSingleNote}
        />
      )}
      {displayFlight && (
        <FlightScreen
          handleCloseFlight={handleCloseFlight}
          user={user}
          traceTime={traceTime}
          todayFlight={todayFlight}
          mergeFlight={mergeFlight}
        />
      )}
      {displayPricing && (
        <PricingScreen
          handleClosePricing={handleClosePricing}
          user={user}
          selected={selected}
          submitRate={submitRate}
          loading={loadingAction}
        />
      )}
      {displaySurcharge && (
        <SurchargeScreen
          user={user}
          handleCloseSurcharge={handleCloseSurcharge}
          handleSurcharge={handleSurcharge}
          surchargeData={surchargeData}
          loading={loadingAction}
        />
      )}
      {displayPayment && (
        <PaymentScreen
          selected={selected}
          user={user}
          handleClosePayment={handleClosePayment}
          fixedCss={fixedCss}
          loading={loadingAction}
          updateMode={updateMode}
          updateAllMode={updateAllMode}
          updatePrepaid={updatePrepaid}
          alertSuccess={alertSuccess}
        />
      )}
      {displayAction && <ActionScreen />}
      {displayExport && (
        <ExportScreen
          handleCloseExport={handleCloseExport}
          fileName={fileName}
          setFileName={setFileName}
          debitExport={debitExport}
          agentExport={agentExport}
          startDay={startDay}
          exportSelected={exportSelected}
          user={user}
          trackingExport={trackingExport}
          endDay={endDay}
          dusReport={dusReport}
          traceTime={traceTime}
          trueHeader={getMoreHeader}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {displayImport && (
        <ImportScreen
          uploadStatus={uploadStatus}
          fixedCss={fixedCss}
          loading={loadingAction}
          alertSuccess={alertSuccess}
          updateBatchReweight={updateBatchReweight}
          updateBatchTracking={updateBatchTracking}
          handleCloseImport={handleCloseImport}
          user={user}
        />
      )}
      {displayEndpoint && <Endpoint selected={selected} returnFunction={() => setDisplayEndpoint(false)} />}
      <WorkSpace
        refreshShipment={refreshShipment}
        loading={loading}
        finalShipment={getWeight}
        systemStatus={systemStatus}
        currentRoute="Shipments"
        lastUpdateStatus={lastUpdateStatus}
      />
      <div className="p-1 flex items-center pt-0">
        <div className="w-full bg-white rounded-[10px] m-2 flex items-center shadow-[rgba(0,0,0,0.1)_0px_1px_1px_0px,rgba(0,0,0,0.05)_0px_1px_2px_0px]">
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplaySearch(false);
            }}
          >
            <div className="relative flex items-center">
              <IoSearchOutline size={23} className="ml-3" />
              <input
                type="text"
                onFocus={() => setDisplaySearch(true)}
                placeholder="Search and filter shipments"
                className="rounded-full border-solid border-slate-300 border-[1px] m-[3px] p-1 ml-2 px-2.5 w-[280px] search-column-input"
                value={
                  searchShipmentType === 'HAWB'
                    ? searchHawb
                    : searchShipmentType === 'MAWB'
                      ? searchMawb
                      : searchShipmentType === 'SIN AWB'
                        ? searchSin
                        : ''
                }
                onChange={(e) => {
                  setCurrentPage(1);
                  if (searchShipmentType === 'HAWB') {
                    setSearchHawb(e.target.value);
                  } else if (searchShipmentType === 'MAWB') {
                    setSearchMawb(e.target.value);
                  } else if (searchShipmentType === 'SIN AWB') {
                    setSearchSin(e.target.value);
                  }
                }}
              />
              {displaySearch && searchHawb === '' && (
                <div className="absolute bg-white top-[45px] w-full z-[9999] shadow-[rgba(0,0,0,0.15)_2.4px_2.4px_3.2px] rounded-[10px] max-h-[400px] overflow-y-auto">
                  <div className="p-2" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
                    <TextareaAutosize
                      placeholder="Enter HAWB List"
                      className="w-full resize-none p-2 mt-1 bg-slate-200 outline-none rounded-[7px] text-[15px]"
                      value={searchMultipleShipment}
                      onChange={(e) => setSearchMultipleShipment(e.target.value)}
                    />
                    <p className="p-2" style={{ borderTop: '1px solid rgb(230,230,230)' }}>
                      Separate shipments by hitting "Enter" to break the line
                    </p>
                    <div
                      className="p-1.5 sticky bottom-0 z-10 bg-sky-600 text-white flex items-center justify-center text-[17px] font-semibold hover:opacity-70 active:opacity-50 select-none cursor-pointer rounded-[7px]"
                      onClick={() => {
                        fetchMultipleSearched();
                        setSearchMultipleShipment('');
                        setDisplaySearch(false);
                      }}
                    >
                      <p>Search</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </OutsideClickHandler>
          <p className="mx-1.5 ml-auto text-slate-500 text-[15px]">Search type:</p>
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplaySearchShipmentType(false);
            }}
          >
            <div className="relative mr-1.5">
              <div
                className="p-1 flex items-center font-semibold cursor-pointer hover:opacity-70 active:opacity-50 select-none"
                onClick={() => {
                  setDisplaySearchShipmentType(!displaySearchShipmentType);
                }}
              >
                <p className="mx-1.5">By {searchShipmentType}</p>
                <FaSortDown size={22} className="mt-[-10px]" />
              </div>
              {displaySearchShipmentType && (
                <div className="absolute bg-white mt-2 shadow-[rgba(17,17,26,0.05)_0px_4px_16px,rgba(17,17,26,0.05)_0px_8px_32px] z-[9999] w-[180px] ml-1.5 rounded-[7px]">
                  <div
                    className="p-1.5 text-[14px] font-semibold rounded-[10px] hover:bg-slate-100 active:bg-slate-200 m-1.5 select-none cursor-pointer"
                    onClick={() => {
                      setDisplaySearchShipmentType(false);
                      setSearchShipmentType('HAWB');
                    }}
                  >
                    <p>By HAWB</p>
                  </div>
                  <div
                    className="p-1.5 text-[14px] font-semibold rounded-[10px] hover:bg-slate-100 active:bg-slate-200 m-1.5 select-none cursor-pointer"
                    onClick={() => {
                      setDisplaySearchShipmentType(false);
                      setSearchShipmentType('MAWB');
                    }}
                  >
                    <p>By MAWB</p>
                  </div>
                  <div
                    className="p-1.5 text-[14px] font-semibold rounded-[10px] hover:bg-slate-100 active:bg-slate-200 m-1.5 select-none cursor-pointer"
                    onClick={() => {
                      setDisplaySearchShipmentType(false);
                      setSearchShipmentType('SIN AWB');
                    }}
                  >
                    <p>By SIN AWB</p>
                  </div>
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
        <div className="w-fit mr-2.5 whitespace-nowrap">
          <OutsideClickHandler
            onOutsideClick={() => {
              handleCloseDate();
            }}
          >
            <div className="relative">
              <div
                className="flex p-2 bg-slate-200 rounded-[10px] hover:bg-slate-300 active:bg-slate-400 select-none transition-[0.1s] cursor-pointer"
                onClick={() => handleShowDate()}
              >
                <GoCalendar size={24} />
                <p style={{ marginRight: 7, marginLeft: 7 }}>{dateStatus}:</p>
                <p style={{ marginRight: 7 }}>{startDay}</p>
                <AiOutlineMinus size={20} style={{ marginRight: 7 }} />
                <p style={{ marginRight: 7 }}>{endDay}</p>
                <IoIosArrowDropdown size={24} />
              </div>
              {displayDate && (
                <div className="skd-user-date" style={{ right: 0 }}>
                  <DatepickerContext.Provider
                    value={{
                      focusedDate,
                      isDateFocused,
                      isDateSelected,
                      isDateHovered,
                      isDateBlocked,
                      isFirstOrLastSelectedDate,
                      onDateSelect,
                      onDateFocus,
                      onDateHover,
                    }}
                  >
                    <div className="skd-user-date-content">
                      <div className="skd-user-date-left">
                        <div
                          className={
                            dateStatus === 'Today'
                              ? 'skydart-date-range-right-option1'
                              : 'skydart-date-range-right-option'
                          }
                          onClick={handleToday}
                        >
                          {dateStatus === 'Today' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                          <p style={{ color: 'black' }}>Today</p>
                        </div>
                        <div
                          className={
                            dateStatus === 'Yesterday'
                              ? 'skydart-date-range-right-option1'
                              : 'skydart-date-range-right-option'
                          }
                          onClick={handleYesterday}
                        >
                          {dateStatus === 'Yesterday' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                          <p style={{ color: 'black' }}>Yesterday</p>
                        </div>
                        <div
                          className={
                            dateStatus === 'This week'
                              ? 'skydart-date-range-right-option1'
                              : 'skydart-date-range-right-option'
                          }
                          onClick={handleThisWeek}
                        >
                          {dateStatus === 'This week' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                          <p style={{ color: 'black' }}>This week</p>
                        </div>
                        <div
                          className={
                            dateStatus === 'Last week'
                              ? 'skydart-date-range-right-option1'
                              : 'skydart-date-range-right-option'
                          }
                          onClick={handleLastWeek}
                        >
                          {dateStatus === 'Last week' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                          <p style={{ color: 'black' }}>Last week</p>
                        </div>
                        <div
                          className={
                            dateStatus === 'This month'
                              ? 'skydart-date-range-right-option1'
                              : 'skydart-date-range-right-option'
                          }
                          onClick={handleThisMonth}
                        >
                          {dateStatus === 'This month' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                          <p style={{ color: 'black' }}>This Month</p>
                        </div>
                        <div
                          className={
                            dateStatus === 'Last month'
                              ? 'skydart-date-range-right-option1'
                              : 'skydart-date-range-right-option'
                          }
                          onClick={handleLastMonth}
                        >
                          {dateStatus === 'Last month' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                          <p style={{ color: 'black' }}>Last Month</p>
                        </div>
                        <div
                          className={
                            dateStatus === 'This year'
                              ? 'skydart-date-range-right-option1'
                              : 'skydart-date-range-right-option'
                          }
                          onClick={handleThisYear}
                        >
                          {dateStatus === 'This year' && <FcCheckmark style={{ fontSize: 15, color: 'black' }} />}
                          <p style={{ color: 'black' }}>This Year</p>
                        </div>
                      </div>
                      <div className="skd-user-date-right">
                        <div
                          style={{
                            display: 'flex',
                            margin: '15px 0 0',
                            gridTemplateColumns: `repeat(${activeMonths.length}, 300px)`,
                            gridGap: '0 24px',
                          }}
                        >
                          <div className="date-month-back" onClick={goToPreviousMonths}>
                            <BsArrowLeft />
                          </div>
                          <div className="date-month-back1" onClick={goToNextMonths}>
                            <BsArrowRight />
                          </div>
                          {activeMonths.map((month) => (
                            <Month1
                              key={`${month.year}-${month.month}`}
                              year={month.year}
                              month={month.month}
                              firstDayOfWeek={firstDayOfWeek}
                            />
                          ))}
                        </div>
                        <div className="skd-user-date-shown">
                          <div className="skd-user-date-shown-range">
                            <h5 style={{ fontWeight: 450 }}>
                              {!dateRange.startDate ? 'Start Date' : dateRange.startDate.toDateString()}
                            </h5>
                            <AiOutlineMinus />
                            <h5 style={{ fontWeight: 450, marginLeft: 7 }}>
                              {!dateRange.endDate ? 'End Date' : dateRange.endDate.toDateString()}
                            </h5>
                            <p style={{ fontSize: 10 }}>Ho Chi Minh City time</p>
                          </div>
                          <div className="skd-user-date-update-button">
                            <div className="skd-user-date-update-cancel" onClick={handleCancelDate}>
                              <h5 style={{ color: 'black', textAlign: 'center' }}>Cancel</h5>
                            </div>
                            <div className="skd-user-date-update-succeed" onClick={handleApplyDate}>
                              <h5 style={{ color: 'white', textAlign: 'center' }}>Update</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DatepickerContext.Provider>
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
      <div
        className="p-1 pt-0 pb-0
       flex items-center gap-x-1.5 font-semibold"
      >
        {statusArray.map((status, index) => (
          <div
            className={`p-1.5 ${index === 0 ? 'ml-5' : index === statusArray.length - 1 ? 'mr-5' : ''} ${
              statusInput === status.value ? 'bg-white text-sky-600' : 'bg-gray-100 text-black'
            } flex items-center w-full shadow-[rgba(0,0,0,0.1)_0px_0px_9px] cursor-pointer select-none relative`}
            style={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            onClick={() => setStatusInput(status.value)}
          >
            {status.icon}
            <p>{status.label}</p>
            <div
              className={`absolute right-[-1px] top-[-9px] ${
                statusInput === status.value ? 'bg-rose-600 text-white' : 'text-slate-500'
              } font-semibold min-w-[23px] rounded-full flex items-center justify-center text-[14px]`}
            >
              <p className="px-1">{status.shipmentLength}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="bg-white mx-3 rounded-[10px] shadow-[rgba(0,0,0,0.1)_0px_1px_1px_0px,rgba(0,0,0,0.1)_0px_1px_2px_0px]">
        <div className="p-2.5 flex items-center gap-x-2 overflow-auto">
          <div
            className="p-1.5 rounded-[10px] bg-sky-600 text-white flex items-center font-semibold select-none cursor-pointer hover:bg-sky-700 active:bg-sky-800 whitespace-nowrap"
            onClick={() => {
              setDisplayCreateShipment(!displayCreateShipment);
            }}
          >
            <IoAddOutline size={23} />
            <p className="mx-1">Create</p>
          </div>
          <div
            className={`p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none hover:bg-slate-300 active:bg-slate-400 ${
              selected.length === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
            }`}
            onClick={() => {
              setAlertSuccess(false);
              if (selected.length !== 0) {
                handleShowEdit();
              }
            }}
          >
            <MdEditSquare size={23} />
            <p className="mx-1">Update</p>
          </div>
          <div
            className={`p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none  hover:bg-slate-300 active:bg-slate-400 ${
              selected.length === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
            }`}
            onClick={() => {
              if (selected.length !== 0) {
                const hawbArray = selected.map((item) => {
                  return item.hawb;
                });
                const getHawb = hawbArray.slice(0, 20);
                const currentHawb = getHawb.toString();
                navigate(`/tracking-result/${currentHawb}`);
              }
            }}
          >
            <MdOutlineTrackChanges size={23} />
            <p className="mx-1">Track</p>
          </div>
          <div className="flex items-center gap-x-1">
            <div className="group">
              <div
                className={`p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none  hover:bg-slate-300 active:bg-slate-400 ${
                  selected.length === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                }`}
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                onClick={() => {
                  setAlertSuccess(false);
                  if (selected.length !== 0) {
                    handleShowNote();
                  }
                }}
              >
                <MdOutlineStickyNote2 size={23} />
              </div>
              <div className="absolute hidden group-hover:block z-[9999] bg-black text-white text-[13px] px-2 rounded-full mt-1.5 ml-[-30px]">
                Note shipment
              </div>
            </div>
            <div className="group">
              <div
                className={`p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none  hover:bg-slate-300 active:bg-slate-400 cursor-pointer`}
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                onClick={() => {
                  setAlertSuccess(false);
                  handleShowFlight();
                }}
              >
                <MdOutlineFlight size={23} />
              </div>
              <div className="absolute hidden group-hover:block z-[9999] bg-black text-white text-[13px] px-2 rounded-full mt-1.5 ml-[-23px]">
                Flight info
              </div>
            </div>
          </div>
          <div className="flex items-center gap-x-1">
            <div className="group">
              <div
                className={`p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none  hover:bg-slate-300 active:bg-slate-400 ${
                  selected.length === 0
                    ? 'opacity-50 cursor-not-allowed'
                    : user.role !== 'CEO' && user.role !== 'Pricing'
                      ? 'opacity-50 cursor-not-allowed'
                      : 'cursor-pointer'
                }`}
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                onClick={() => {
                  if (user.role === 'Pricing') {
                    setAlertSuccess(false);
                    if (selected.length !== 0) {
                      handleShowPricing();
                    }
                  } else if (user.role === 'CEO') {
                    setAlertSuccess(false);
                    if (selected.length !== 0) {
                      handleShowPricing();
                    }
                  } else {
                    return;
                  }
                }}
              >
                <IoMdPricetags size={23} />
              </div>
              <div className="absolute hidden group-hover:block z-[9999] bg-black text-white text-[13px] px-2 rounded-full mt-1.5 ml-[-12px]">
                Pricing
              </div>
            </div>
            <div className="group">
              <div
                className={`p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none  hover:bg-slate-300 active:bg-slate-400 cursor-pointer`}
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                onClick={() => {
                  setAlertSuccess(false);
                  handleShowSurcharge();
                }}
              >
                <SiGoogleanalytics size={23} />
              </div>
              <div className="absolute hidden group-hover:block z-[9999] bg-black text-white text-[13px] px-2 rounded-full mt-1.5 ml-[-40px]">
                FSC & Surcharge
              </div>
            </div>
          </div>
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplayAction(false);
            }}
          >
            <div>
              <div
                className={`p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none  hover:bg-slate-300 active:bg-slate-400 ${
                  selected.length === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                }`}
                onClick={() => {
                  if (user.role === 'Courier Manager') {
                    if (selected.length !== 0) {
                      handleShowAction();
                    }
                  } else if (user.role === 'Admin') {
                    if (selected.length !== 0) {
                      handleShowAction();
                    }
                  } else if (user.role === 'CEO') {
                    if (selected.length !== 0) {
                      handleShowAction();
                    }
                  } else if (user.role === 'CS') {
                    if (selected.length !== 0) {
                      handleShowAction();
                    }
                  } else if (user.role === 'OPS') {
                    if (selected.length !== 0) {
                      handleShowAction();
                    }
                  } else {
                    alert('You dont have permission to run this action');
                  }
                }}
              >
                <AiTwotoneInteraction size={23} />

                <p className="mx-1">Action</p>
                <FaSortDown size={23} className="mt-[-10px]" />
              </div>
              {displayAction && (
                <div className="absolute left-[485px]  bg-white rounded-[10px] z-[9999] mt-[7px] w-[220px] shadow-[rgba(0,0,0,0.15)_0px_10px_15px_-3px,rgba(0,0,0,0.05)_0px_4px_6px_-2px] self-start">
                  <div
                    className="m-1 p-1.5 rounded-[10px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none"
                    onClick={shipmentArrived}
                  >
                    <p>Arrive Depot</p>
                  </div>
                  <div
                    className="m-1 p-1.5 rounded-[10px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none"
                    onClick={shipmentProcess}
                  >
                    <p>In Process</p>
                  </div>
                  <div
                    className="m-1 p-1.5 rounded-[10px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none"
                    onClick={shipmentHold}
                  >
                    <p>Hold</p>
                  </div>
                  <div
                    className="m-1 p-1.5 rounded-[10px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none"
                    onClick={shipmentClearance}
                  >
                    <p>In Clearance</p>
                  </div>
                  <div
                    className="m-1 p-1.5 rounded-[10px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none"
                    onClick={shipmentCleared}
                  >
                    <p>Cleared for export</p>
                  </div>
                  <div
                    className="m-1 p-1.5 rounded-[10px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none"
                    onClick={shipmentTransit}
                  >
                    <p>In Transit</p>
                  </div>
                  <div
                    className="m-1 p-1.5 rounded-[10px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none"
                    onClick={shipmentDelivered}
                  >
                    <p>Delivered</p>
                  </div>
                  {/* <div className="m-1 p-1.5 rounded-[10px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none">
                    <p>Update controller</p>
                  </div>
                  <div className="m-1 p-1.5 rounded-[10px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none">
                    <p>Update class</p>
                  </div> */}
                  {user.role === 'CEO' && (
                    <div
                      className="m-1 p-1.5 rounded-[10px] cursor-pointer hover:bg-slate-100 active:bg-slate-200 select-none"
                      onClick={returnShipment}
                    >
                      <p>Delete shipment</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </OutsideClickHandler>
          <div
            className={`p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none  hover:bg-slate-300 active:bg-slate-400 ${
              selected.length === 0
                ? 'opacity-50 cursor-not-allowed'
                : user.role !== 'Finance' && user.role !== 'CEO' && user.role !== 'CFO'
                  ? 'opacity-50 cursor-not-allowed'
                  : 'cursor-pointer'
            }`}
            onClick={() => {
              if (user.role === 'Finance') {
                setAlertSuccess(false);
                if (selected.length !== 0) {
                  handleShowPayment();
                }
              } else if (user.role === 'CEO') {
                setAlertSuccess(false);
                if (selected.length !== 0) {
                  handleShowPayment();
                }
              } else if (user.role === 'CFO') {
                setAlertSuccess(false);
                if (selected.length !== 0) {
                  handleShowPayment();
                }
              } else {
                return;
              }
            }}
          >
            <SlWallet size={23} />

            <p className="mx-1.5">Payment</p>
            <FiMoreVertical size={23} />
          </div>
          <div
            className={`p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none  hover:bg-slate-300 active:bg-slate-400 ${
              selected.length === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
            }`}
            onClick={() => {
              setAlertSuccess(false);
              if (selected.length !== 0) {
                handleShowExport();
              }
            }}
          >
            <FaDownload size={23} />
            <p className="mx-1.5">Export</p>
            <FiMoreVertical size={23} />
          </div>
          <div className="group">
            <div
              className="p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none cursor-pointer hover:bg-slate-300 active:bg-slate-400"
              onClick={refreshShipment}
            >
              <FiRefreshCcw size={23} />
            </div>
            <div className="absolute hidden group-hover:block z-[9999] bg-black text-white text-[13px] px-2 rounded-full mt-1.5 ml-[-14px]">
              Refresh
            </div>
          </div>
          <div className="mr-auto group">
            <OutsideClickHandler
              onOutsideClick={() => {
                setDisplayMore(false);
              }}
            >
              <div
                className={`p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none hover:bg-slate-300 active:bg-slate-400 cursor-pointer`}
                onClick={handleShowMore}
              >
                <TfiMoreAlt size={23} />
              </div>
              {displayMore && (
                <div className="absolute bg-white mt-2 rounded-[10px] z-[9999] w-[250px] shadow-[rgba(99,99,99,0.2)_0px_2px_8px_0px]">
                  <div
                    className="p-1.5 m-0.5 flex items-center rounded-[10px] hover:bg-slate-100 active:bg-slate-200 hover:font-semibold active:font-bold select-none cursor-pointer"
                    onClick={() => {
                      setAlertSuccess(false);
                      setDisplayImport(true);
                      setUploadStatus('tracking');
                      setDisplayMore(false);
                    }}
                  >
                    <MdOutlineTrackChanges size={27} />
                    <p className="ml-2">Upload tracking number</p>
                  </div>
                  <div
                    className="p-1.5 m-0.5 flex items-center rounded-[10px] hover:bg-slate-100 active:bg-slate-200 hover:font-semibold active:font-bold select-none cursor-pointer"
                    onClick={() => {
                      setAlertSuccess(false);
                      setDisplayImport(true);
                      setUploadStatus('reweight');
                      setDisplayMore(false);
                    }}
                  >
                    <LiaWeightSolid size={27} />
                    <p className="ml-2">Upload reweight</p>
                  </div>
                  <div
                    className={`p-1.5 m-0.5 flex items-center rounded-[10px] hover:bg-slate-100 active:bg-slate-200 hover:font-semibold active:font-bold select-none ${
                      selected.length === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                    }`}
                    onClick={() => {
                      if (selected?.length === 0) return;
                      setDisplayEndpoint(true);
                    }}
                  >
                    <BiEdit size={27} />
                    <p className="ml-2">Edit export agent</p>
                  </div>
                  <div
                    className={`p-1.5 m-0.5 flex items-center rounded-[10px] hover:bg-slate-100 active:bg-slate-200 hover:font-semibold active:font-bold select-none ${
                      selected.length === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                    }`}
                    onClick={() => {
                      if (selected?.length === 0) return;
                      setDisplayMore(false);
                      setSelectedShipment(
                        selected.map((sd, index) => {
                          sd.loading = true;
                          axios
                            .put('https://test.skydart.com.vn/api/update/extraSellNote', {
                              hawb: sd.hawb,
                              extraSellNote: 'Missing shipment',
                            })
                            .then(() => {
                              sd.loading = false;
                              sd.updateSuccess = 'true';
                              sd.extraSellNote = 'Missing shipment';
                              if (index === selected?.length - 1) {
                                setLoading(false);
                                alert('Cập nhật hoàn tất');
                              }
                              return sd;
                            });
                        })
                      );
                    }}
                  >
                    <BiEdit size={27} />
                    <p className="ml-2">Mất hàng</p>
                  </div>
                  <div
                    className={`p-1.5 m-0.5 flex items-center rounded-[10px] hover:bg-slate-100 active:bg-slate-200 hover:font-semibold active:font-bold select-none ${
                      selected.length === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                    }`}
                    onClick={() => {
                      if (selected?.length === 0) return;
                      setDisplayMore(false);
                      setSelectedShipment(
                        selected.map((sd, index) => {
                          sd.loading = true;
                          axios
                            .put('https://test.skydart.com.vn/api/update/extraSellNote', {
                              hawb: sd.hawb,
                              extraSellNote: '',
                            })
                            .then(() => {
                              sd.loading = false;
                              sd.updateSuccess = 'true';
                              sd.extraSellNote = '';
                              if (index === selected?.length - 1) {
                                setLoading(false);
                                alert('Cập nhật hoàn tất');
                              }
                              return sd;
                            });
                        })
                      );
                    }}
                  >
                    <IoReturnUpBack size={27} />
                    <p className="ml-2">Khôi phục hàng hoá</p>
                  </div>
                </div>
              )}
              <div className="absolute hidden group-hover:block z-[9999] bg-black text-white text-[13px] px-2 rounded-full mt-1.5 ml-[-25px]">
                More action
              </div>
            </OutsideClickHandler>
          </div>
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplaySelectList(false);
            }}
          >
            {selected?.length !== 0 && (
              <div>
                <div
                  className="p-1.5 rounded-[10px] bg-emerald-50 text-emerald-600 flex items-center font-semibold select-none cursor-pointer whitespace-nowrap px-2.5 hover:bg-emerald-100 active:bg-emerald-200 min-w-[150px] justify-center"
                  onClick={() => setDisplaySelectList(!displaySelectList)}
                >
                  <p className="mx-1.5">{selected?.length} selected</p>
                  <FaSortDown size={23} className="mt-[-10px]" />
                </div>
                {displaySelectList && (
                  <div className="absolute bg-white rounded-[10px] w-[300px] shadow-[rgba(17,17,26,0.1)_0px_4px_16px,rgba(17,17,26,0.15)_0px_8px_32px] z-[9999] mt-1.5 max-h-[calc(100vh_-_20rem)] overflow-auto flex flex-col">
                    <div className="flex items-center sticky top-0 bg-white z-10 p-1 shadow-[rgba(0,0,0,0.05)_2.4px_2.4px_3.2px] mb-1">
                      <GoSearch
                        size={25}
                        className="p-1 bg-white rounded-full shadow-[rgba(67,71,85,0.27)_0px_0px_0.25em,rgba(90,125,188,0.05)_0px_0.25em_1em] absolute left-3"
                      />
                      <input
                        type="text"
                        value={searchSelected}
                        onChange={(e) => setSearchSelected(e.target.value)}
                        autoFocus
                        placeholder="Search HAWB"
                        className="bg-slate-100 m-1 rounded-[20px] p-1 w-[97%] outline-none pl-[33px]"
                      />
                    </div>
                    {selected
                      .filter((s) => {
                        if (s.hawb.toLowerCase().includes(searchSelected.toLowerCase())) {
                          return s;
                        }
                      })
                      .slice(0, showMoreSelected ? selected?.length : 20)
                      .map((item, index) => (
                        <div
                          className="p-1.5 px-2.5 flex items-center justify-between hover:bg-slate-200 rounded-[7px] active:bg-slate-300 mb-1 cursor-pointer select-none mx-2"
                          style={{ borderBottom: index === selected.length - 1 ? '' : '1px solid rgb(220,220,220)' }}
                          onClick={() => {
                            handleShowShipmentLabel(item);
                          }}
                        >
                          <p>{item.hawb}</p>
                          <p className="bg-emerald-50 text-emerald-500 font-semibold tracking-wide text-[14px] px-1.5 rounded-full">
                            {item.chargeable} KG
                          </p>
                        </div>
                      ))}
                    <div
                      className="sticky bottom-0 bg-white z-10 p-1 mx-2.5 flex flex-col"
                      style={{ borderTop: '1px solid lightgray' }}
                    >
                      <div className="flex items-center">
                        <div className="flex items-center p-1 rounded-[10px] font-semibold bg-sky-50 text-[darkblue]">
                          <p className="text-lg">{((totalSelectedWeight * 100) / 100).toFixed(2)} KG</p>
                        </div>
                        <div
                          className="p-1 bg-slate-100 hover:bg-slate-200 active:bg-slate-300 flex items-center ml-auto px-2 rounded-[7px] cursor-pointer select-none"
                          onClick={() => {
                            handleShowExport();
                            setDisplaySelectList(false);
                            setSearchSelected('');
                          }}
                        >
                          <FaDownload size={20} />
                          <p className="mx-1.5">Export</p>
                        </div>
                      </div>
                      <div className="mt-1.5" style={{ borderTop: '1px solid rgb(220,220,220)' }}>
                        {selected?.length > 20 && (
                          <div
                            className={`p-1.5 rounded-[10px] flex items-center my-1.5 font-semibold cursor-pointer select-none ${
                              !showMoreSelected
                                ? 'hover:bg-emerald-100 active:bg-emerald-200 bg-emerald-50 text-emerald-500'
                                : 'bg-rose-50 text-rose-500 hover:bg-rose-100 active:bg-rose-200'
                            }`}
                            onClick={() => setShowMoreSelected(!showMoreSelected)}
                          >
                            <p>{showMoreSelected ? 'Show less shipments' : 'Show all selected shipments'}</p>
                            <FaSortDown size={25} className="mt-[-10px] ml-auto" />
                          </div>
                        )}
                        <p className="text-[15px] text-slate-500 px-1">
                          Showing <span className="text-black underline">{selected.length}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </OutsideClickHandler>
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplayColumn(false);
            }}
          >
            <div>
              <div
                className={`p-1.5 rounded-[10px] bg-slate-200 flex items-center font-semibold select-none hover:bg-slate-300 active:bg-slate-400 ${
                  user.role === 'Courier' ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                }`}
                onClick={() => {
                  if (user.role === 'Courier') return;
                  handleShowColumn();
                }}
              >
                <BsColumnsGap size={23} />
                <p className="mx-1.5">Columns</p>
                <FaSortDown size={23} className="mt-[-10px]" />
              </div>
              {displayColumn && (
                <div className="skydart-shipment-break-down right-[22px] mt-[8px] rounded-[10px]">
                  {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps({})} style={{ display: 'grid' }}>
                      {headerGroup.headers.map((column, index) => (
                        <div
                          className="skydart-shipment-break-down-option cursor-pointer hover:bg-slate-200 active:bg-slate-300"
                          key={index}
                          onClick={() => {
                            handleSelectedHeader(headerGroup, column.id, column.active);
                          }}
                        >
                          <div className="w-[23px] h-[23px] rounded-full flex items-center justify-center bg-white border-solid border-slate-200 border-[1px]">
                            {column.active && <div className="w-[15px] h-[15px] bg-sky-600 rounded-full"></div>}
                          </div>
                          <label style={{ fontSize: 17, marginLeft: 7 }}>{column.render('Header')}</label>
                        </div>
                      ))}
                    </div>
                  ))}
                  <div
                    className="p-1.5 rounded-[5px] hover:bg-slate-200 active:bg-gray-300 cursor-pointer select-none text-[17px] font-semibold"
                    onClick={() => {
                      handleSelectedAllHeader();
                      setDisplayColumn(false);
                    }}
                  >
                    <p>Reset column</p>
                  </div>
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
        {loading && (
          <LinearProgress style={{ width: 190, height: 5, borderRadius: 9999, position: 'absolute', zIndex: 9999 }} />
        )}
        <ShipmentsGrid
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          getCurrentPage={getCurrentPage}
          displayShipment={displayShipment}
          handleChangeClass={handleChangeClass}
          duplicateClass={duplicateClass}
          itemPerPage={itemPerPage}
          setItemPerPage={setItemPerPage}
          duplicateNote={duplicateNote}
          duplicateSellNote={duplicateSellNote}
          duplicateAccNote={duplicateAccNote}
          duplicatePickupNote={duplicatePickupNote}
          duplicateGnNote={duplicateGnNote}
          displayShipmentLabel={displayShipmentLabel}
          handleSelectedCustomizeShipment={handleSelectedCustomizeShipment}
          shipmentData={shipmentData}
          duplicateRemote={duplicateRemote}
          duplicateAddress={duplicateAddress}
          duplicateSender={duplicateSender}
          selected={selected}
          duplicateSale={duplicateSale}
          handleShowShipmentLabel={handleShowShipmentLabel}
          handleSelectedAllShipment={handleSelectedAllShipment}
          handleSelectedCustomizeShipmentRemove={handleSelectedCustomizeShipmentRemove}
          handleCloseShipmentEdit={handleCloseShipmentEdit}
          handleCloseShipmentLabel={handleCloseShipmentLabel}
          selectedEdit={shipmentData}
          handleCloseEdit={handleCloseShipment}
          handleSelectedAllShipment1={handleSelectedAllShipment1}
          handleShowShipment={handleShowShipment}
          handleSelectedContextMenu={handleSelectedContextMenu}
          handleCloseContextMenu={handleCloseContextMenu}
          getTableBodyProps={getTableBodyProps}
          isColumnResizing={isColumnResizing}
          handleSelectedShipment={handleSelectedShipment}
          handleSelectedContext={handleSelectedContext}
          handleCloseContext={handleCloseContext}
          handleChangeInputColumn={handleChangeInputColumn}
          handleResetSearchColumn={handleResetSearchColumn}
          handleSelectedHeader={handleSelectedHeader}
          searchCus={searchCus}
          setSearchCus={setSearchCus}
          searchClass={searchClass}
          searchHawb={searchHawb}
          handleSortShipmentColumn={handleSortShipmentColumn}
          searchCountry={searchCountry}
          searchService={searchService}
          searchType={searchType}
          searchWeight={searchWeight}
          searchPcs={searchPcs}
          searchSin={searchSin}
          searchSale={searchSale}
          searchPack={searchPack}
          searchDate={searchDate}
          searchRate={searchRate}
          searchDemandCharge={searchDemandCharge}
          searchComestic={searchComestic}
          duplicateRate={duplicateRate}
          searchStatus={searchStatus}
          searchCode={searchCode}
          searchFrom={searchFrom}
          searchExactCharge={searchExactCharge}
          searchExactWeight={searchExactWeight}
          searchReweight={searchReweight}
          searchAddress={searchAddress}
          searchRemote={searchRemote}
          searchSurcharge={searchSurcharge}
          searchFsc={searchFsc}
          searchFscValue={searchFscValue}
          searchSurcharge2={searchSurcharge2}
          searchVat={searchVat}
          searchVatValue={searchVatValue}
          searchSurcharge3={searchSurcharge3}
          searchAcc={searchAcc}
          searchCompany={searchCompany}
          searchNote={searchNote}
          searchSellNote={searchSellNote}
          searchAccNote={searchAccNote}
          searchPickupNote={searchPickupNote}
          searchGnNote={searchGnNote}
          searchEndpoint={searchEndpoint}
          searchMawb={searchMawb}
          searchAgent={searchAgent}
          searchAirline={searchAirline}
          handleChangeCode={handleChangeCode}
          duplicateWeight={duplicateWeight}
          duplicateCode={duplicateCode}
          pages={pages}
          currentPage={currentPage}
          handleFirstPage={handleFirstPage}
          handleLastPage={handleLastPage}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          renderPageNumbers={renderPageNumbers}
          totalWeight={((totalWeight * 100) / 100).toFixed(2)}
          totalRate={((totalRate * 100) / 100).toFixed(2)}
          totalExactCharge={((totalExactCharge * 100) / 100).toFixed(2)}
          totalExactWeight={((totalExactWeight * 100) / 100).toFixed(2)}
          totalReweight={((totalReweight * 100) / 100).toFixed(2)}
          totalPrice={((totalPrice * 100) / 100).toFixed(2)}
          totalPieces={totalPieces}
          totalComestic={((totalComestic * 100) / 100).toFixed(2)}
          totalAddress={((totalAddress * 100) / 100).toFixed(2)}
          totalRemote={((totalRemote * 100) / 100).toFixed(2)}
          totalSurcharge={((totalSurcharge * 100) / 100).toFixed(2)}
          totalFscValue={((totalFscValue * 100) / 100).toFixed(2)}
          totalSurcharge2={((totalSurcharge2 * 100) / 100).toFixed(2)}
          totalSurcharge3={((totalSurcharge3 * 100) / 100).toFixed(2)}
          totalDemandCharge={((totalDemandCharge * 100) / 100).toFixed(2)}
          totalVatValue={((totalVatValue * 100) / 100).toFixed(2)}
          totalDus={((totalDus * 100) / 100).toFixed(2)}
          totalDhl={((totalDhl * 100) / 100).toFixed(2)}
          totalFie={((totalFie * 100) / 100).toFixed(2)}
          totalFip={((totalFip * 100) / 100).toFixed(2)}
          totalF3p={((totalF3p * 100) / 100).toFixed(2)}
          totalDhlVn={((totalDhlVn * 100) / 100).toFixed(2)}
          totalFedexVn={((totalFedexVn * 100) / 100).toFixed(2)}
          duplicateService={duplicateService}
          duplicateChargeable={duplicateChargeable}
          duplicateExactWeight={duplicateExactWeight}
          duplicateReweight={duplicateReweight}
          duplicateAirline={duplicateAirline}
          duplicatePack={duplicatePack}
          duplicateAgent={duplicateAgent}
          duplicateComestic={duplicateComestic}
          duplicateSurcharge={duplicateExtra1}
          duplicateSurcharge2={duplicateExtra2}
          duplicateSurcharge3={duplicateExtra3}
          duplicateFsc={duplicateFsc}
          duplicateFscValue={duplicateFscValue}
          duplicateAcc={duplicatePaymentAccountant}
          duplicateCompany={duplicatePaymentCompany}
          duplicateVat={duplicateVat}
          duplicateVatValue={duplicateVatValue}
          duplicateTotal={duplicateTotal}
          duplicateDemandCharge={duplicateDemandCharge}
          searchTotal={searchTotal}
        />
      </div>
    </div>
  );
}

import React from 'react';
import { MdOutlineClear } from 'react-icons/md';
import OutsideClickHandler from 'react-outside-click-handler';
import skydartLogo from '../../skydartLogo.png';
export default function CustomerFeedback({ displayFeedback, handleHideFeedback }) {
  const array = [{ label: 'name', id: 0, ingredients: '' }];
  return (
    <div className="skydart-create-print flex items-center justify-center z-[9999]">
      <OutsideClickHandler
        onOutsideClick={() => {
          handleHideFeedback();
        }}
      >
        <div className="w-[550px] shadow-[rgba(0,0,0,0.15)_0px_2px_8px]  bg-white rounded-[9px]">
          <div className="flex items-start p-3.5 border-b-[1px] border-solid border-t-0 border-x-0 border-slate-300">
            <div>
              <p className="font-bold text-xl">Góp ý Skydart Express</p>
              <p className="text-[15px] leading-5 text-slate-600">
                Hãy cho chúng tôi biết cảm nghĩ cũng như ý kiến của bạn về dịch vụ của chúng tôi để có thể cải thiện tốt
                hơn mỗi ngày nhé!
              </p>
            </div>
            <div
              className="ml-auto w-[38px] h-[38px] rounded-[9px] bg-slate-200 hover:bg-slate-300 active:scale-90 flex items-center justify-center cursor-pointer flex-shrink-0"
              onClick={() => {
                handleHideFeedback();
              }}
            >
              <MdOutlineClear size={28} />
            </div>
          </div>
          <div className="p-3.5">
            <div className="p-2 bg-white rounded-[9px] flex items-center  hover:bg-slate-100 active:bg-slate-300 cursor-pointer select-none transition-[98s]">
              <div className="bg-slate-200 flex-shrink-0 rounded-full flex items-center justify-center w-[50px] h-[50px]">
                <img
                  src={skydartLogo}
                  alt=""
                  loading="lazy"
                  className="w-[35px] h-[35px] object-contain rounded-full"
                />
              </div>
              <div className="ml-2.5">
                <p className="text-xl font-medium">Cải thiện dịch vụ vận chuyển</p>
                <p className="leading-5 text-[14px]">
                  Hãy cho chúng tôi biết trải nghiệm vận chuyển của bạn tại Skydart.
                </p>
              </div>
            </div>
            <div className="p-2 bg-white rounded-[9px] flex items-center  hover:bg-slate-100 active:bg-slate-300 cursor-pointer select-none transition-[98s]">
              <div className="bg-slate-200 flex-shrink-0 rounded-full flex items-center justify-center w-[50px] h-[50px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-[35px] p-[12px]"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-2.5">
                <p className="text-xl font-medium">Xảy ra lỗi trong quá trình vận chuyển</p>
                <p className="leading-5 text-[14px]">Hãy cho chúng tôi biết lỗi sản phẩm bạn đang gặp phải.</p>
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}

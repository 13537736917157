import { CircularProgress, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FaSortDown } from 'react-icons/fa6';
import { IoMdPricetags } from 'react-icons/io';
import { MdOutlineClear } from 'react-icons/md';
import OutsideClickHandler from 'react-outside-click-handler';

export default function PricingScreen({ handleClosePricing, user, selected, submitRate, loading }) {
  const [loadingRate, setLoadingRate] = useState(false);
  const [rateInput, setRateInput] = useState('');
  const [comesticInput, setComesticInput] = useState('');
  const [remoteInput, setRemoteInput] = useState('');
  const [addressCorrectionInput, setAddressCorrectionInput] = useState('');
  const [fuel, setFuel] = useState('');
  const [vatValueInput, setVatValueInput] = useState('');
  const [surchargeInput, setSurchargeInput] = useState('');
  const [surchargeInput2, setSurchargeInput2] = useState('');
  const [surchargeInput3, setSurchargeInput3] = useState('');
  const [comInput, setComInput] = useState('');
  const [sellNoteInput, setSellNoteInput] = useState('');
  const [demandCharge, setDemandCharge] = useState('');
  const [vatInput, setVatInput] = useState('');
  const [showSelected, setShowSelected] = useState(false);
  const handleSubmitRate = async () => {
    submitRate(
      rateInput,
      remoteInput,
      fuel,
      fuelValue,
      comesticInput,
      addressCorrectionInput,
      vatInput,
      vatValue,
      surchargeInput,
      surchargeInput2,
      surchargeInput3,
      sellNoteInput,
      demandCharge,
      comInput
    );
    await setRateInput('');
    setComesticInput('');
    setRemoteInput('');
    setAddressCorrectionInput('');
    setVatValueInput('');
    setSurchargeInput('');
    setSurchargeInput2('');
    setSurchargeInput3('');
    setSellNoteInput('');
    setVatInput('');
    setDemandCharge('');
  };
  const fuelValue =
    ((+rateInput + +addressCorrectionInput + +remoteInput + +demandCharge + +surchargeInput) * +fuel) / 100;
  const vatValue =
    ((+rateInput +
      +addressCorrectionInput +
      +remoteInput +
      +demandCharge +
      +comesticInput +
      +surchargeInput +
      +fuel +
      +surchargeInput2) *
      +vatInput) /
    100;

  const totalValue =
    +rateInput +
    +addressCorrectionInput +
    +remoteInput +
    +demandCharge +
    +comesticInput +
    +surchargeInput +
    +fuelValue +
    +vatValue +
    +surchargeInput3;
  const handleShowSelected = () => {
    setShowSelected(!showSelected);
  };
  const handleCloseSelected = () => {
    setShowSelected(false);
  };
  return (
    <form
      className="shipment-note"
      onSubmit={(e) => {
        e.preventDefault();
        if (user.role === 'CS') {
          handleSubmitRate();
        } else if (user.role === 'CEO') {
          handleSubmitRate();
        } else if (user.role === 'Pricing') {
          handleSubmitRate();
        } else if (user.role !== 'CS' && user.role !== 'CEO') {
          return;
        }
      }}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          handleClosePricing();
        }}
      >
        <div className="shipment-note-container relative" style={{ marginTop: '9%', width: '55%', marginLeft: '24%' }}>
          {loading && (
            <div className="absolute rounded-[10px] right-0 left-0 top-0 bottom-0 bg-[rgba(255,255,255,0.55)] flex items-center justify-center">
              <p className="bg-white font-semibold">Loading...</p>
            </div>
          )}
          <div
            className="p-3 flex items-center justify-between shadow-[rgba(60,64,67,0.03)_0px_1px_2px_0px,rgba(60,64,67,0.15)_0px_2px_6px_2px]"
            style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
          >
            <div className="flex items-center">
              <IoMdPricetags size={32} />
              <h3 className="text-2xl ml-2">Shortcuts pricing update</h3>
            </div>
            <IconButton type="button" onClick={() => handleClosePricing()}>
              <MdOutlineClear size={25} />
            </IconButton>
          </div>
          <OutsideClickHandler
            onOutsideClick={() => {
              handleCloseSelected();
            }}
          >
            <div className="relative w-fit">
              <div
                className="p-1.5 px-3 flex items-center rounded-[10px] bg-slate-100 m-1 w-fit mt-4 mx-3 hover:bg-slate-200 active:bg-slate-300 select-none cursor-pointer"
                onClick={handleShowSelected}
              >
                <p className="ml-1.5">{selected?.length} shipments selected</p>
                <FaSortDown size={25} className="mt-[-10px] ml-1.5" />
              </div>
              {showSelected && (
                <div className="absolute bg-white shadow-[rgba(0,0,0,0.1)_0px_4px_6px_-1px,rgba(0,0,0,0.06)_0px_2px_4px_-1px] ml-3 mt-1 w-full rounded-[10px] z-10">
                  {selected?.map((item) => (
                    <div className="p-1.5 bg-slate-200 rounded-[10px] m-1.5 select-none cursor-pointer" key={item.hawb}>
                      <p>{item.hawb}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </OutsideClickHandler>
          <div className="shipment-rate-content" style={{ paddingTop: 5 }}>
            <div className="flex flex-wrap">
              <div className="m-1">
                <h5>Rate</h5>
                <input
                  type="number"
                  value={rateInput}
                  onChange={(e) => setRateInput(e.target.value)}
                  className="p-1.5 bg-slate-100 rounded-[7px] border-slate-200 border-solid border-[1px] mt-1.5 search-column-input focus:bg-white w-[115px]"
                  placeholder="Enter rate"
                />
              </div>
              <div className="m-1">
                <h5>Correct Add</h5>
                <input
                  type="number"
                  value={addressCorrectionInput}
                  onChange={(e) => setAddressCorrectionInput(e.target.value)}
                  className="p-1.5 bg-slate-100 rounded-[7px] border-slate-200 border-solid border-[1px] mt-1.5 search-column-input focus:bg-white w-[115px]"
                  placeholder="Enter value"
                />
              </div>
              <div className="m-1">
                <h5>Remote</h5>
                <input
                  type="number"
                  value={remoteInput}
                  onChange={(e) => setRemoteInput(e.target.value)}
                  className="p-1.5 bg-slate-100 rounded-[7px] border-slate-200 border-solid border-[1px] mt-1.5 search-column-input focus:bg-white w-[115px]"
                  placeholder="Enter value"
                />
              </div>
              <div className="m-1">
                <h5>Extra 1</h5>
                <input
                  type="number"
                  value={surchargeInput}
                  onChange={(e) => setSurchargeInput(e.target.value)}
                  className="p-1.5 bg-slate-100 rounded-[7px] border-slate-200 border-solid border-[1px] mt-1.5 search-column-input focus:bg-white w-[115px]"
                  placeholder="Enter value"
                />
              </div>
              <div className="m-1">
                <h5>FSC(%)</h5>
                <input
                  type="text"
                  value={fuel}
                  onChange={(e) => setFuel(e.target.value)}
                  className="p-1.5 bg-slate-100 rounded-[7px] border-slate-200 border-solid border-[1px] mt-1.5 search-column-input focus:bg-white w-[115px]"
                  placeholder="Enter value"
                />
              </div>
              <div className="m-1">
                <h5>FSC Value</h5>
                <input
                  type="text"
                  value={(Math.round(fuelValue * 100) / 100).toFixed(2)}
                  disabled
                  className="p-1.5 bg-slate-100 rounded-[7px] border-slate-200 border-solid border-[1px] mt-1.5 search-column-input focus:bg-white w-[115px]"
                />
              </div>
              <div className="m-1">
                <h5>Extra</h5>
                <input
                  type="number"
                  value={comesticInput}
                  onChange={(e) => setComesticInput(e.target.value)}
                  className="p-1.5 bg-slate-100 rounded-[7px] border-slate-200 border-solid border-[1px] mt-1.5 search-column-input focus:bg-white w-[115px]"
                  placeholder="Enter value"
                />
              </div>
              <div className="m-1">
                <h5>Extra 2</h5>
                <input
                  type="number"
                  value={surchargeInput2}
                  onChange={(e) => setSurchargeInput2(e.target.value)}
                  className="p-1.5 bg-slate-100 rounded-[7px] border-slate-200 border-solid border-[1px] mt-1.5 search-column-input focus:bg-white w-[115px]"
                  placeholder="Enter value"
                />
              </div>
              <div className="m-1">
                <h5>VAT(%)</h5>
                <select
                  value={vatInput}
                  onChange={(e) => setVatInput(e.target.value)}
                  className="p-[7px] bg-slate-100 rounded-[7px] border-slate-200 border-solid border-[1px] mt-1.5 search-column-input focus:bg-white w-[115px]"
                  placeholder="Enter value"
                  required
                >
                  <option value="">VAT</option>
                  <option value="0">0%</option>
                  <option value="8">8%</option>
                  <option value="10">10%</option>
                </select>
              </div>
              <div className="m-1">
                <h5>VAT Value</h5>
                <input
                  type="text"
                  value={(Math.round(vatValue * 100) / 100).toFixed(2)}
                  disabled
                  className="p-1.5 bg-slate-100 rounded-[7px] border-slate-200 border-solid border-[1px] mt-1.5 search-column-input focus:bg-white w-[115px]"
                  placeholder="Enter value"
                />
              </div>
              <div className="m-1">
                <h5>Extra 3</h5>
                <input
                  type="number"
                  value={surchargeInput3}
                  onChange={(e) => setSurchargeInput3(e.target.value)}
                  className="p-1.5 bg-slate-100 rounded-[7px] border-slate-200 border-solid border-[1px] mt-1.5 search-column-input focus:bg-white w-[115px]"
                  placeholder="Enter value"
                />
              </div>
              <div className="m-1">
                <h5>Demand charge</h5>
                <input
                  type="number"
                  value={demandCharge}
                  onChange={(e) => setDemandCharge(e.target.value)}
                  className="p-1.5 bg-slate-100 rounded-[7px] border-slate-200 border-solid border-[1px] mt-1.5 search-column-input focus:bg-white w-[115px]"
                  placeholder="Enter value"
                />
              </div>
            </div>
          </div>
          <p className="text-lg font-semibold text-slate-500 px-5">
            Total price: <span className="text-[darkblue]">${totalValue.toFixed(2)}</span>
          </p>
          <div className="px-5">
            <div className="note-collection">
              <h5>Com</h5>
              <input type="number" value={comInput} onChange={(e) => setComInput(e.target.value)} />
            </div>
            <div className="note-collection" style={{ marginTop: 12 }}>
              <h5>Selling Note</h5>
              <textarea
                type="text"
                value={sellNoteInput}
                placeholder="Enter note for selected shipment"
                onChange={(e) => setSellNoteInput(e.target.value)}
                className="p-2 bg-slate-100 rounded-[10px]  outline-none resize-none h-[100px] w-[300px]"
              />
            </div>
          </div>
          <div className="flex items-center p-3 gap-x-1.5">
            <div
              className="p-2 bg-slate-100 hover:bg-slate-200 active:bg-slate-300 rounded-[10px] ml-auto cursor-pointer px-3.5"
              onClick={() => handleClosePricing()}
            >
              <p>Cancel</p>
            </div>
            <div className="p-2 bg-sky-600 hover:bg-sky-700 active:bg-sky-800 rounded-[10px] text-white font-semibold cursor-pointer px-3.5">
              <button type="submit">Update Rate</button>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </form>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { RiArrowDownSFill } from 'react-icons/ri';
import { GrDocument, GrLocation } from 'react-icons/gr';
import { useNavigate, NavLink } from 'react-router-dom';
import { BsBoxSeam } from 'react-icons/bs';
import { v1 as uuid } from 'uuid';
import { IoMdAddCircle } from 'react-icons/io';
import { FaFileInvoice } from 'react-icons/fa';
import { Timestamp } from 'firebase/firestore';
import {
  MdArrowDropDown,
  MdClear,
  MdOutlineAddLocationAlt,
  MdOutlineDocumentScanner,
  MdOutlineEmail,
  MdOutlineLabel,
} from 'react-icons/md';
import { VscDebugRestart, VscNotebookTemplate } from 'react-icons/vsc';
import { GrDocumentText } from 'react-icons/gr';
import { BiAddToQueue } from 'react-icons/bi';
import { GiCommercialAirplane } from 'react-icons/gi';
import { MdSimCardDownload } from 'react-icons/md';
import { BsUpload } from 'react-icons/bs';
import { MdOutlineClear } from 'react-icons/md';
import { HiOutlineDocument } from 'react-icons/hi';

import { IoBuild, IoCreateOutline } from 'react-icons/io5';
import { IoDocumentAttachSharp } from 'react-icons/io5';
import { SiNextbilliondotai, SiReasonstudios } from 'react-icons/si';
import * as XLSX from 'xlsx';
import { CSVLink } from 'react-csv';
import { FcCheckmark, FcHighPriority, FcOk } from 'react-icons/fc';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { CircularProgress, Switch } from '@mui/material';
import { db } from '../../server/firebase';
import CustomerBill from '../components/CustomerBill';
import CustomerInvoice from '../components/CustomerInvoice';
import { FaFlag, FaSortDown } from 'react-icons/fa6';
import { TiArrowBack } from 'react-icons/ti';
import { FaRegUser } from 'react-icons/fa';
import OutsideClickHandler from 'react-outside-click-handler';
import { CiCircleQuestion } from 'react-icons/ci';
import { GoSearch } from 'react-icons/go';
import { FiPackage, FiPhone } from 'react-icons/fi';
import { PiCircuitryThin } from 'react-icons/pi';
import { LiaCitySolid } from 'react-icons/lia';
import { countries } from '../../staff/api/countries';

export default function Booking3({ user }) {
  const { countryParam, codeParam } = useParams();
  const [displayType, setDisplayType] = useState(false);
  const [displaySave, setDisplaySave] = useState(false);
  const [displayService, setDisplayService] = useState(false);
  const [displayReason, setDisplayReason] = useState(false);
  const [displayLabel, setDisplayLabel] = useState(true);
  const [displayInvoice, setDisplayInvoice] = useState(false);
  const [displaySaveList, setDisplaySaveList] = useState(false);
  const [displayShippingMark, setDisplayShippingMark] = useState(false);
  const [displayBill, setDisplayBill] = useState(true);
  const [isSave, setIsSave] = useState(false);
  const [useTemplate, setUseTemplate] = useState(true);
  const [fileAttached, setFileAttached] = useState(false);
  const [attachLoading, setAttachLoading] = useState(false);
  const [validBook, setValidBook] = useState(false);
  const [rapidBooking, setRapidBooking] = useState(false);
  const [displayCountry, setDisplayCountry] = useState(false);
  const [displayDraft, setDisplayDraft] = useState(false);
  const [displayState, setDisplayState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveConfirm, setSaveConfirm] = useState(false);
  const [shipmentList, setShipmentList] = useState([]);
  const [bookConfirm, setBookConfirm] = useState(false);
  const [displayAttach, setDisplayAttach] = useState(false);
  const [doxErr, setDoxErr] = useState(false);
  const [savedShipment, setSavedShipment] = useState([]);
  const [packageValue, setPackageValue] = useState([
    {
      packageLength: 0,
      packageWidth: 0,
      packageHeight: 0,
      packageWeight: 0,
      packageCharge: 0,
      packNo: 1,
      pack: '',
      confirm: false,
    },
  ]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('myArray')) || [];
    const newStoreData = storedData
      .map((item) => ({
        ...item,
        isNew: false,
      }))
      .sort((a, b) => {
        // Compare Firebase Timestamp fields directly
        const timestampA = a.timestamp instanceof Timestamp ? a.timestamp : new Timestamp(0, 0); // default to a very low timestamp if not valid
        const timestampB = b.timestamp instanceof Timestamp ? b.timestamp : new Timestamp(0, 0);

        // Sort descending by timestamp (latest first)
        return timestampB.seconds - timestampA.seconds;
      });
    setSavedShipment(newStoreData);
  }, []);
  const [invoiceValue, setInvoiceValue] = useState([
    {
      id: uuid(),
      isSelectItem: false,
      itemCommonity: '',
      itemVietnamCommonity: '',
      itemCountryOrigin: 'Vietnam',
      itemManufactAddress: '',
      itemPackType: '',
      itemPackType2: '',
      itemHsCode: '',
      itemPnc: '',
      itemUnit: '',
      itemOtherUnit: '',
      itemPurpose: '',
      itemManufact: '',
      itemUnitPrice: 0,
      itemQuantity: 0,
      itemValue: 0,
      itemBrand: '',
      itemIngredients: '',
      itemNo: 1,
      itemFda: '',
      itemUnit2: '',
    },
  ]);
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const navigate = useNavigate();
  const [type, setType] = useState('WPX');
  const [keyword, setKeyword] = useState('');
  const [service, setService] = useState('');
  const [hawb, setHawb] = useState('');
  const [dateTime, setDateTime] = useState('');
  const [traceTime, setTraceTime] = useState('');
  const [datePicker, setDatePicker] = useState('');
  const [dateInput, setDateInput] = useState('');
  const [timeInput, setTimeInput] = useState('');
  const [proxyService, setProxyService] = useState('');
  const [reason, setReason] = useState('');
  const [eamService, setEamService] = useState('');
  const [input, setInput] = useState('');
  const [term, setTerm] = useState('');
  const [eori, setEori] = useState('');
  const [code, setCode] = useState(codeParam || '');
  const [transport, setTransport] = useState(0);
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [countryData, setCountryData] = useState([]);
  const [companyInput, setCompanyInput] = useState('');
  const [countryInput, setCountryInput] = useState(countryParam || '');
  const [addressInput, setAddressInput] = useState('');
  const [addressInput2, setAddressInput2] = useState('');
  const [addressInput3, setAddressInput3] = useState('');
  const [townInput, setTownInput] = useState('');
  const [cityInput, setCityInput] = useState('');
  const [stateLabel, setStateLabel] = useState('');
  const [stateInput, setStateInput] = useState('');
  const [postalInput, setPostalInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [contactInput, setContactInput] = useState('');
  const [deliverySign, setDeliverySign] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [description, setDescription] = useState('');
  const [doxWeight, setDoxWeight] = useState(0.5);
  const [envelope, setEnvelope] = useState(1);
  const [direct, setDirect] = useState('');
  const [stateCode, setStateCode] = useState('');
  const [rated, setRated] = useState([]);
  const [rate, setRate] = useState(0);
  const [doxRate, setDoxRate] = useState(0);
  const [fsc, setFsc] = useState(0);
  const [dhlFsc, setDhlFsc] = useState(0);
  const [fedexFsc, setFedexFsc] = useState(0);
  const [upsFsc, setUpsFsc] = useState(0);
  const [skdFsc, setSkdFsc] = useState(0);
  const [board, setBoard] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [currentZone, setCurrentZone] = useState('');
  useEffect(() => {
    const refNumber = 'SKD';
    const newDates = new Date();
    const dates = (newDates.getDate() < 10 ? '0' : '') + newDates.getDate();
    const months = (newDates.getMonth() + 1 < 10 ? '0' : '') + (newDates.getMonth() + 1);
    const years = newDates.getFullYear().toString().charAt(2);
    const yearLength = newDates.getFullYear().toString().charAt(3);
    const max = 900000;
    const wrapper = `${years}${yearLength}${months}${dates}`;
    const txt = refNumber + wrapper + Math.floor(100000 + Math.random() * max);
    setHawb(txt);
  }, []);

  useEffect(() => {
    let newDate = new Date();
    let date = (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let month = (newDate.getMonth() + 1 < 10 ? '0' : '') + (newDate.getMonth() + 1);
    let year = newDate.getFullYear();
    setDatePicker(`${year}-${month}-${date}`);
    const monthName = months[newDate.getMonth()];
    setTraceTime(`${date} ${monthName} ${year}`);
    const hour = (newDate.getHours() < 10 ? '0' : '') + newDate.getHours();
    const min = (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes();
    setDateTime(`${hour}:${min}`);
  }, []);

  // console.log(input.split(/[/]+/));
  const inputArray = input.split(/[*]+/);
  const handleType = () => {
    if (!displayType) {
      setDisplayType(true);
    } else {
      setDisplayType(false);
    }
  };
  const handleService = () => {
    if (!displayService) {
      setDisplayService(true);
    } else {
      setDisplayService(false);
    }
  };
  const handleReason = () => {
    if (!displayReason) {
      setDisplayReason(true);
    } else {
      setDisplayReason(false);
    }
  };

  // const inputArray = input.split(/[#]+/);

  const handleWPX = () => {
    if (type === 'WPX') {
      return;
    } else {
      setType('WPX');
      setDisplayType(false);
    }
  };
  const handleDOX = () => {
    if (type === 'DOX') {
      return;
    } else {
      setType('DOX');
      setDisplayType(false);
    }
  };
  const importer = inputArray[0]?.trim() || companyInput.trim();
  const addressContainer = inputArray[1]?.trim() || '';
  const address = addressContainer.slice(0, 30) || addressInput.trim();
  const address2 = addressContainer.slice(31, 61) || addressInput2.trim();
  const address3 = addressContainer.slice(62, 92) || addressInput3.trim();
  const town = inputArray[2]?.trim() || townInput.trim();
  const city = inputArray[3]?.trim() || cityInput.trim();
  const state = inputArray[4]?.trim() || stateInput.trim();
  const postal = inputArray[5]?.trim() || postalInput.trim();
  const country = inputArray[6]?.trim() || countryInput.trim();
  const phone = inputArray[7]?.trim() || phoneInput.trim();
  const contact = inputArray[8]?.trim() || contactInput.trim();
  const email = inputArray[9]?.trim() || emailInput.trim();
  useEffect(() => {
    if (direct === 'us') {
      if (
        state !== '' &&
        stateCode !== '' &&
        code !== '' &&
        service !== '' &&
        country !== '' &&
        importer !== '' &&
        address !== '' &&
        address2 !== '' &&
        town !== '' &&
        city !== '' &&
        postal !== '' &&
        contact !== '' &&
        phone !== ''
      ) {
        setValidBook(true);
      } else {
        setValidBook(false);
      }
    } else if (direct !== 'us') {
      if (
        code !== '' &&
        service !== '' &&
        country !== '' &&
        importer !== '' &&
        address !== '' &&
        address2 !== '' &&
        town !== '' &&
        city !== '' &&
        postal !== '' &&
        contact !== '' &&
        phone !== ''
      ) {
        setValidBook(true);
      } else {
        setValidBook(false);
      }
    }
  });

  let addFormFields = () => {
    let x = packageValue.length;
    setPackageValue([
      ...packageValue,
      {
        pack: '',
        confirm: false,
        packNo: x + 1,
        packageLength: 0,
        packageWidth: 0,
        packageHeight: 0,
        packageWeight: 0.0,
        packageCharge: 0,
      },
    ]);
  };
  let removeFormField = (index, e) => {
    if (packageValue.length === 1) {
      return;
    } else {
      setPackageValue(packageValue.filter((v, i) => i !== index));
    }
  };
  let removeInvoice = (index, e) => {
    if (invoiceValue.length === 1) {
      return;
    } else {
      setInvoiceValue(invoiceValue.filter((v, i) => i !== index));
    }
  };
  function removeUndefinedFields(data) {
    return Object.fromEntries(Object.entries(data).filter(([_, value]) => value === undefined));
  }
  function removeUndefinedFields1(data) {
    return Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== undefined));
  }
  let addCarton = () => {
    let x = invoiceValue.length;
    setInvoiceValue([
      ...invoiceValue,
      {
        id: uuid(),
        itemNo: x + 1,
        isSelectItem: false,
        itemCommonity: '',
        itemVietnamCommonity: '',
        itemCountryOrigin: 'Vietnam',
        itemManufactAddress: '',
        itemPackType: '',
        itemPackType2: '',
        itemHsCode: '',
        itemPnc: '',
        itemUnit: '',
        itemOtherUnit: '',
        itemPurpose: '',
        itemManufact: '',
        itemUnitPrice: 0,
        itemQuantity: 0,
        itemValue: 0,
        itemBrand: '',
        itemIngredients: '',
        itemFda: '',
        itemUnit2: '',
      },
    ]);
  };
  const resetPacking = () => {
    setPackageValue([
      { packNo: 1, packageLength: 0, packageWidth: 0, packageHeight: 0, packageWeight: 0, packageCharge: 0 },
    ]);
  };
  const resetInvoice = () => {
    setInvoiceValue([
      {
        itemNo: 1,
        id: uuid(),
        isSelectItem: false,
        itemUnit2: '',
        itemBrand: '',
        itemIngredients: '',
        itemPackType2: '',
        itemCommonity: '',
        itemVietnamCommonity: '',
        itemUnit: '',
        itemOtherUnit: '',
        itemUnitPrice: 0,
        itemQuantity: 0,
        itemValue: 0,
        itemManufact: '',
        itemPurpose: '',
        itemHsCode: '',
        itemPackType: '',
        itemManufactAddress: '',
        itemCountryOrigin: 'Vietnam',
        itemFda: '',
      },
    ]);
  };

  const finalAttachInvoiceValue = invoiceValue.map((s) => {
    return {
      itemValue: s.itemQuantity * s.itemUnitPrice,
    };
  });
  const finalInvoiceValue = finalAttachInvoiceValue.reduce((a, v) => (a = +a.toFixed(2) + +v.itemValue?.toFixed(2)), 0);
  const totalInvoiceValue = +finalInvoiceValue + +transport;
  const headers = [
    { label: 'itemNo', key: 'itemNo' },
    { label: 'itemCommonity', key: 'itemCommonity' },
    { label: 'itemVietnamCommonity', key: 'itemVietnamCommonity' },
    { label: 'itemPackType', key: 'itemPackType' },
    { label: 'itemIngredients', key: 'itemIngredients' },
    { label: 'itemUsage', key: 'itemUsage' },
    { label: 'itemCountryOrigin', key: 'itemCountryOrigin' },
    { label: 'itemManufact', key: 'itemManufact' },
    { label: 'itemManufactAddress', key: 'itemManufactAddress' },
    { label: 'itemHsCode', key: 'itemHsCode' },
    { label: 'itemFda', key: 'itemFda' },
    { label: 'itemQuantity', key: 'itemQuantity' },
    { label: 'itemUnit', key: 'itemUnit' },
    { label: 'itemUnitPrice', key: 'itemUnitPrice' },
  ];
  const exportInvoice = {
    data: invoiceValue,
    headers: headers,
    filename: `${hawb} invoice`,
  };

  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }
  const handleFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileAttached(false);
      setFileName(file.name);
      setFileType(file.type);
      setFileSize(file.size);
      setAttachLoading(true);
      setTimeout(() => {
        setAttachLoading(false);
        readExcel(file);
      }, 1000);
    } else {
    }
  };
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((item) => {
      setInvoiceValue(item);
    });
    setUseTemplate(false);
    setFileAttached(true);
  };
  let typeRef = useRef();
  let serviceRef = useRef();
  let reasonRef = useRef();
  let bookingRef = useRef();
  let stateRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!typeRef.current.contains(e.target)) {
        setDisplayType(false);
      }
      if (!serviceRef.current.contains(e.target)) {
        setDisplayService(false);
      }
      if (!reasonRef.current.contains(e.target)) {
        setDisplayReason(false);
      }
      if (!stateRef.current.contains(e.target)) {
        setDisplayState(false);
      }
      if (!bookingRef.current.contains(e.target)) {
        setBookConfirm(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);
  function removeAccents(str) {
    var AccentsMap = [
      'aàảãáạăằẳẵắặâầẩẫấậ',
      'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
      'dđ',
      'DĐ',
      'eèẻẽéẹêềểễếệ',
      'EÈẺẼÉẸÊỀỂỄẾỆ',
      'iìỉĩíị',
      'oòỏõóọôồổỗốộơờởỡớợ',
      'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
      'uùủũúụưừửữứự',
      'UÙỦŨÚỤƯỪỬỮỨỰ',
      'yỳỷỹýỵ',
      'YỲỶỸÝỴ',
    ];
    for (var i = 0; i < AccentsMap.length; i++) {
      var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
      var char = AccentsMap[i][0];
      str = str.replace(re, char);
    }
    return str;
  }

  const onDeleteSavedShipment = (hawbValue) => {
    const newData = savedShipment.filter((s) => {
      return s.hawb !== hawbValue;
    });
    setSavedShipment(newData);
    localStorage.setItem('myArray', JSON.stringify(newData));
  };

  const onBookShipment = () => {
    setLoading(true);
    setBookConfirm(false);
    let arr = [];
    if (isSave) {
      onSaveShipment();
    }
    const finalInvoiceValue1 =
      type === 'WPX'
        ? invoiceValue.map((s) => {
            const match = s.itemCommonity.match(/\(([^)]+)\)/);
            if (match) {
              return {
                ...s,
                itemCommonity: s.itemCommonity,
                itemIngredients: match[1],
              };
            } else if (s.itemIngredients) {
              return {
                ...s,
                itemCommonity: `${s.itemCommonity} (made of ${s.itemIngredients})`,
              };
            }
            return s;
          })
        : [];
    finalInvoiceValue1.map((s) => {
      arr.push(s.itemCommonity);
      return s;
    });
    if (doxWeight < 0.5) {
      alert('Your document can not be less than 0.5kg');
      setDoxWeight(0.5);
      setDoxErr(true);
    } else {
      setDoxErr(false);
    }
    if (doxWeight > 2) {
      alert('Your document can not be more than 2kg');
      setDoxWeight(0.5);
      setDoxErr(true);
    } else {
      setDoxErr(false);
    }
    if (doxWeight >= 0.5 && doxWeight <= 2) {
      const updateData = {
        account: '786009',
        pickupScheduled: `${dateInput}T${timeInput}:00`,
        deliverySign: deliverySign || '',
        currency: 'USD',
        service: service || 'SKD',
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        perform: 'New',
        status: 'Pending',
        id: (Math.random() + 1).toString(36).substring(7),
        desc: firebase.firestore.FieldValue.arrayUnion({
          stat: 'Shipment booked, waiting for confirmation by Skydart ',
          time: datePicker + 'T' + dateTime + ':00',
          location: 'VIETNAM - VIETNAM - HCM',
        }),
        controller: user.controller || 'Pending',
        eamFormat: type === 'DOX' ? 'DOX' : 'NDX',
        important: '',
        airline: 'Pending',
        mawb: 'Pending',
        pack: 'Pending',
        etd: 'Pending',
        agent: 'Pending',
        subStatus: 'Pending',
        sin: 'Null',
        note: 'Null',
        perform: 'Null',
        code: code,
        hawb: hawb,
        uid: user.uid,
        makh: user.makh,
        paymentStatus: '',
        phoneCode: phoneCode,
        shipmentType: type,
        fromCountry: user.country,
        exporter: removeAccents(user.exporter),
        fromCity: removeAccents(user.city),
        fromState: removeAccents(user.state),
        fromTown: removeAccents(user.town),
        fromAddress: removeAccents(user.address),
        fromAddress2: removeAccents(user.address2),
        fromAddress3: removeAccents(user.address3),
        value: totalInvoiceValue || '',
        fromPostal: user.postal,
        fromPhone: user.phone,
        fromName: removeAccents(user.displayName),
        toPhone2: '',
        fromEmail: user.email,
        dateTime: new Date(),
        toCountry: country || '',
        importer: importer,
        toCity: removeAccents(city) || '',
        toState: state || '',
        stateLabel: stateLabel || '',
        toTown: removeAccents(town) || '',
        toAddress: removeAccents(address) || '',
        toAddress2: removeAccents(address2) || '',
        toAddress3: removeAccents(address3) || '',
        stateCode: stateCode || '',
        toPostal: postal || '',
        toPhone: phone || '',
        toName: removeAccents(contact) || '',
        toEmail: email || '',
        createAt: datePicker,
        time: dateTime,
        invoiceValue: type === 'DOX' ? 'Pending' : finalInvoiceValue1,
        packageValue: type === 'DOX' ? 'Pending' : packageValue,
        totalCharge: type === 'DOX' ? doxWeight : finalCharge.toFixed(2),
        reweight: type === 'DOX' ? doxWeight : finalCharge.toFixed(2),
        weight: type === 'DOX' ? doxWeight : finalGross.toFixed(2),
        totalPackage: type === 'DOX' ? envelope : packageValue.length,
        totalItem: type === 'DOX' ? envelope : invoiceValue.length,
        eori: eori || '',
        otherTerm: '',
        description: type === 'DOX' ? 'Document' : arr.toString() + ',',
        term: term || '',
        transportation: transport || '',
        totalItemValue: type === 'WPX' ? finalInvoiceValue.toFixed(2).toString() : '0.00',
        totalInvoiceValue: type === 'WPX' ? totalInvoiceValue.toFixed(2).toString() : '0.00',
        usage: reason || 'Gift',
        rate: 0,
        fsc: 0,
        fscValue: '0.00',
        addressCorrection: '0.00',
        direct: '',
        surcharge: '0.00',
        surcharge2: '0.00',
        surcharge3: '0.00',
        remote: '0.00',
        vat: 'No',
        vatValue: '0.00',
        eamService: eamService || '',
        plan: user.plan || 'Pending',
        booked: 'false',
        skydartZone: currentZone || '',
      };
      db.collection('shipments')
        .doc(hawb)
        .set(updateData)
        .then(() => {
          setLoading(false);
          onDeleteSavedShipment(hawb);
          navigate('/shipments');
        })
        .catch((err) => {
          alert('There is unexpected error that has been occur, please try again or contact us for IT support');
          setLoading(false);
        });
    }
  };

  const onSaveShipment1 = () => {
    if (loading) return;
    setLoading(true);
    let arr = [];
    setInvoiceValue(
      invoiceValue.map((s) => {
        arr.push(s.itemCommonity);
        return s;
      })
    );
    const finalInvoiceValue1 = invoiceValue.map((s) => {
      const match = s.itemCommonity.match(/\(([^)]+)\)/);
      if (match) {
        return {
          ...s,
          itemCommonity: s.itemCommonity,
          itemIngredients: match[1],
        };
      } else if (s.itemIngredients) {
        return {
          ...s,
          itemCommonity: `${s.itemCommonity} (made of ${s.itemIngredients})`,
        };
      }
      return s;
    });
    const updateData = {
      account: '786009',
      pickupScheduled: `${dateInput}T${timeInput}:00`,
      deliverySign: deliverySign || '',
      currency: country === 'Singapore' ? 'SGD' : 'USD',
      service: service || 'SKD',
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isNew: true,
      savedDate: new Date(),
      perform: 'Draft',
      status: 'Editing',
      id: (Math.random() + 1).toString(36).substring(7),
      desc: firebase.firestore.FieldValue.arrayUnion({
        stat: 'Shipment booked, waiting for confirmation by Skydart ',
        time: datePicker + 'T' + dateTime + ':00',
        location: 'VIETNAM - VIETNAM - HCM',
      }),
      controller: user.controller || 'Pending',
      eamFormat: type === 'DOX' ? 'DOX' : 'NDX',
      important: '',
      airline: 'Pending',
      mawb: 'Pending',
      pack: 'Pending',
      etd: 'Pending',
      direct: service === 'DUS' ? 'us' : direct,
      agent: 'Pending',
      subStatus: 'Pending',
      sin: 'Null',
      phoneCode: phoneCode,
      note: 'Null',
      code: code,
      hawb,
      uid: user.uid,
      makh: user.makh,
      paymentStatus: '',
      shipmentType: type,
      fromCountry: user.country,
      exporter: user.exporter,
      fromCity: user.city,
      fromState: user.state,
      fromTown: user.town,
      fromAddress: user.address,
      fromAddress2: user.address2,
      fromAddress3: user.address3,
      value: totalInvoiceValue,
      fromPostal: user.postal,
      fromPhone: user.phone,
      fromName: user.displayName,
      toPhone2: '',
      fromEmail: user.email,
      dateTime: new Date(),
      toCountry: country || '',
      importer,
      toCity: city || '',
      toState: state || '',
      toTown: town || '',
      toAddress: address || '',
      toAddress2: address2 || '',
      toAddress3: address3 || '',
      toPostal: postal || '',
      toPhone: phone || '',
      toName: contact || '',
      toEmail: email || '',
      createAt: datePicker,
      time: dateTime,
      invoiceValue: type === 'DOX' ? 'Pending' : finalInvoiceValue1,
      packageValue: type === 'DOX' ? 'Pending' : packageValue,
      totalCharge: type === 'DOX' ? doxWeight : finalCharge.toFixed(2),
      reweight: type === 'DOX' ? doxWeight : finalCharge.toFixed(2),
      weight: type === 'DOX' ? doxWeight : finalGross.toFixed(2),
      totalPackage: type === 'DOX' ? envelope : packageValue.length,
      totalItem: type === 'DOX' ? envelope : invoiceValue.length,
      eori,
      otherTerm: '',
      description: type === 'DOX' ? 'Document' : arr.toString() + ',',
      term,
      transportation: transport,
      totalItemValue: type === 'WPX' ? finalInvoiceValue.toFixed(2).toString() : '0.00',
      totalInvoiceValue: type === 'WPX' ? totalInvoiceValue.toFixed(2).toString() : '0.00',
      usage: reason,
      rate: 0,
      fsc: 0,
      fscValue: '0.00',
      addressCorrection: '0.00',
      surcharge: '0.00',
      surcharge2: '0.00',
      surcharge3: '0.00',
      remote: '0.00',
      vat: 'No',
      vatValue: '0.00',
      eamService: eamService,
      plan: user.plan || 'Pending',
      booked: 'false',
    };
    db.collection('shipments')
      .doc(hawb)
      .set(updateData)
      .then(() => {
        setLoading(false);
        setSaveConfirm(true);
      });
  };

  const onSaveShipment = async () => {
    const finalInvoiceValue1 = invoiceValue.map((s) => {
      const match = s.itemCommonity.match(/\(([^)]+)\)/);
      if (match) {
        return {
          ...s,
          itemCommonity: s.itemCommonity,
          itemIngredients: match[1],
        };
      } else if (s.itemIngredients) {
        return {
          ...s,
          itemCommonity: `${s.itemCommonity} (made of ${s.itemIngredients})`,
        };
      }
      return s;
    });
    let arr = [];
    setInvoiceValue(
      finalInvoiceValue1.map((s) => {
        arr.push(s.itemCommonity);
        return s;
      })
    );
    const updateData = {
      account: '786009',
      pickupScheduled: `${dateInput}T${timeInput}:00`,
      deliverySign: deliverySign || '',
      currency: country === 'Singapore' ? 'SGD' : 'USD',
      service: service || 'SKD',
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isNew: true,
      savedDate: new Date(),
      perform: 'Draft',
      status: 'Editing',
      id: (Math.random() + 1).toString(36).substring(7),
      desc: firebase.firestore.FieldValue.arrayUnion({
        stat: 'Shipment booked, waiting for confirmation by Skydart ',
        time: datePicker + 'T' + dateTime + ':00',
        location: 'VIETNAM - VIETNAM - HCM',
      }),
      controller: user.controller || 'Pending',
      eamFormat: type === 'DOX' ? 'DOX' : 'NDX',
      important: '',
      airline: 'Pending',
      mawb: 'Pending',
      pack: 'Pending',
      etd: 'Pending',
      direct: service === 'DUS' ? 'us' : direct,
      agent: 'Pending',
      subStatus: 'Pending',
      sin: 'Null',
      phoneCode: phoneCode,
      note: 'Null',
      code: code,
      hawb,
      uid: user.uid,
      makh: user.makh,
      paymentStatus: '',
      shipmentType: type,
      fromCountry: user.country,
      exporter: user.exporter,
      fromCity: user.city,
      fromState: user.state,
      fromTown: user.town,
      fromAddress: user.address,
      fromAddress2: user.address2,
      fromAddress3: user.address3,
      value: totalInvoiceValue,
      fromPostal: user.postal,
      fromPhone: user.phone,
      fromName: user.displayName,
      toPhone2: '',
      fromEmail: user.email,
      dateTime: new Date(),
      toCountry: country || '',
      importer,
      toCity: city || '',
      toState: state || '',
      toTown: town || '',
      toAddress: address || '',
      toAddress2: address2 || '',
      toAddress3: address3 || '',
      toPostal: postal || '',
      toPhone: phone || '',
      toName: contact || '',
      toEmail: email || '',
      createAt: datePicker,
      time: dateTime,
      invoiceValue: type === 'DOX' ? 'Pending' : finalInvoiceValue1,
      packageValue: type === 'DOX' ? 'Pending' : packageValue,
      totalCharge: type === 'DOX' ? doxWeight : finalCharge.toFixed(2),
      reweight: type === 'DOX' ? doxWeight : finalCharge.toFixed(2),
      weight: type === 'DOX' ? doxWeight : finalGross.toFixed(2),
      totalPackage: type === 'DOX' ? envelope : packageValue.length,
      totalItem: type === 'DOX' ? envelope : invoiceValue.length,
      eori,
      otherTerm: '',
      description: type === 'DOX' ? 'Document' : arr.toString() + ',',
      term,
      transportation: transport,
      totalItemValue: type === 'WPX' ? finalInvoiceValue.toFixed(2).toString() : '0.00',
      totalInvoiceValue: type === 'WPX' ? totalInvoiceValue.toFixed(2).toString() : '0.00',
      usage: reason,
      rate: 0,
      fsc: 0,
      fscValue: '0.00',
      addressCorrection: '0.00',
      surcharge: '0.00',
      surcharge2: '0.00',
      surcharge3: '0.00',
      remote: '0.00',
      vat: 'No',
      vatValue: '0.00',
      eamService: eamService,
      plan: user.plan || 'Pending',
      booked: 'false',
    };
    if (doxWeight < 0.5) {
      alert('Your document can not be less than 0.5kg');
      setDoxWeight(0.5);
      setDoxErr(true);
    } else {
      setDoxErr(false);
    }
    if (doxWeight > 2) {
      alert('Your document can not be more than 2kg');
      setDoxWeight(0.5);
      setDoxErr(true);
    } else {
      setDoxErr(false);
    }
    if (doxWeight >= 0.5 && doxWeight <= 2) {
      const newData = [updateData, ...savedShipment];
      setSavedShipment(newData);
      localStorage.setItem('myArray', JSON.stringify(newData));
    }
  };

  let handleChange = (i, e) => {
    let newFormValues = [...packageValue];
    newFormValues[i][e.target.name] = e.target.value;
    setPackageValue(newFormValues);
  };
  let invoiceChange = (i, e) => {
    const { name, value } = e.target;
    if (name === 'itemUnitPrice') {
      const newValue = value.replace(',', '.'); // Replace comma with dot for decimal input

      const decimalParts = newValue.split('.'); // Split the input into parts separated by the decimal point

      if (decimalParts.length > 1 && decimalParts[1].length > 2) {
        // Check if the input has more than two decimal places
        return;
      }

      // Update the invoiceValue state with the modified value
      setInvoiceValue((prevState) => {
        const updatedInvoiceValue = prevState.map((item, index) => {
          if (index === i) {
            return { ...item, [name]: newValue };
          }
          return item;
        });
        return updatedInvoiceValue;
      });
    } else if (name === 'itemUnit') {
      setInvoiceValue((prevState) => {
        const updatedInvoiceValue = prevState.map((item, index) => {
          if (index === i) {
            return {
              ...item,
              [name]: value,
              itemOtherUnit:
                value === 'PCS'
                  ? 'Chiếc'
                  : value === 'BOX'
                    ? 'Hộp'
                    : value === 'KG'
                      ? 'KG'
                      : value === 'PRS'
                        ? 'Đôi'
                        : value === 'SET'
                          ? 'Bộ'
                          : '',
            };
          }
          return item;
        });
        return updatedInvoiceValue;
      });
    } else {
      let newInvoiceValues = [...invoiceValue];
      newInvoiceValues[i][e.target.name] = e.target.value;
      setInvoiceValue(newInvoiceValues);
    }
  };
  const processTracking = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    setShipmentList(list);
  };
  // const handleUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     ExcelRenderer(file, (err, resp) => {
  //       if (err) {
  //         console.log(err);
  //       }
  //       else {
  //         setShipmentList(resp.rows);
  //       }
  //     });
  //   } else {
  //     return;
  //   }
  // }
  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        processTracking(data);
      };
      reader.readAsBinaryString(file);
    } else {
      return;
    }
  };
  const bookingHeaders = [
    { label: 'service', key: '' },
    { label: 'importer', key: '' },
    { label: 'toName', key: '' },
    { label: 'toAddress', key: '' },
    { label: 'toAddress2', key: '' },
    { label: 'toAddress3', key: '' },
    { label: 'toTown', key: '' },
    { label: 'toCity', key: '' },
    { label: 'toCountry', key: '' },
    { label: 'toPostal', key: '' },
    { label: 'toPhone', key: '' },
    { label: 'amount', key: '' },
    { label: 'chargeable', key: '' },
    { label: 'shipmentType', key: '' },
    { label: 'toEmail', key: '' },
    { label: 'reason', key: '' },
    { label: 'tax_vat_id', key: '' },
    { label: '', key: 'slash' },
    { label: 'item number', key: '' },
    { label: 'qty', key: '' },
    { label: 'Qty unit', key: '' },
    { label: 'Item description', key: '' },
    { label: 'Unit Price', key: '' },
    { label: 'net weight', key: '' },
    { label: 'gross weight', key: '' },
    { label: 'country manufactured', key: '' },
    { label: 'hs code', key: '' },
  ];
  const exportFormat = {
    data: [],
    headers: bookingHeaders,
    filename: `BookingTemplate_skd`,
  };

  const fscValue = (rate * fsc) / 100;
  const fscIncluded = rate + fscValue;
  const doxFscValue = (doxRate * fsc) / 100;
  const doxFscIncluded = doxRate + doxFscValue;
  const rateValue = rate;
  const getRate = (e) => {
    e.preventDefault();
    setBookConfirm(true);
  };
  // const getRate = (e) => {
  //   e.preventDefault();
  //   setBookConfirm(true);

  // }

  const packaging = packageValue.map((element) => {
    let dimCharge = 0;
    dimCharge = (element.packageWidth * element.packageHeight * element.packageLength) / 5000;

    if (dimCharge < element.packageWeight) {
      element.packageCharge = element.packageWeight;
    }
    if (element.packageWeight < dimCharge) {
      element.packageCharge = dimCharge;
    }
    if (element.packageCharge >= 30) {
      element.packageCharge = Math.ceil(element.packageCharge);
    }
    if (element.packageCharge < 30) {
      element.packageCharge = Math.ceil(element.packageCharge / 0.5) * 0.5;
    }
    if (element.packageWeight === '' || 0) {
      element.packageCharge = 0;
    }
    return {
      packageLength: element.packageLength,
      packageWidth: element.packageWidth,
      packageHeight: element.packageHeight,
      packageWeight: element.packageWeight,
      packageCharge: element.packageCharge,
      packNo: element.packNo,
    };
  });

  const finalCharge = packaging.reduce((a, v) => (a = +a + +v.packageCharge), 0);
  const finalGross = packaging.reduce((a, v) => (a = +a + +v.packageWeight), 0);
  const filterEnglishOnly = (value) => {
    return value.replace(/[^a-zA-Z\s]/g, ''); // Allows only English letters and spaces
  };

  const serviceArray = [
    { label: 'SKD', proxy: 'SKD', board: 'abc', fsc: skdFsc },
    { label: 'DHL', proxy: 'SDH', board: 'dhl', eam: 'INT', fsc: dhlFsc },
    { label: 'D3P', proxy: 'D3P', board: 'abc', fsc: dhlFsc },
    { label: 'OCS', proxy: 'OCS', board: 'abc', fsc: '' },
    { label: 'FIP', proxy: 'FIP', board: 'fedex', fsc: fedexFsc },
    { label: 'FIE', proxy: 'FIE', board: 'fie', fsc: fedexFsc },
    { label: 'F3P', proxy: 'F3P', board: 'f3p', fsc: fedexFsc },
    { label: 'UPS', proxy: 'SUPS', board: 'ups', fsc: upsFsc },
    { label: 'DHL-VN', proxy: 'VDH', board: 'abc', fsc: '' },
    { label: 'Fedex-VN', proxy: 'VIP', board: 'abc', fsc: '' },
    { label: 'UPS-VN', proxy: 'VUPS', board: 'abc', fsc: '' },
    { label: 'DAU', proxy: 'DAU', board: 'abc', fsc: '' },
    { label: 'DEU', proxy: 'DEU', board: 'abc', fsc: '' },
    { label: 'FIP-DXB', proxy: 'FIP-DXB', board: 'abc', fsc: '' },
    { label: 'UPS-DXB', proxy: 'UPS-DXB', board: 'abc', fsc: '' },
    { label: 'DHL-DXB', proxy: 'DHL-DXB', board: 'abc', fsc: '' },
    { label: 'DUS', proxy: 'DUS', board: 'dus', fsc: '' },
  ];

  return (
    <div className="skydart-bookShipment w-full h-full overflow-auto">
      {displaySaveList && (
        <div className="skydart-shipment-booked z-[9999]">
          <OutsideClickHandler
            onOutsideClick={() => {
              setDisplaySaveList(false);
            }}
          >
            <div className="fixed right-0 top-0 h-[100vh] bg-white w-[430px] new-save-list overflow-auto">
              <div className="p-2.5 flex items-center shadow-[rgba(0,0,0,0.1)_2.4px_2.4px_3.2px] bg-white sticky z-10 top-0">
                <div>
                  <p className="text-[21px] font-semibold tracking-wide">Saved shipment list</p>
                </div>
                <div
                  className="w-[40px] h-[40px]  flex items-center justify-center ml-auto cursor-pointer rounded-full hover:bg-slate-100 text-slate-500 active:bg-slate-200"
                  onClick={() => {
                    setDisplaySaveList(false);
                  }}
                >
                  <MdOutlineClear size={25} />
                </div>
              </div>
              <div className="p-2.5 flex items-center border-b-[1px] border-t-0 border-x-0 border-solid border-slate-400">
                <div className="relative flex items-center">
                  <GoSearch size={23} className="absolute left-[9px]" />
                  <input
                    type="text"
                    placeholder="Search..."
                    className="p-1.5 bg-slate-200 rounded-full px-[35px] w-[270px] border-slate-300 border-solid border-[1px] focus:bg-white search-column-input  outline-none"
                  />
                  <MdOutlineClear size={23} className="absolute right-[10px]" />
                </div>
                <div className="flex items-center cursor-pointer hover:underline active:opacity-50 text-[darkblue] select-none ml-auto">
                  <CiCircleQuestion size={25} />
                  <p className="ml-1">Learn more</p>
                </div>
              </div>
              <div className="p-2.5">
                <p className="text-[darkblue] font-semibold text-[15px] mb-1">
                  Prepared shipments that are waiting to be book
                </p>
                {savedShipment?.length === 0 && (
                  <div>
                    <p>There is no shipment that has been saved</p>
                    <div
                      className="p-2 bg-slate-200 px-4 mt-1.5 w-fit rounded-[6px] select-none font-medium hover:bg-slate-300 active:bg-slate-400 transition-[98s] cursor-pointer"
                      onClick={() => {
                        setDisplaySaveList(false);
                      }}
                    >
                      <p>Create shipment</p>
                    </div>
                  </div>
                )}
                {savedShipment?.map((item, index) => (
                  <div
                    className={`p-2.5 bg-white rounded-[6px] border-slate-400 border-solid border-[1px] border-l-[15px] mb-2.5 ${
                      item.isNew ? ' border-l-emerald-500' : 'border-l-slate-200'
                    }`}
                  >
                    <div className="p-1 flex items-center text-sky-900">
                      <FiPackage size={23} />
                      <p className=" font-bold ml-2">{item.shipmentType}</p>
                      <p className=" font-bold ml-1">{item.totalCharge}kg</p>
                      <FaFlag size={23} className="text-amber-500 ml-auto" />
                    </div>
                    <div className="p-1 flex items-center text-slate-500">
                      <SiNextbilliondotai size={23} />
                      <p className=" font-bold ml-2">
                        {item.hawb} | {item.packageValue?.length}pcs | {item.totalInvoiceValue} USD
                      </p>
                    </div>
                    <div className="p-1 mx-5">
                      <div className="flex items-start gap-2.5">
                        <p className="font-medium text-sky-900 w-[63px] text-center">Dec 25</p>
                        <div className="relative mx-2">
                          <div className="w-[14px] h-[14px] bg-sky-900 rounded-full absolute top-0 left-[-6px]"></div>
                          <div className="h-[35px] w-[2px] bg-sky-900 "></div>
                        </div>
                        <img src={`https://flagsapi.com/VN/shiny/64.png`} className="w-[25px] h-[25px] " />
                        <div className="text-[15px] font-semibold">
                          <p className="text-sky-900">{item.fromCountry}</p>
                          <p className="text-slate-500">{item.fromCity}</p>
                        </div>
                      </div>
                      <div className="flex items-start gap-2.5">
                        <p className="font-medium text-sky-900 w-[63px] text-center">Pending</p>
                        <div className="relative mx-2 mt-[-10px]">
                          <div className="w-[14px] h-[14px] bg-slate-300 rounded-full absolute bottom-0 left-[-6px]"></div>
                          <div className="h-[35px] w-[2px] bg-slate-300 "></div>
                        </div>
                        <img
                          src={`https://flagsapi.com/${item.code.toUpperCase()}/shiny/64.png`}
                          className="w-[25px] h-[25px] "
                        />
                        <div className="text-[15px] font-semibold">
                          <p className="text-sky-900">{item.toCountry}</p>
                          <p className="text-slate-500 overflow-hidden whitespace-nowrap text-ellipsis w-[170px]">
                            {item.toTown}, {item.toState}, {item.toCity}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="p-1 flex items-start text-black">
                      <FaRegUser size={35} className="p-2 bg-slate-200 rounded-full mt-1" />
                      <div className="ml-2">
                        <p className=" font-bold  text-[22px]">{item.toName}</p>
                        <p className="text-slate-600 text-[15px] leading-3">{item.importer}</p>
                      </div>
                    </div>
                    <div className="p-1 flex items-center text-black">
                      <GrLocation size={23} />
                      <p className=" font-bold ml-2">{item.toAddress}</p>
                    </div>
                    <div className="p-1 flex items-center text-black">
                      <LiaCitySolid size={23} />
                      <p className=" font-bold ml-2">
                        {item.toState}, {item.toTown}, {item.toCity}
                      </p>
                    </div>
                    {item.toAddress2 && (
                      <div className="p-1 flex items-center text-slate-600">
                        <MdOutlineAddLocationAlt size={23} />
                        <p className=" ml-2">{item.toAddress2}</p>
                      </div>
                    )}
                    {item.toAddress3 && (
                      <div className="p-1 flex items-center text-slate-600">
                        <MdOutlineAddLocationAlt size={23} />
                        <p className=" ml-2">{item.toAddress3}</p>
                      </div>
                    )}
                    <div className="p-1 flex items-center text-black">
                      <FiPhone size={23} className="p-[1px]" />
                      <p className=" font-bold ml-2">{item.toPhone}</p>
                    </div>
                    <div className="p-1 flex items-center text-black">
                      <MdOutlineEmail size={23} />
                      <p className=" font-bold ml-2">{item.toEmail}</p>
                    </div>
                    <div className="p-2 bg-white border-slate-400 rounded-[6px] border-solid border-[1px] my-1.5">
                      <div className="flex items-center">
                        <p
                          className="text-[12px] 
                      mx-1 text-slate-600"
                        >
                          Dimensions:
                        </p>
                        <div className="ml-auto bg-slate-200 px-2.5 rounded-[3px]">
                          <p>{item.service}</p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        {item.packageValue?.map((item7) => (
                          <div className="bg-slate-200 p-2 rounded-[5px] px-2.5">
                            {item7.packageLength}cm x {item7.packageWidth}cm x {item7.packageHeight}cm
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="p-2  text-black bg-slate-200 rounded-[6px] mt-2">
                      <p
                        className="text-[12px] 
                      mx-1 text-slate-600"
                      >
                        Description of goods
                      </p>
                      <p className="mx-1 text-[17px]">{item.description}</p>
                    </div>
                    <div className="flex items-center mt-2 gap-1.5">
                      <div
                        className="w-full p-2 bg-slate-200 text-slate-600 tracking-wide transition-[98s] flex items-center justify-center font-semibold gap-1 rounded-[6px] select-none hover:bg-slate-300 active:bg-slate-400 cursor-pointer"
                        onClick={() => {
                          onDeleteSavedShipment(item.hawb);
                        }}
                      >
                        <p>Cancel shipment</p>
                      </div>
                      <div
                        className="w-full p-2 bg-[darkblue] text-white tracking-wide transition-[98s] flex items-center justify-center font-semibold gap-1 rounded-[6px] select-none hover:opacity-70 active:opacity-50 cursor-pointer"
                        onClick={() => {
                          setType(item.shipmentType);
                          setHawb(item.hawb);
                          setService(item.service);
                          setReason(item.usage);
                          setCountryInput(item.toCountry);
                          setCompanyInput(item.importer);
                          setAddressInput(item.toAddress);
                          setAddressInput2(item.toAddress2);
                          setAddressInput3(item.toAddress3);
                          setTownInput(item.toTown);
                          setStateInput(item.toState);
                          setPostalInput(item.toPostal);
                          setPhoneInput(item.toPhone);
                          setContactInput(item.toName);
                          setEmailInput(item.toEmail);
                          setPackageValue(item.packageValue);
                          setInvoiceValue(item.invoiceValue);
                          setDisplaySaveList(false);
                        }}
                      >
                        <IoCreateOutline size={25} />
                        <p>Edit shipment</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      )}
      {displayAttach && (
        <div className="skydart-bookShipment-attach">
          <div className="skydart-bookShipment-attach-container">
            <div className="skydart-bookShipment-attach-header">
              <h3>Upload shipment file</h3>
              <div
                className="skydart-bookShipment-attach-clear"
                onClick={() => {
                  setDisplayAttach(false);
                }}
              >
                <MdClear />
              </div>
            </div>
            <div className="skydart-bookShipment-attach-body">
              <div className="skydart-bookShipment-attach-body-content">
                <h5>Select an Excel file to upload multiple shipments</h5>
              </div>
              <div className="skydart-bookShipment-attach-body-selection">
                <input type="file" id="book-upload" onChange={handleUpload} />
                <label className="uploading-book" for="book-upload">
                  <GrDocumentText style={{ marginRight: 5 }} size={16} />
                  Upload file
                </label>
                <CSVLink {...exportFormat} className="uploading-book">
                  <BsUpload style={{ marginRight: 5 }} size={16} />
                  Download format
                </CSVLink>
              </div>
            </div>
            <div className="skydart-bookShipment-attach-action">
              <h5>VU Nguyen</h5>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="skydart-bookShipment-loading">
          <OutsideClickHandler
            onOutsideClick={() => {
              setLoading(false);
            }}
          >
            <div className="skydart-bookShipment-loading-container">
              <CircularProgress />
              <p style={{ marginTop: 15, marginLeft: 10 }}>Loading...</p>
            </div>
          </OutsideClickHandler>
        </div>
      )}
      {saveConfirm && (
        <div className="skydart-bookShipment-loading">
          <OutsideClickHandler
            onOutsideClick={() => {
              setSaveConfirm(false);
            }}
          >
            <div className="skydart-bookShipment-loading-container1">
              <p style={{ padding: 15, fontSize: 15, fontWeight: 600, textAlign: 'center' }} className="leading-5">
                Shipment booking confirmatin
              </p>
              <div className="skydart-bookShipment-save-confirm-option">
                <button
                  type="button"
                  className="skydart-bookShipment-save-confirm-cancel"
                  onClick={() => {
                    navigate('/drafts');
                  }}
                >
                  Manage draft shipment
                </button>
                <button
                  type="button"
                  className="skydart-bookShipment-save-confirm-book-another"
                  onClick={() => window.location.reload()}
                >
                  Book another shipment
                </button>
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      )}
      <div ref={bookConfirm ? null : bookingRef}></div>
      {bookConfirm && (
        <div className="skydart-bookShipment-loading">
          <div className="skydart-bookShipment-loading-container1" ref={bookConfirm ? bookingRef : null}>
            <div style={{ display: 'flex', padding: 10, alignItems: 'center', flexDirection: 'column' }}>
              <FcCheckmark size={45} className="p-1 bg-emerald-50 rounded-full" />
              <p
                style={{ padding: 15, paddingBottom: 0, fontSize: 25, fontWeight: 600, textAlign: 'center' }}
                className="tracking-wide leading-7"
              >
                Shipment booking confirmation
              </p>
              <p className="text-[14px] text-slate-600">Are you sure with all of your shipping information ?</p>
            </div>
            <div className="skydart-bookShipment-save-confirm-option">
              <button
                type="button"
                className="p-1.5 bg-rose-50 text-rose-600 px-3.5 rounded-[7px] hover:bg-rose-100 active:bg-rose-200 font-semibold"
                onClick={() => setBookConfirm(false)}
              >
                Not sure, cancel
              </button>
              <button
                type="button"
                className="p-1.5 bg-emerald-50 text-emerald-600 px-3.5 rounded-[7px] hover:bg-emerald-100 active:bg-emerald-200 font-semibold ml-1.5"
                onClick={onBookShipment}
              >
                Book shipment
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="skydart-bookShipment-header flex items-center">
        <p className="font-semibold tracking-wide text-xl">Create shipment</p>
        <div className="ml-auto flex items-center gap-1.5 bg-white px-2 rounded-[10px] py-1">
          <div className="flex items-center font-medium">
            <Switch checked={displayBill} onChange={(e) => setDisplayBill(e.target.checked)} />
            <p className="tracking-wide">Show bill</p>
          </div>
          <div className="flex items-center font-medium">
            <Switch checked={isSave} onChange={(e) => setIsSave(e.target.checked)} />
            <p className="tracking-wide">Rebook this shipment</p>
          </div>
          <div
            className="p-1.5 bg-slate-200 rounded-[8px] font-semibold select-none transition-[98s] hover:bg-slate-300 active:bg-slate-400 cursor-pointer px-4 ml-1 text-slate-600 hover:text-black"
            onClick={() => {
              setDisplaySaveList(!displaySaveList);
            }}
          >
            <p>Rebook list</p>
          </div>
        </div>
        {/* <div className="ml-auto">
          <p>VU Nguyen</p>
        </div> */}
        {/* {type === "WPX" && <p>Estimate shipping cost (NET): ${rate?.toFixed(2) || "UNDEFINED"}</p>}
        {type === "WPX" && <p>Estimate shipping cost (FSC included): ${fscIncluded?.toFixed(2)}</p>}
        {type === "DOX" && <p>Estimate shipping cost (NET): ${doxRate?.toFixed(2) || "UNDEFINED"}</p>}
        {type === "DOX" && <p>Estimate shipping cost (FSC included): ${doxFscIncluded?.toFixed(2)}</p>} */}
      </div>
      <form className="skydart-bookShipment-container" onSubmit={getRate}>
        <div className={displayBill ? 'w-[500px]' : 'w-[calc(100vw_-_21.5rem)]'}>
          <div className="skydart-bookShipment-wrap">
            <div className="skydart-bookShipment-left-header">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h5>Shipment type</h5>
              </div>
              <div style={{ position: 'relative' }} ref={typeRef}>
                <div className="skydart-bookShipment-select-type" onClick={handleType}>
                  {type === 'WPX' && (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
                      <BsBoxSeam size={21} />
                      <h3>Worldwide Package Express (WPX)</h3>
                    </div>
                  )}
                  {type === 'DOX' && (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
                      <GrDocument size={21} />
                      <h3>Document (DOX)</h3>
                    </div>
                  )}
                  <FaSortDown size={25} className="mt-[-10px]" />
                </div>
                {displayType && (
                  <div className="skydart-bookShipment-select-type-container">
                    <p style={{ marginLeft: 8, fontSize: 12, fontWeight: 600, color: 'gray' }}>
                      Select a suitable shipping type for your shipment.
                    </p>
                    <div
                      onClick={handleWPX}
                      className={
                        type === 'WPX'
                          ? 'skydart-bookShipment-select-type-option'
                          : 'skydart-bookShipment-select-type-option1'
                      }
                    >
                      <div
                        className="skd-user-shipment-last-header"
                        style={{ padding: 0, border: 'none', marginRight: 12 }}
                      >
                        <input type="checkbox" checked={type === 'WPX' ? true : false} />
                      </div>
                      <BsBoxSeam size={18} />
                      <h3>Worldwide Package Express (WPX)</h3>
                    </div>
                    <div
                      onClick={handleDOX}
                      className={
                        type === 'DOX'
                          ? 'skydart-bookShipment-select-type-option'
                          : 'skydart-bookShipment-select-type-option1'
                      }
                    >
                      <div
                        className="skd-user-shipment-last-header"
                        style={{ padding: 0, border: 'none', marginRight: 12 }}
                      >
                        <input type="checkbox" checked={type === 'DOX' ? true : false} />
                      </div>
                      <GrDocument size={18} />
                      <h3>Document (DOX)</h3>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="skydart-bookShipment-service">
              <h5>Services</h5>
              <div style={{ position: 'relative' }} ref={serviceRef}>
                <div className="skydart-bookShipment-select-type" onClick={handleService}>
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
                    <GiCommercialAirplane size={21} />
                    <h3>{proxyService || 'Select services'}</h3>
                  </div>
                  <FaSortDown size={25} className="mt-[-10px]" />
                </div>
                {service === 'DAU' && (
                  <p style={{ padding: 5, fontSize: 12 }}>
                    Dimension calculation for <strong style={{ textDecoration: 'underline' }}>DAU</strong>: L x W x H /
                    6000
                  </p>
                )}
                {service === 'DUS' && (
                  <p style={{ padding: 5, fontSize: 12 }}>
                    Dimension calculation for <strong style={{ textDecoration: 'underline' }}>DUS</strong>: L x W x H /
                    6000
                  </p>
                )}
                {displayService && (
                  <div
                    className="skydart-bookShipment-select-type-container"
                    style={{ height: '25vh', overflowY: 'scroll' }}
                  >
                    <p style={{ marginLeft: 8, fontSize: 12, fontWeight: 600, color: 'gray' }}>Services:</p>
                    {serviceArray.map((item) => (
                      <div
                        className="skydart-bookShipment-select-type-option1"
                        onClick={() => {
                          if (item.label === 'DHL') {
                            setService('DHL');
                            setProxyService('SDH');
                            setDisplayService(false);
                            setDirect('');
                            setEamService('INT');
                            setFsc(dhlFsc);
                            setBoard('dhl');
                          } else {
                            setService(item.label);
                            setProxyService(item.proxy);
                            setDisplayService(false);
                            setDirect('');
                            setFsc(item.fsc);
                            setBoard(item.board);
                          }
                        }}
                      >
                        <div
                          className="skd-user-shipment-last-header"
                          style={{ padding: 0, border: 'none', marginRight: 12 }}
                        >
                          <input type="checkbox" checked={service === item.label ? true : false} />
                        </div>
                        <h3>{item.proxy}</h3>
                      </div>
                    ))}
                    <div
                      className="skydart-bookShipment-select-type-option1"
                      onClick={() => {
                        setService('DHL');
                        setProxyService('SDH');
                        setDisplayService(false);
                        setDirect('');
                        setEamService('INT');
                        setFsc(dhlFsc);
                        setBoard('dhl');
                      }}
                    >
                      <div
                        className="skd-user-shipment-last-header"
                        style={{ padding: 0, border: 'none', marginRight: 12 }}
                      >
                        <input type="checkbox" checked={service === 'DHL' ? true : false} />
                      </div>
                      <h3>SDH</h3>
                    </div>
                    <div
                      className="skydart-bookShipment-select-type-option1"
                      onClick={() => {
                        setService('FIP');
                        setFsc(fedexFsc);
                        setDisplayService(false);
                        setProxyService('SIP');
                        setDirect('');
                        setEamService('FIP');
                        setBoard('fedex');
                      }}
                    >
                      <div
                        className="skd-user-shipment-last-header"
                        style={{ padding: 0, border: 'none', marginRight: 12 }}
                      >
                        <input type="checkbox" checked={service === 'FIP' ? true : false} />
                      </div>
                      <h3>SIP</h3>
                    </div>
                    <div
                      className="skydart-bookShipment-select-type-option1"
                      onClick={() => {
                        setService('UPS');
                        setProxyService('SUPS');
                        setDirect('');
                        setRate(0);
                        setDoxRate(0);
                        setFsc(upsFsc);
                        setDisplayService(false);
                        setBoard('ups');
                      }}
                    >
                      <div
                        className="skd-user-shipment-last-header"
                        style={{ padding: 0, border: 'none', marginRight: 12 }}
                      >
                        <input type="checkbox" checked={service === 'UPS' ? true : false} />
                      </div>
                      <h3>SUP</h3>
                    </div>
                    <div
                      className="skydart-bookShipment-select-type-option1"
                      onClick={() => {
                        setService('DHL-VN');
                        setProxyService('VDH');
                        setDisplayService(false);
                        setDirect('');
                        setFsc(0);
                        setBoard('abc');
                      }}
                    >
                      <div
                        className="skd-user-shipment-last-header"
                        style={{ padding: 0, border: 'none', marginRight: 12 }}
                      >
                        <input type="checkbox" checked={service === 'DHL-VN' ? true : false} />
                      </div>
                      <h3>VDH</h3>
                    </div>
                    <div
                      className="skydart-bookShipment-select-type-option1"
                      onClick={() => {
                        setService('FIE');
                        setProxyService('FIE');
                        setDisplayService(false);
                        setDirect('');
                        setFsc(0);
                        setBoard('abc');
                      }}
                    >
                      <div
                        className="skd-user-shipment-last-header"
                        style={{ padding: 0, border: 'none', marginRight: 12 }}
                      >
                        <input type="checkbox" checked={service === 'FIE' ? true : false} />
                      </div>
                      <h3>FIE</h3>
                    </div>
                    <div
                      className="skydart-bookShipment-select-type-option1"
                      onClick={() => {
                        setService('Fedex-VN');
                        setProxyService('VIP');
                        setDirect('');
                        setFsc(0);
                        setBoard('abc');
                        setDisplayService(false);
                      }}
                    >
                      <div
                        className="skd-user-shipment-last-header"
                        style={{ padding: 0, border: 'none', marginRight: 12 }}
                      >
                        <input type="checkbox" checked={service === 'Fedex-VN' ? true : false} />
                      </div>
                      <h3>VIP</h3>
                    </div>
                    <div
                      className="skydart-bookShipment-select-type-option1"
                      onClick={() => {
                        setService('UPS-VN');
                        setProxyService('VUPS');
                        setFsc(0);
                        setDisplayService(false);
                        setDirect('');
                        setBoard('abc');
                      }}
                    >
                      <div
                        className="skd-user-shipment-last-header"
                        style={{ padding: 0, border: 'none', marginRight: 12 }}
                      >
                        <input type="checkbox" checked={service === 'UPS-VN' ? true : false} />
                      </div>
                      <h3>VUPS</h3>
                    </div>
                    <div
                      className="skydart-bookShipment-select-type-option1"
                      onClick={() => {
                        setService('DAU');
                        setDisplayService(false);
                        setProxyService('DAU');
                        setFsc(0);
                        setDirect('');
                        setBoard('abc');
                      }}
                    >
                      <div
                        className="skd-user-shipment-last-header"
                        style={{ padding: 0, border: 'none', marginRight: 12 }}
                      >
                        <input type="checkbox" checked={service === 'DAU' ? true : false} />
                      </div>
                      <h3>DAU</h3>
                    </div>
                    <div
                      className="skydart-bookShipment-select-type-option1"
                      onClick={() => {
                        setService('DEU');
                        setProxyService('DEU');
                        setDisplayService(false);
                        setDirect('');
                        setEamService('DEU');
                        setFsc(0);
                        setBoard('abc');
                      }}
                    >
                      <div
                        className="skd-user-shipment-last-header"
                        style={{ padding: 0, border: 'none', marginRight: 12 }}
                      >
                        <input type="checkbox" checked={service === 'DHL' ? true : false} />
                      </div>
                      <h3>DEU</h3>
                    </div>
                    <div
                      className="skydart-bookShipment-select-type-option1"
                      onClick={() => {
                        setService('FIP-DXB');
                        setProxyService('FIP-DXB');
                        setDisplayService(false);
                        setDirect('');
                        setFsc(0);
                        setBoard('abc');
                      }}
                    >
                      <div
                        className="skd-user-shipment-last-header"
                        style={{ padding: 0, border: 'none', marginRight: 12 }}
                      >
                        <input type="checkbox" checked={service === 'FIP-DXB' ? true : false} />
                      </div>
                      <h3>FIP-DXB</h3>
                    </div>
                    <div
                      className="skydart-bookShipment-select-type-option1"
                      onClick={() => {
                        setService('UPS-DXB');
                        setProxyService('UPS-DXB');
                        setDisplayService(false);
                        setDirect('');
                        setFsc(0);
                        setBoard('abc');
                      }}
                    >
                      <div
                        className="skd-user-shipment-last-header"
                        style={{ padding: 0, border: 'none', marginRight: 12 }}
                      >
                        <input type="checkbox" checked={service === 'UPS-DXB' ? true : false} />
                      </div>
                      <h3>UPS-DXB</h3>
                    </div>

                    {/* <div className="skydart-bookShipment-select-type-option1" onClick={() => {
                    setService("SEC");
                    setProxyService("SEC");
                    setDisplayService(false);
                    setDirect("");
                    setFsc(0);
                    setBoard("abc");
                  }}>
                    <div className="skd-user-shipment-last-header" style={{ padding: 0, border: "none", marginRight: 12 }}>
                      <input type="checkbox" checked={service === "SEC" ? true : false} />
                    </div>
                    <h3>SEC</h3>
                  </div> */}
                    <div
                      className="skydart-bookShipment-select-type-option1"
                      onClick={() => {
                        setService('DUS');
                        setProxyService('DUS');
                        setDisplayService(false);
                        setCountryInput('United States');
                        setDirect('us');
                        setFsc(0);
                        setCode('US');
                        setBoard('dus');
                      }}
                    >
                      <div
                        className="skd-user-shipment-last-header"
                        style={{ padding: 0, border: 'none', marginRight: 12 }}
                      >
                        <input type="checkbox" checked={service === 'DUS' ? true : false} />
                      </div>
                      <h3>DUS</h3>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="skydart-bookShipment-service">
              <h5>Recipient sign after delievery</h5>
              <p className="text-[13px] text-slate-500 tracking-wide p-1 px-[5px]">
                Note: There are fee that will be charge if you select this service
              </p>
              <div
                style={{ position: 'relative' }}
                className="flex p-1.5 rounded-[10px] mt-1.5 hover:bg-slate-100 active:bg-slate-200 cursor-pointer"
                onClick={() => setDeliverySign(!deliverySign)}
              >
                <div className="w-[25px] h-[25px] rounded-full border-slate-300 border-solid border-[1px] flex items-center justify-center bg-white">
                  {deliverySign && <div className="w-[15px] h-[15px] rounded-full bg-sky-600"></div>}
                </div>
                <p className="ml-2 select-none">I want recipient to sign when they receive the goods</p>
              </div>
            </div> */}
            {service === 'D3P' && (
              <div className="skydart-bookShipment-service">
                <h5>Transfer & pick up scheduled</h5>
                <p className="text-[13px] text-slate-500 tracking-wide p-1 px-[5px]">
                  Service you are choosing require you to provide the time when we will receive your packages. Please
                  provide us date and time for this service so we can serve you better
                </p>
                <div className="flex items-center">
                  <input type="date" value={dateInput} required onChange={(e) => setDateInput(e.target.value)} />
                  <input type="time" value={timeInput} required onChange={(e) => setTimeInput(e.target.value)} />
                </div>
              </div>
            )}
            {type === 'DOX' && <div ref={reasonRef}></div>}
            {type === 'WPX' && (
              <div className="skydart-bookShipment-service">
                <h5>Reason for export</h5>
                <div style={{ position: 'relative' }} ref={reasonRef}>
                  <div className="skydart-bookShipment-select-type" onClick={handleReason}>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
                      <SiReasonstudios size={21} />
                      <h3>{reason || 'Please select your export reason'}</h3>
                    </div>
                    <FaSortDown size={25} className="mt-[-10px]" />
                  </div>
                  {displayReason && (
                    <div className="skydart-bookShipment-select-type-container">
                      <p style={{ marginLeft: 8, fontSize: 12, fontWeight: 600, color: 'gray' }}>Reasons:</p>
                      <div
                        className="skydart-bookShipment-select-type-option1"
                        onClick={() => {
                          setReason('GIFT');
                          setDisplayReason(false);
                        }}
                      >
                        <div
                          className="skd-user-shipment-last-header"
                          style={{ padding: 0, border: 'none', marginRight: 12 }}
                        >
                          <input type="checkbox" checked={reason === 'GIFT' ? true : false} />
                        </div>
                        <h3>THE GOODS ARE GIFT FROM FAMILY AND NON COMMERCIAL VALUE</h3>
                      </div>
                      <div
                        className="skydart-bookShipment-select-type-option1"
                        onClick={() => {
                          setReason('E-COMMERCE SELLING');
                          setDisplayReason(false);
                        }}
                      >
                        <div
                          className="skd-user-shipment-last-header"
                          style={{ padding: 0, border: 'none', marginRight: 12 }}
                        >
                          <input type="checkbox" checked={reason === 'E-COMMERCE SELLING' ? true : false} />
                        </div>
                        <h3>THE GOODS ARE E-COMMERCE SELLING</h3>
                      </div>
                      <div
                        className="skydart-bookShipment-select-type-option1"
                        onClick={() => {
                          setReason('RETURNED FOR MAINTENANCE');
                          setDisplayReason(false);
                        }}
                      >
                        <div
                          className="skd-user-shipment-last-header"
                          style={{ padding: 0, border: 'none', marginRight: 12 }}
                        >
                          <input type="checkbox" checked={reason === 'RETURNED FOR MAINTENANCE' ? true : false} />
                        </div>
                        <h3>THE GOODS ARE RETURNED FOR MAINTENANCE</h3>
                      </div>
                      <div
                        className="skydart-bookShipment-select-type-option1"
                        onClick={() => {
                          setReason('SAMPLE');
                          setDisplayReason(false);
                        }}
                      >
                        <div
                          className="skd-user-shipment-last-header"
                          style={{ padding: 0, border: 'none', marginRight: 12 }}
                        >
                          <input type="checkbox" checked={reason === 'SAMPLE' ? true : false} />
                        </div>
                        <h3>THE GOODS ARE SAMPLE AND NON COMMERCIAL VALUE</h3>
                      </div>
                      <div
                        className="skydart-bookShipment-select-type-option1"
                        onClick={() => {
                          setReason('COMMERCIAL');
                          setDisplayReason(false);
                        }}
                      >
                        <div
                          className="skd-user-shipment-last-header"
                          style={{ padding: 0, border: 'none', marginRight: 12 }}
                        >
                          <input type="checkbox" checked={reason === 'COMMERCIAL' ? true : false} />
                        </div>
                        <h3>THE GOODS ARE COMMERCIAL WITH ALL NECESSARY DOCUMENTS ENCLOSED</h3>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="skydart-bookShipment-form">
              <h5>Booking form</h5>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <div style={{ marginLeft: "auto", display: "flex", alignItems: "center" }} onClick={() => {
                  if (rapidBooking) {
                    setRapidBooking(false);
                    setInput("");
                  } else {
                    setRapidBooking(true);
                    setCompanyInput("");
                    setCountryInput("");
                    setAddressInput("");
                    setAddressInput2("");
                    setAddressInput3("");
                    setTownInput("");
                    setCityInput("");
                    setStateInput("");
                    setPostalInput("");
                    setPhoneInput("");
                    setContactInput("");
                    setEmailInput("");
                  }

                }}>
                  <div className="skydart-bookShipment-shortcut-option">
                    <LocationOnOutlined style={{ fontSize: 21 }} />
                    {rapidBooking && <p>Regular method</p>}
                    {!rapidBooking && <p>1 Line Booking</p>}
                  </div>
                </div> */}
              </div>
              {!rapidBooking && (
                <div className="skydart-regular-booking">
                  <div className="skydart-regular-booking-option">
                    <p style={{ fontSize: 12, marginLeft: 1 }}>Country</p>
                    <div className="skydart-regular-booking-name" style={{ position: 'relative' }}>
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          setDisplayCountry(false);
                        }}
                      >
                        <div className="flex w-full items-center">
                          {service !== 'DUS' && (
                            <input
                              type="text"
                              value={countryInput}
                              onChange={(e) => setCountryInput(e.target.value)}
                              onClick={() => setDisplayCountry(true)}
                              style={{ width: 320 }}
                            />
                          )}
                          {service === 'DUS' && (
                            <input
                              type="text"
                              value={country}
                              onChange={(e) => setCountryInput(e.target.value)}
                              disabled
                              style={{ width: 320 }}
                            />
                          )}
                          {countryInput !== '' && <FcOk size={18} style={{ marginLeft: 10 }} />}
                          {countryInput === '' && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                          {displayCountry && (
                            <div className="skydart-regular-country-render">
                              {countries
                                .filter((s) => {
                                  if (s.label.toLowerCase().startsWith(countryInput.toLowerCase())) {
                                    return s;
                                  }
                                })
                                .map((s) => (
                                  <div
                                    className="skydart-regular-country-render-option"
                                    onClick={() => {
                                      setCountryInput(s.label);
                                      setCode(s.code);
                                      setPhoneCode(s.phone);
                                      setDisplayCountry(false);
                                      setCurrentZone(s.skydartZone);
                                    }}
                                  >
                                    <h5>{s.label}</h5>
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      </OutsideClickHandler>
                    </div>
                  </div>
                  <div className="skydart-regular-booking-option">
                    <p style={{ fontSize: 12, marginLeft: 1 }}>Company</p>
                    <div className="skydart-regular-booking-name">
                      <input
                        type="text"
                        value={companyInput}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          // const regex = /^[a-zA-Z0-9\s\-#]*$/;
                          // const regex = /^[!$%^*_=+"'@]*$/;

                          const regex = /^[^"&@+_<>$%#,.=!'^*]*$/;
                          if (regex.test(newValue)) {
                            setCompanyInput(newValue);
                          }
                        }}
                      />
                      {companyInput !== '' && <FcOk size={18} style={{ marginLeft: 10 }} />}
                      {companyInput === '' && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                    </div>
                  </div>

                  <div className="skydart-regular-booking-option">
                    <p style={{ fontSize: 12, marginLeft: 1 }}>Address</p>
                    <div className="skydart-regular-booking-name">
                      <input
                        type="text"
                        maxLength={30}
                        value={addressInput}
                        onChange={(e) => {
                          const newValue = e.target.value;

                          const regex = /^[^"&@+_<>$%']*$/;
                          if (regex.test(newValue)) {
                            setAddressInput(newValue);
                          }
                        }}
                      />
                      {addressInput !== '' && <FcOk size={18} style={{ marginLeft: 10 }} />}
                      {addressInput === '' && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                    </div>
                  </div>
                  <div className="skydart-regular-booking-option">
                    <p style={{ fontSize: 12, marginLeft: 1 }}>Address 2</p>
                    <div className="skydart-regular-booking-name">
                      <input
                        type="text"
                        maxLength={30}
                        value={addressInput2}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          // const regex = /^[a-zA-Z0-9\s\-#]*$/;
                          const regex = /^[^"&@+_<>$%']*$/;

                          if (regex.test(newValue)) {
                            setAddressInput2(newValue);
                          }
                        }}
                      />
                      {addressInput2 !== '' && <FcOk size={18} style={{ marginLeft: 10 }} />}
                      {addressInput2 === '' && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                    </div>
                  </div>
                  <div className="skydart-regular-booking-option">
                    <p style={{ fontSize: 12, marginLeft: 1 }}>Address 3</p>
                    <div className="skydart-regular-booking-name">
                      <input
                        type="text"
                        maxLength={30}
                        value={addressInput3}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          // const regex = /^[a-zA-Z0-9\s\-#]*$/;
                          const regex = /^[^"&@+_<>$%']*$/;

                          if (regex.test(newValue)) {
                            setAddressInput3(newValue);
                          }
                        }}
                      />
                      <FcOk size={18} style={{ marginLeft: 10 }} className="opacity-0" />
                    </div>
                  </div>
                  <div className="skydart-regular-booking-option">
                    <p style={{ fontSize: 12, marginLeft: 1 }}>Town</p>
                    <div className="skydart-regular-booking-name">
                      <input type="text" value={townInput} onChange={(e) => setTownInput(e.target.value)} />
                      {townInput !== '' && <FcOk size={18} style={{ marginLeft: 10 }} />}
                      {townInput === '' && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                    </div>
                  </div>
                  <div className="skydart-regular-booking-option">
                    <p style={{ fontSize: 12, marginLeft: 1 }}>City</p>
                    <div className="skydart-regular-booking-name">
                      <input type="text" value={cityInput} onChange={(e) => setCityInput(e.target.value)} />
                      {cityInput !== '' && <FcOk size={18} style={{ marginLeft: 10 }} />}
                      {cityInput === '' && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                    </div>
                  </div>
                  {service !== 'UUS' && <div ref={stateRef}></div>}
                  {service !== 'FUS' && <div ref={stateRef}></div>}
                  {service !== 'DUS' && <div ref={stateRef}></div>}
                  {service === 'DUS' && <div ref={stateRef}></div>}
                  <div className="skydart-regular-booking-option">
                    <p style={{ fontSize: 12, marginLeft: 1 }}>State</p>
                    {country !== 'United States' && (
                      <div className="skydart-regular-booking-name">
                        <input
                          type="text"
                          value={stateInput}
                          onChange={(e) => {
                            setStateInput(e.target.value);
                            setStateLabel(e.target.value);
                          }}
                        />
                        <FcHighPriority size={18} className="opacity-0" style={{ marginLeft: 10 }} />
                      </div>
                    )}
                    <div ref={country === 'United States' ? null : stateRef}></div>
                    {country === 'United States' && (
                      <div
                        className="skydart-regular-booking-name"
                        ref={country === 'United States' ? stateRef : null}
                        style={{ position: 'relative' }}
                      >
                        <input
                          type="text"
                          value={stateInput}
                          onChange={(e) => setStateInput(e.target.value)}
                          onClick={() => {
                            setDisplayState(true);
                          }}
                        />
                        {displayState && (
                          <div className="skydart-regular-country-render">
                            {usState
                              .filter((s) => {
                                if (s.code.toLowerCase().startsWith(stateInput.toLowerCase())) {
                                  return s;
                                }
                              })
                              .map((s) => (
                                <div
                                  className="skydart-regular-country-render-option"
                                  onClick={() => {
                                    setStateLabel(s.label);
                                    setStateInput(s.code);
                                    setStateCode(s.code);
                                    setDisplayState(false);
                                  }}
                                >
                                  <h5>{`${s.label}, ${s.code}`}</h5>
                                </div>
                              ))}
                          </div>
                        )}
                        {stateInput !== '' && <FcOk size={18} style={{ marginLeft: 10 }} />}
                        {stateInput === '' && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                      </div>
                    )}
                  </div>
                  <div className="skydart-regular-booking-option">
                    <p style={{ fontSize: 12, marginLeft: 1 }}>Postal Code</p>
                    <div className="skydart-regular-booking-name">
                      <input type="text" value={postalInput} onChange={(e) => setPostalInput(e.target.value)} />
                      {postalInput !== '' && <FcOk size={18} style={{ marginLeft: 10 }} />}
                      {postalInput === '' && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                    </div>
                  </div>
                  <div className="skydart-regular-booking-option">
                    <p style={{ fontSize: 12, marginLeft: 1 }}>Telephone</p>
                    <div className="skydart-regular-booking-name">
                      <input type="text" value={phoneInput} onChange={(e) => setPhoneInput(e.target.value)} />
                      {phoneInput !== '' && <FcOk size={18} style={{ marginLeft: 10 }} />}
                      {phoneInput === '' && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                    </div>
                  </div>
                  <div className="skydart-regular-booking-option">
                    <p style={{ fontSize: 12, marginLeft: 1 }}>Contact Name</p>
                    <div className="skydart-regular-booking-name">
                      <input
                        type="text"
                        value={contactInput}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          // const regex = /^[a-zA-Z0-9\s\-#()-]*$/;
                          const regex = /^[^"&@+_<>$%#,.=!'^*]*$/;

                          if (regex.test(newValue)) {
                            setContactInput(newValue);
                          }
                        }}
                      />
                      {contactInput !== '' && <FcOk size={18} style={{ marginLeft: 10 }} />}
                      {contactInput === '' && <FcHighPriority size={18} style={{ marginLeft: 10 }} />}
                    </div>
                  </div>
                  <div className="skydart-regular-booking-option">
                    <p style={{ fontSize: 12, marginLeft: 1 }}>Email Address</p>
                    <div className="skydart-regular-booking-name">
                      <input
                        type="text"
                        value={emailInput}
                        required={service === 'UPS' ? true : false}
                        onChange={(e) => setEmailInput(e.target.value)}
                      />

                      <FcHighPriority size={18} className="opacity-0" style={{ marginLeft: 10 }} />
                    </div>
                  </div>
                </div>
              )}
              {rapidBooking && (
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
                  <p style={{ color: 'black', fontSize: 12 }}>
                    <strong style={{ fontWeight: 600 }}>
                      Format: Company * Address (1,2,3) * Town* City * State * Postal * Country (select) * Telephone *
                      Contact Name * Email
                    </strong>
                  </p>
                </div>
              )}
            </div>
            {type === 'WPX' && (
              <div className="skydart-bookShipment-packing">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h5>Packing information</h5>
                  <div
                    className="skydart-bookShipment-packing-header-option"
                    style={{ marginLeft: 'auto', marginRight: 5 }}
                  >
                    <p>
                      Total packages: <strong style={{ color: 'rgb(61, 78, 104)' }}>{packageValue.length} PCS</strong>
                    </p>
                  </div>
                </div>
                <div className="skydart-bookShipment-packing-info">
                  <div className="skydart-bookShipment-info-packing-header">
                    <div className="skydart-bookShipment-packing-info-header-option">
                      <div className="skydart-bookShipment-packing-info-kg">
                        <p>Gross (KG)</p>
                      </div>
                    </div>
                    <div className="skydart-bookShipment-packing-info-header-option">
                      <div className="skydart-bookShipment-packing-info-dim">
                        <p style={{ marginLeft: -11 }}>Length (CM)</p>
                      </div>

                      <div className="skydart-bookShipment-packing-info-dim">
                        <p style={{ marginLeft: -11 }}>Width (CM)</p>
                      </div>

                      <div className="skydart-bookShipment-packing-info-dim">
                        <p style={{ marginLeft: -11 }}>Height (CM)</p>
                      </div>
                    </div>
                    <div className="skydart-bookShipment-packing-info-header-option">
                      <div className="skydart-bookShipment-packing-info-kg">
                        <p style={{ marginLeft: -22 }}>Chargeable (KG)</p>
                      </div>
                    </div>
                    <div
                      className="skydart-bookShipment-packing-info-header-option2"
                      style={{ border: 'none', cursor: 'default' }}
                    ></div>
                    <div className="skydart-bookShipment-packing-info-header-option4"></div>
                  </div>
                  <div style={{ marginTop: 5 }}>
                    {packageValue.map((element, index) => {
                      return (
                        <div className="skydart-bookShipment-info-packing-header" key={index}>
                          <div className="skydart-bookShipment-packing-info-header-option">
                            <div className="skydart-bookShipment-packing-info-kg">
                              <input
                                type="number"
                                required
                                value={element.packageWeight || ''}
                                onChange={(e) => handleChange(index, e)}
                                name="packageWeight"
                              />
                            </div>
                          </div>
                          <div className="skydart-bookShipment-packing-info-header-option">
                            <div className="skydart-bookShipment-packing-info-dim">
                              <input
                                type="number"
                                required
                                style={{ marginLeft: 10 }}
                                name="packageLength"
                                value={element.packageLength || ''}
                                onChange={(e) => handleChange(index, e)}
                              />
                              <p style={{ marginLeft: 9, fontSize: 15 }}>X</p>
                            </div>
                            <div className="skydart-bookShipment-packing-info-dim">
                              <input
                                type="number"
                                required
                                style={{ marginLeft: 10 }}
                                name="packageWidth"
                                value={element.packageWidth || ''}
                                onChange={(e) => handleChange(index, e)}
                              />
                              <p style={{ marginLeft: 9, fontSize: 15 }}>X</p>
                            </div>
                            <div className="skydart-bookShipment-packing-info-dim">
                              <input
                                type="number"
                                required
                                style={{ marginLeft: 10 }}
                                name="packageHeight"
                                value={element.packageHeight || ''}
                                onChange={(e) => handleChange(index, e)}
                              />
                            </div>
                          </div>
                          <div className="skydart-bookShipment-packing-info-header-option">
                            <div className="skydart-bookShipment-packing-info-kg">
                              <input type="number" value={element.packageCharge.toFixed(2)} name="packageCharge" />
                            </div>
                          </div>
                          <div
                            className="skydart-bookShipment-packing-info-header-option1"
                            onClick={() => {
                              setPackageValue([
                                ...packageValue,
                                {
                                  packNo: packageValue.length + 1,
                                  packageLength: element.packageLength,
                                  packageWidth: element.packageWidth,
                                  packageHeight: element.packageHeight,
                                  packageWeight: element.packageWeight,
                                  packageCharge: element.packageCharge,
                                },
                              ]);
                            }}
                          >
                            <p>Copy</p>
                          </div>
                          <div
                            className="skydart-bookShipment-packing-info-header-option3"
                            onClick={(e) => removeFormField(index, e)}
                          >
                            <MdOutlineClear size={15} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <div
                      className="skydart-bookShipment-info-packing-header"
                      style={{ alignItems: 'center', borderTop: '1px solid lightgray', paddingTop: 5 }}
                    >
                      <div className="skydart-bookShipment-packing-info-header-option">
                        <div className="skydart-bookShipment-packing-info-kg">
                          <p style={{ fontWeight: 600 }}>Total</p>
                        </div>
                      </div>
                      <div className="skydart-bookShipment-packing-info-header-option">
                        <div className="skydart-bookShipment-packing-info-dim">
                          <p style={{ marginLeft: 16, fontWeight: 600 }}>Weight</p>
                        </div>
                        <div className="skydart-bookShipment-packing-info-dim">
                          <input
                            type="text"
                            style={{
                              marginLeft: 10,
                              background: 'rgb(240, 248, 255)',
                              borderRadius: 3,
                              fontSize: 13,
                              width: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingTop: 7,
                              paddingBottom: 7,
                              padding: 5,
                              fontWeight: 600,
                              color: 'rgb(56, 97, 133)',
                            }}
                            value={(Math.round(finalGross * 100) / 100).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          />
                          <p style={{ marginLeft: 9, fontSize: 15, opacity: 0 }}>X</p>
                        </div>
                        <div className="skydart-bookShipment-packing-info-dim">
                          <p style={{ marginLeft: 11, fontWeight: 600 }}>Chargeable</p>
                        </div>
                      </div>
                      <div className="skydart-bookShipment-packing-info-header-option">
                        <div className="skydart-bookShipment-packing-info-kg">
                          <input
                            type="text"
                            style={{
                              background: 'rgb(243, 255, 251)',
                              color: 'darkgreen',
                              fontWeight: 600,
                              fontSize: 13,
                              width: '80%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingTop: 7,
                              paddingBottom: 7,
                              padding: 5,
                              fontWeight: 600,
                            }}
                            value={(Math.round(finalCharge * 100) / 100).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          />
                        </div>
                      </div>
                      <div className="skydart-bookShipment-packing-info-header-option1" onClick={addFormFields}>
                        <p>Adds</p>
                      </div>
                      <div className="skydart-bookShipment-packing-info-header-option3" onClick={resetPacking}>
                        <VscDebugRestart size={15} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {type === 'DOX' && (
              <div className="skydart-bookShipment-packing">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h5>Packing information</h5>
                </div>
                <div style={{ padding: 5, display: 'flex', alignItems: 'center' }}>
                  <p style={{ fontSize: 13, fontWeight: 600 }}>Number of envelope:</p>
                  <input
                    type="number"
                    className="dox-input-declare"
                    value={envelope}
                    onChange={(e) => setEnvelope(e.target.value)}
                  />
                  <p style={{ fontSize: 13, fontWeight: 600, width: '5%', marginLeft: 5 }}>PCS</p>
                </div>
                <div style={{ padding: 5, display: 'flex', alignItems: 'center' }}>
                  <p style={{ fontSize: 13, fontWeight: 600 }}>Total weight:</p>
                  <input
                    type="number"
                    className="dox-input-declare"
                    style={{ background: 'rgb(240,240,240)' }}
                    value={doxWeight}
                    onChange={(e) => setDoxWeight(e.target.value)}
                  />
                  <p style={{ fontSize: 13, fontWeight: 600, width: '5%', marginLeft: 5 }}>KG</p>
                </div>
              </div>
            )}
            {type === 'WPX' && (
              <div className="skydart-bookShipment-invoice">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <h5>Description of goods</h5>
                </div>
                <p style={{ marginLeft: 5, marginRight: 5, lineHeight: 1.5, fontSize: 13.5 }}>
                  Declare element of your goods in detail for a faster process duration.
                </p>
                <div style={{ display: 'flex', marginTop: 12, marginLeft: 5 }}>
                  <input type="file" id="book-upload" onChange={handleFile} />
                  <label className={fileAttached ? 'label-upload1' : 'label-upload'} style={{ position: 'relative' }}>
                    <IoDocumentAttachSharp size={20} />
                    <p>Add invoice</p>
                    {fileAttached && <p className="file-attached-text">{invoiceValue.length}</p>}
                  </label>
                  <input type="file" id="book-upload" />
                  <div
                    {...exportInvoice}
                    style={{ color: 'black', textDecoration: 'none' }}
                    for="book-upload"
                    className="label-upload"
                  >
                    <MdSimCardDownload size={20} />
                    <p>Download invoice</p>
                  </div>
                  <label
                    className={useTemplate ? 'label-upload1' : 'label-upload'}
                    onClick={() => {
                      if (!useTemplate) {
                        setUseTemplate(true);
                        setFileAttached(false);
                        resetInvoice();
                      } else {
                        setUseTemplate(false);
                        setFileAttached(false);
                      }
                    }}
                  >
                    <VscNotebookTemplate size={20} />
                    <p>Use template</p>
                  </label>
                  {attachLoading && <CircularProgress size={23} style={{ color: 'rgb(31, 82, 139)', marginTop: 4 }} />}
                </div>
                {!attachLoading && fileAttached && (
                  <div className="skydart-bookShipment-invoice-wrap">
                    <div className="skydart-bookShipment-invoice-attached">
                      <div className="skydart-bookShipment-invoice-attached-header">
                        <div className="skydart-bookShipment-invoice-attached-option">
                          <p>Name</p>
                        </div>
                        <div className="skydart-bookShipment-invoice-attached-option">
                          <p>Type</p>
                        </div>
                        <div className="skydart-bookShipment-invoice-attached-option">
                          <p>Size</p>
                        </div>
                      </div>
                      <div className="skydart-bookShipment-invoice-attached-body">
                        <div className="skydart-bookShipment-invoice-attached-option1">
                          <p>{fileName}</p>
                        </div>
                        <div className="skydart-bookShipment-invoice-attached-option1">
                          <p>{fileType}</p>
                        </div>
                        <div className="skydart-bookShipment-invoice-attached-option1">
                          <p>{niceBytes(fileSize)}</p>
                        </div>
                      </div>
                    </div>
                    <div className="skydart-bookShipment-subTotal">
                      <div className="skydart-bookShipment-subTotal-option">
                        <p>Total item:</p>
                        <p>
                          {invoiceValue.length} {invoiceValue.length === 1 ? 'item' : 'items'}
                        </p>
                      </div>
                      <div className="skydart-bookShipment-subTotal-option">
                        <p>Total item value:</p>
                        <p>{(Math.round(finalInvoiceValue * 100) / 100).toFixed(2)} USD</p>
                      </div>
                      <div className="skydart-bookShipment-subTotal-option">
                        <p>Total invoice value:</p>
                        <p style={{ textDecoration: 'underline' }}>
                          {(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)} USD
                        </p>
                      </div>
                      <div className="skydart-bookShipment-subTotal-btn">
                        <div
                          className="skydart-bookShipment-subTotal-cancel"
                          onClick={() => {
                            setDisplayInvoice(true);
                            setDisplayLabel(false);
                            setDisplayShippingMark(false);
                          }}
                        >
                          <FaFileInvoice size={20} />
                          <p>View invoice</p>
                        </div>
                        <div
                          className="skydart-bookShipment-subTotal-cancel"
                          onClick={() => {
                            resetInvoice();
                            setFileAttached(false);
                          }}
                        >
                          <VscDebugRestart size={20} />
                          <p>Reset</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {useTemplate && (
                  <div className="skydart-bookShipment-invoice-wrap">
                    {invoiceValue?.map((s, index) => {
                      let donGia1 = s.itemUnitPrice;
                      let soLuong1 = s.itemQuantity;
                      let tong1 = donGia1 * soLuong1;
                      s.itemValue = tong1;
                      return (
                        <div className="skydart-bookShipment-invoice-container">
                          <div className="skydart-bookShipment-invoice-header">
                            <p style={{ marginLeft: 3, fontSize: 15, fontWeight: 600 }}>Item {index + 1}</p>
                            <div
                              className="skydart-bookShipment-packing-info-header-option1"
                              style={{ width: 40, height: 30, marginLeft: 'auto' }}
                              onClick={() => {
                                setInvoiceValue([
                                  ...invoiceValue,
                                  {
                                    id: uuid(),
                                    isSelectItem: false,
                                    itemCommonity: s.itemCommonity,
                                    itemVietnamCommonity: s.itemVietnamCommonity,
                                    itemCountryOrigin: 'Vietnam',
                                    itemManufactAddress: s.itemManufactAddress,
                                    itemPackType: s.itemPackType,
                                    itemPackType2: s.itemPackType2,
                                    itemHsCode: s.itemHsCode,
                                    itemPnc: s.itemPnc,
                                    itemUnit: s.itemUnit,
                                    itemOtherUnit: s.itemOtherUnit,
                                    itemPurpose: s.itemPurpose,
                                    itemManufact: s.itemManufact,
                                    itemUnitPrice: s.itemUnitPrice,
                                    itemQuantity: s.itemQuantity,
                                    itemValue: s.itemValue,
                                    itemBrand: s.itemBrand,
                                    itemIngredients: s.itemIngredients,
                                    itemNo: index + 1,
                                    itemFda: s.itemFda,
                                    itemUnit2: s.itemUnit2,
                                  },
                                ]);
                              }}
                            >
                              <p>Copy</p>
                            </div>
                            <div
                              className="skydart-bookShipment-packing-info-header-option3 h-[30px]"
                              onClick={(e) => removeInvoice(index, e)}
                            >
                              <MdOutlineClear size={30} />
                            </div>
                          </div>
                          <div className="skydart-bookShipment-invoice-box">
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>Item name: *</p>
                              <input
                                type="text"
                                required
                                name="itemCommonity"
                                value={s.itemCommonity}
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <p className="text-[12px] text-[darkblue] m-1">- Use ( ) to include your ingredients</p>
                            <p className="text-[12px] text-[darkblue] m-1">- Use '-' to declare detail</p>
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>Tên mặt hàng bằng tiếng Việt: *</p>
                              <div className="relative">
                                <input
                                  type="text"
                                  required
                                  name="itemVietnamCommonity"
                                  value={s.itemVietnamCommonity}
                                  style={{ width: 277 }}
                                  onChange={(e) => invoiceChange(index, e)}
                                />
                              </div>
                            </div>
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>Brand name:</p>
                              <input
                                type="text"
                                value={s.itemBrand}
                                name="itemBrand"
                                required
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>Packing type:</p>
                              <select
                                value={s.itemPackType || ''}
                                name="itemPackType"
                                required
                                onChange={(e) => invoiceChange(index, e)}
                              >
                                <option value="">Select packing type</option>
                                <option value="Bag">Bag</option>
                                <option value="Box">Box</option>
                                <option value="Carton">Carton</option>
                                <option value="Wooden Pack">Wooden Pack</option>
                                <option value="Other">Other</option>
                              </select>
                            </div>
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>Ingredient:</p>
                              <input
                                type="text"
                                value={s.itemIngredients}
                                name="itemIngredients"
                                disabled={s.itemCommonity.match(/\(([^)]+)\)/)}
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <p className="text-[12px] text-[darkblue] m-1">
                              - If you declared your ingredients at "Item name" this field will be disabled.
                            </p>
                            <p className="text-[12px] text-[darkblue] m-1">
                              - If you declared your ingredients here, we will assume this will be your main ingredient
                              for the product.
                            </p>
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>Usage:</p>
                              <input
                                type="text"
                                value={s.itemPurpose}
                                name="itemPurpose"
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>Country of origin:</p>
                              <input
                                type="text"
                                value={s.itemCountryOrigin}
                                placeholder="Nước xuất khẩu"
                                name="itemCountryOrigin"
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>Manufacturer name:</p>
                              <input
                                type="text"
                                name="itemManufact"
                                required
                                value={s.itemManufact}
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>Manufacturer address:</p>
                              <input
                                type="text"
                                name="itemManufactAddress"
                                value={s.itemManufactAddress}
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>HS code:</p>
                              <input
                                type="text"
                                name="itemHsCode"
                                value={s.itemHsCode}
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>FDA:</p>
                              <input
                                type="text"
                                value={s.itemFda || ''}
                                name="itemFda"
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>Quantity: *</p>
                              <input
                                type="number"
                                required
                                value={s.itemQuantity || ''}
                                name="itemQuantity"
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>Unit: *</p>
                              <select
                                required
                                value={s.itemUnit || ''}
                                name="itemUnit"
                                onChange={(e) => invoiceChange(index, e)}
                              >
                                <option value="">Select item unit</option>
                                <option value="PCS">PCS</option>
                                <option value="BOX">BOX</option>
                                <option value="KG">KG</option>
                                <option value="PRS">PRS</option>
                                <option value="SET">SET</option>
                              </select>
                            </div>
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>Unit price: *</p>
                              <input
                                type="number"
                                required
                                value={s.itemUnitPrice || ''}
                                name="itemUnitPrice"
                                onChange={(e) => invoiceChange(index, e)}
                              />
                            </div>
                            <div className="skydart-bookShipment-invoice-box-option">
                              <HiOutlineDocument size={20} />
                              <p>Total amount (USD):</p>
                              <input
                                type="text"
                                disabled
                                value={s.itemValue.toFixed(2) + ' ' + 'USD' || '0.00' + 'USD'}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="skydart-bookShipment-subTotal">
                      <div className="skydart-bookShipment-subTotal-option">
                        <p>Total item:</p>
                        <p>
                          {invoiceValue.length} {invoiceValue.length === 1 ? 'item' : 'items'}
                        </p>
                      </div>
                      <div className="skydart-bookShipment-subTotal-option">
                        <p>Total item value:</p>
                        <p>{(Math.round(finalInvoiceValue * 100) / 100).toFixed(2)} USD</p>
                      </div>
                      <div className="skydart-bookShipment-subTotal-option">
                        <p>Total invoice value:</p>
                        <p style={{ textDecoration: 'underline' }}>
                          {(Math.round(totalInvoiceValue * 100) / 100).toFixed(2)} USD
                        </p>
                      </div>
                      <div className="skydart-bookShipment-subTotal-btn">
                        <div
                          className="skydart-bookShipment-subTotal-cancel"
                          onClick={() => {
                            setDisplayInvoice(true);
                            setDisplayLabel(false);
                            setDisplayShippingMark(false);
                          }}
                        >
                          <FaFileInvoice size={20} />
                          <p>View invoice</p>
                        </div>
                        <div className="skydart-bookShipment-subTotal-cancel" onClick={resetInvoice}>
                          <VscDebugRestart size={20} />
                          <p>Reset</p>
                        </div>
                        <div className="skydart-bookShipment-subTotal-cancel" onClick={addCarton}>
                          <IoMdAddCircle size={20} />
                          <p>Add item</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {type === 'WPX' && (
              <div className="skydart-bookShipment-term">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h5>Transport Term</h5>
                  <div className="skydart-bookShipment-term-container">
                    <div
                      className={
                        term === 'EXW' ? 'skydart-bookShipment-term-option1' : 'skydart-bookShipment-term-option'
                      }
                      onClick={() => {
                        setTerm('EXW');
                      }}
                    >
                      <p>EXW</p>
                    </div>
                    <div
                      className={
                        term === 'FOB' ? 'skydart-bookShipment-term-option1' : 'skydart-bookShipment-term-option'
                      }
                      style={{ borderLeft: 'none' }}
                      onClick={() => {
                        setTerm('FOB');
                      }}
                    >
                      <p>FOB</p>
                    </div>
                    <div
                      className={
                        term === 'CNF' ? 'skydart-bookShipment-term-option1' : 'skydart-bookShipment-term-option'
                      }
                      style={{ borderLeft: 'none' }}
                      onClick={() => {
                        setTerm('CNF');
                      }}
                    >
                      <p>CNF</p>
                    </div>
                    <div
                      className={
                        term === 'CIF' ? 'skydart-bookShipment-term-option1' : 'skydart-bookShipment-term-option'
                      }
                      style={{ borderLeft: 'none' }}
                      onClick={() => {
                        setTerm('CIF');
                      }}
                    >
                      <p>CIF</p>
                    </div>
                  </div>
                </div>
                <div className="skydart-bookShipment-transportValue">
                  <p>Transport value</p>
                  <input type="number" value={transport} onChange={(e) => setTransport(e.target.value)} />
                </div>
              </div>
            )}

            {type === 'WPX' && (
              <div className="skydart-bookShipment-service">
                <h5>IOSS / TIN / EORI Number / ID Tax Number</h5>
                <input type="text" value={eori} onChange={(e) => setEori(e.target.value)} />
              </div>
            )}
          </div>
          <div className="skydart-bookShipment-btn">
            <div className="skydart-bookShipment-btn-container w-full">
              <div className="skydart-bookShipment-cancel-btn" onClick={() => navigate(-1)}>
                <TiArrowBack size={20} />
                <p className="mx-1.5">Cancel, back to previous page</p>
              </div>
              {/* <div className={validBook ? "skydart-bookShipment-book-btn" : "skydart-bookShipment-book-btn1"} style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} onClick={() => {
                if (validBook) {
                  onBookShipment();
                } else {
                  return;
                }
              }}>
                <p>Book shipment</p>
              </div> */}
              {validBook && (
                <button
                  type="submit"
                  className="p-[7px] mr-[2px] select-none flex items-center justify-center w-[130px] transition-[0.1s] text-[14px] rounded-[5px] cursor-pointer border-solid border-[1px] border-transparent bg-[rgb(73,130,177)] text-white hover:bg-[rgb(53,99,138)] active:bg-[rgb(43,91,131)]"
                  style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                >
                  <p>Book shipment</p>
                </button>
              )}
              {!validBook && (
                <button
                  type="button"
                  className="skydart-bookShipment-book-btn1"
                  style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                >
                  <p>Book shipment</p>
                </button>
              )}
              <OutsideClickHandler
                onOutsideClick={() => {
                  setDisplaySave(false);
                }}
              >
                <div style={{ position: 'relative' }}>
                  <div
                    className="skydart-bookShipment-save-btn"
                    onClick={() => {
                      if (displaySave) {
                        setDisplaySave(false);
                      } else {
                        setDisplaySave(true);
                      }
                    }}
                    style={{
                      width: 37,
                      height: 37,
                      border: '1px solid transparent',
                      marginRight: 5,
                      color: 'white',
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    <FaSortDown size={27} className="mt-[-11px]" />
                  </div>
                  {displaySave && (
                    <div className="skydart-bookShipment-save-display">
                      <div className="skydart-bookShipment-save-save" onClick={onSaveShipment1}>
                        <p>Save shipment</p>
                      </div>
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
              {/* <button type="button" onClick={getRate}>Test</button>
              <p>{rateValue.toFixed(2)}</p> */}
            </div>
          </div>
        </div>
        {displayBill && (
          <div className="skydart-bookShipment-right ml-auto">
            <div className="skydart-bookShipment-right-header">
              <h5>Shipment preview</h5>
              <div className="skydart-bookShipment-right-header-icon">
                <div
                  className={
                    displayLabel
                      ? 'skydart-bookShipment-right-header-icon-option1'
                      : 'skydart-bookShipment-right-header-icon-option'
                  }
                  onClick={() => {
                    setDisplayLabel(true);
                    setDisplayInvoice(false);
                    setDisplayDraft(false);
                  }}
                >
                  <MdOutlineDocumentScanner size={20} />
                  <p>Label</p>
                </div>
                {type === 'WPX' && (
                  <div
                    className={
                      displayInvoice
                        ? 'skydart-bookShipment-right-header-icon-option1'
                        : 'skydart-bookShipment-right-header-icon-option'
                    }
                    onClick={() => {
                      setDisplayLabel(false);
                      setDisplayInvoice(true);
                      setDisplayDraft(false);
                    }}
                  >
                    <FaFileInvoice size={20} />
                    <p>Invoice</p>
                  </div>
                )}
              </div>
            </div>
            <div className="skydart-bookShipment-right-wrap ml-auto">
              {displayLabel && (
                <>
                  <CustomerBill
                    hawb={hawb}
                    exporter={user.exporter}
                    fromAddress={user.address}
                    fromAddress2={user.address2}
                    fromAddress3={user.address3}
                    fromState={user.state}
                    fromTown={user.town}
                    fromCity={user.city}
                    fromPostal={user.postal}
                    fromCountry={user.country}
                    fromPhone={user.phone}
                    fromName={user.displayName}
                    fromEmail={user.email}
                    importer={importer}
                    toAddress={address}
                    toAddress2={address2}
                    toAddress3={address3}
                    toState={state}
                    toTown={town}
                    toCity={city}
                    toPostal={postal}
                    toCountry={country}
                    toPhone={phone}
                    toName={contact}
                    toEmail={email}
                    amount={packageValue.length}
                    weight={finalGross.toFixed(2)}
                    chargeable={finalCharge.toFixed(2)}
                    type={type}
                    invoiceValue={invoiceValue}
                    date={datePicker}
                  />
                </>
              )}
              {displayInvoice && type === 'WPX' && (
                <CustomerInvoice
                  invoiceValue={invoiceValue}
                  invoice={invoiceValue}
                  hawb={hawb}
                  datePicker={datePicker}
                  fromCountry={user.country}
                  toCountry={country}
                  usage={reason}
                  exporter={user.exporter}
                  fromAddress={user.address}
                  fromAddress2={user.address2}
                  fromAddress3={user.address3}
                  fromTown={user.town}
                  fromState={user.state}
                  fromCity={user.city}
                  fromPostal={user.postal}
                  fromName={user.displayName}
                  fromPhone={user.phone}
                  fromEmail={user.email}
                  mst={user.mst}
                  importer={importer}
                  toAddress={address}
                  toAddress2={address2}
                  toAddress3={address3}
                  toState={state}
                  toPostal={postal}
                  toTown={town}
                  toCity={city}
                  toName={contact}
                  toPhone={phone}
                  toEmail={email}
                  eori={eori}
                  transportation={transport}
                  totalGross={finalGross.toFixed(2)}
                  totalPackage={packageValue.length}
                  totalItemValue={finalInvoiceValue}
                  totalInvoiceValue={totalInvoiceValue}
                />
              )}
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

const usState = [
  { code: 'AL', label: 'Alabama' },
  { code: 'AK', label: 'Alaska' },
  { code: 'AZ', label: 'Arizona' },
  { code: 'AR', label: 'Arkansas' },
  { code: 'CA', label: 'California	' },
  { code: 'CZ', label: 'Canal Zone' },
  { code: 'CO', label: 'Colorado' },
  { code: 'CT', label: 'Connecticut' },
  { code: 'DE', label: 'Delaware' },
  { code: 'DC', label: 'District of Columbia' },
  { code: 'FL', label: 'Florida' },
  { code: 'GA', label: 'Georgia' },
  { code: 'GU', label: 'Guam' },
  { code: 'HI', label: 'Hawaii' },
  { code: 'ID', label: 'Idaho' },
  { code: 'IL', label: 'Illinois' },
  { code: 'IN', label: 'Indiana' },
  { code: 'IA', label: 'Iowa' },
  { code: 'KS', label: 'Kansas' },
  { code: 'KY', label: 'Kentucky' },
  { code: 'LA', label: 'Louisiana' },
  { code: 'ME', label: 'Maine' },
  { code: 'MD', label: 'Maryland' },
  { code: 'MA', label: 'Massachusetts' },
  { code: 'MI', label: 'Michigan' },
  { code: 'MN', label: 'Minnesota' },
  { code: 'MS', label: 'Mississippi' },
  { code: 'MO', label: 'Missouri' },
  { code: 'MT', label: 'Montana' },
  { code: 'NE', label: 'Nebraska' },
  { code: 'NV', label: 'Nevada' },
  { code: 'NH', label: 'New Hampshire' },
  { code: 'NJ', label: 'New Jersey' },
  { code: 'NM', label: 'New Mexico' },
  { code: 'NY', label: 'New York' },
  { code: 'NC', label: 'North Carolina' },
  { code: 'ND', label: 'North Dakota' },
  { code: 'OH', label: 'Ohio' },
  { code: 'OK', label: 'Oklahoma' },
  { code: 'OR', label: 'Oregon' },
  { code: 'PA', label: 'Pennsylvania' },
  { code: 'PR', label: 'Puerto Rico' },
  { code: 'RI', label: 'Rhode Island' },
  { code: 'SC', label: 'South Carolina' },
  { code: 'SD', label: 'South Dakota' },
  { code: 'TN', label: 'Tennessee' },
  { code: 'TX', label: 'Texas' },
  { code: 'UT', label: 'Utah' },
  { code: 'VT', label: 'Vermont' },
  { code: 'VI', label: 'Virgin Islands' },
  { code: 'VA', label: 'Virginia' },
  { code: 'WA', label: 'Washington' },
  { code: 'WV', label: 'West Virginia' },
  { code: 'WI', label: 'Wisconsin' },
  { code: 'WY', label: 'Wyoming' },
];
